import ObjectFinder from 'components/system/Objects/Finder';
import NameCircle from 'components/markup/misc/NameCircle';

const UserIcons = ({ users }) => {

    const includedUsers = users.length > 4 ? users.slice(0, 4) : users
    const extraUsers = users.length > 4 ? users.slice(4, users.length) : []

    const totalIcons = includedUsers.length + extraUsers.length

    return (
        <span className='archk-user-icons d-inline-block' style={{width: totalIcons * 40 }}>
            {includedUsers.map((u, i) => (
                <span className='archk-user-icon' key={i}>
                    <ObjectFinder collection="users" _id={u} showAvatar={true} />
                </span>
            ))}

            {extraUsers.length ? (
                <span className='archk-user-icon'>
                    <NameCircle contact={{ given_name: '+', family_name: (extraUsers.length).toString() }} width={35} />
                </span>
            ) : ''}
        </span>
    )
}

export default UserIcons;