import { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';

import api from 'api';
import { setMultipleObjects } from 'store/functions/objects'

import formatObject from 'utils/formatObject'
import formatText from 'utils/formatText'
import Dots from 'components/markup/loading/Dots';
import NameCircle from 'components/markup/misc/NameCircle';

let queue = {};
let timeout;

const batchQueue = (collection, _id) => {
    if(!queue[collection]) {
        queue[collection] = []
    }

    if(!queue[collection].includes(_id)) {
        queue[collection].push(_id)
    }
    clearTimeout(timeout)
    let tries = 0
    timeout = setTimeout(async () => {
        tries ++;
        if(tries > 5) return
        const keys = Object.keys(queue);
        const collections = queue;
        queue = {}
        if(!keys) return;
        const data = await api.query.batch(collections);
        if(data.data) {

            // filter out empty objects if items are not found to not cause continuous polling
            Object.keys(data.data).forEach(key => {
                if(!data.data[key].length) {
                    delete data.data[key];
                }
            })
            if(Object.keys(data.data).length) setMultipleObjects(data.data)
        }
    }, 100)
}

const ObjectFinder = ({collection, _id, errMessage, populate, objects, formatter, trim, showAvatar}) => {
    // we only want to get the store at the time this loads so we don't wrap this in
    // react-redux connect; This will cause this component to rerender any time an 
    // object gets added to state
    // const objects = store.getState().objects;
    const [foundObject, setFoundObject] = useState(null)
    const [err, setErr] = useState(null)

    useEffect(() => {
        let timeout = setTimeout(() => {
            if(!foundObject) setErr(true)
        }, 3000)

        return () => {
            clearTimeout(timeout)
        }
    }, [foundObject])

    useEffect(() => {

        if(!foundObject) {
            try {
                const obj = objects[collection][_id] ? objects[collection][_id] : null;
                if(obj) return setFoundObject(obj);
                batchQueue(collection, _id, populate)
            } catch(e) {
            }
        }
    }, [collection, _id, foundObject, populate, objects]);

    if(err) return <><i className="fas fa-info-circle mr-2 text-info" /> Item Not Found</>
    if(foundObject === null) return <Dots />
    if(formatter) return formatter(foundObject)
    let string = _id;

    if(showAvatar) {
        if(foundObject.avatar) return <img src={foundObject.avatar} alt="..." />
        return <NameCircle contact={foundObject} width={35} />
    }

    if(foundObject.name) {
        string = foundObject.name;
    } else if(foundObject.display_name) {
        string = foundObject.display_name
    } else if(foundObject.title) {
        string = foundObject.title;
    } else if(foundObject.given_name) {
        string = formatObject(foundObject).name()
    } else if(foundObject.phone) {
        string = formatText(foundObject.phone).phone()
    } else if(foundObject.email) {
        string = foundObject.email;
    } else {
        string = errMessage ? errMessage : "Name Not Found";
    }

    if(trim && string) {
        string = string.length > trim ? string.slice(0, trim) + '...' : string
    }

    return string;
}


const mapStateToProps = state => {
	return {
	    objects: state.objects,
	};
};

export default memo(connect(mapStateToProps, '')(ObjectFinder));