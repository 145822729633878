import { useCallback } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";

import SearchCollections from "components/system/Search/Collections";

import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';

import formatText from 'utils/formatText'

const ContactRow = ({workflowContacts, parties, setName, setParties}) => workflowContacts.map((workflowContact, i) => {
    const found = parties.find(p => p.workflow_contact === workflowContact._id)
    let key = workflowContact._id
    if(found) key += found.contact

    return (
        <SearchCollections
            key={key}
            collection={'contacts'}
            showEnhanced={"contacts"}
            title={(
                <span className=" d-block">
                    {workflowContact.name} 
                    {found && found.contact ? 
                        <i style={{fontSize: 18}} className="float-right font-weight-bold fas fa-check-circle ml-2 text-success " /> : (
                            <span>
                                <i 
                                    style={{fontSize: 18}} 
                                    className="float-right font-weight-bold fas fa-times-circle ml-2 text-danger " 
                                />
                            </span>
                        )}


                </span>
            )}
            value={found ? found.contact : ''}
            onChange={(obj) => {
                const _parties = JSON.parse(JSON.stringify(parties))
                const foundIndex = _parties.findIndex(r => r.workflow_contact === workflowContact._id)

                if(foundIndex !== -1) {
                    _parties[foundIndex].contact = obj.value
                } else {
                    _parties.push({
                        contact: obj.value,
                        workflow_contact: workflowContact._id,
                    })
                }

                if(workflowContact.main_contact) {
                    console.log(obj)
                    setName(
                        obj.obj.display_name ? obj.obj.display_name :
                        obj.obj.phone ? formatText(obj.obj.phone).phone() : 
                        obj.obj.email ? obj.obj.email : 
                        obj.family_name_unformatted
                    )
                }
                setParties(_parties)
            }}
        /> 
    )
})

const MattersCreateAssignContacts = ({setView, workflowContacts, parties, setParties, setName}) => {
    const main_contacts = workflowContacts.filter(c => c.main_contact)
    const secondary_contacts = workflowContacts.filter(c => !c.main_contact)

    const hasMain = workflowContacts.some(c => {
        if(c.main_contact) {
            if(parties.some(p => p.workflow_contact === c._id)) return true
        }
        return false;
    })

    const onMoveOn = useCallback(() => {
        if(!hasMain) return toast.info(`At least one of the matters main contacts must be selected before proceeding.`)
        setView('assignRoles')
    }, [setView, hasMain])


    if(!workflowContacts.length) return <Circle />

  
    
    return (
        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0"><i className="fas text-success fa-user-plus mr-2" /> Main Contacts</CardTitle>
                </CardHeader>

                {!hasMain ? (
                    <div className="alert alert-warning mb-0">At least one of the matters Main Contacts below must be set.</div>
                ) : ''}
                
                <CardHeader className="form-control-label-block">

                    <ContactRow 
                        workflowContacts={main_contacts}
                        parties={parties}
                        setParties={setParties}
                        setName={setName}
                    />

                </CardHeader>

                {secondary_contacts.length ? (
                    <div>
                        <CardHeader>
                            <CardTitle className="mb-0"><i className="fas text-info fa-users mr-2" /> Secondary Contacts</CardTitle>
                        </CardHeader>

                        <CardBody className="form-control-label-block">

                            <ContactRow 
                                workflowContacts={secondary_contacts}
                                parties={parties}
                                setParties={setParties}
                                setName={setName}
                            />

                        </CardBody>
                    </div>
                ) : ''}
               
            
                <CardFooter className="text-right">
                    <button onClick={onMoveOn} className="btn btn-success">Move On <i className="fas fa-arrow-right" /></button>
                </CardFooter>
            </Card>

        </div>
    )

}

export default MattersCreateAssignContacts;