import { connect } from 'react-redux';
import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';

import { Alert, Card, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import api from 'api';
import Table from 'components/functional/tables/Standard';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';

import A from 'components/markup/links/A';

import ObjectFinder from 'components/system/Objects/Finder';

import ReactHTMLParser from 'html-react-parser';

const UserCommentsAll = ({ selected_division, viewing_user }) => {

    const [ loading, setLoading ] = useState(false);
    // const [ category, setCategory ] = useState(null);
    const [ read, setRead ] = useState('unread');
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage, filter: {} }
        if(!params.filter) params.filter = {}

        if(read === 'unread') params.filter.unread = true;
        if(selected_division) params.filter.division = selected_division._id

        const query = await api.user_comments.search({ ...params, isCSV })

        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

   

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onSetFilter = useCallback((field, value) => {
        if(field === 'read') {
            setRead(value)
        } else {
            // setCategory(value)
        }

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 300)
    }, [])

    const getURL = useCallback((row) => {
        let url = `/matters/${row.matter}`

        if(row.collection_name === 'outstanding_items') {
            url += `?tab=Items&selectedItem=${row.collection_id}`
        } else if(row.collection_name === 'bk_garnishments') {
            url += `?showModule=bk&moduleTab=Garnishments&garnishment=${row.collection_id}`
        } else if(row.collection_name === 'bk_objections') {
            url += `?showModule=bk&moduleTab=Objections&objection=${row.collection_id}`
        }

        return url
    }, [])

    const columns = [
        {
            dataField: "_id",
            text: "Date",
            headerStyle: { width: 100 },
            formatter: (cell, row) => row.name === 'no data' ? 'No Mentions Found' : (
                <div>
                    <div >
                        <A 
                            className={row.read_by_users.includes(viewing_user._id) ? 'text-muted' : ''}
                            href={getURL(row)}
                        >
                            {moment.unix(row.created_at).format('MM/DD/YYYY')}
                        </A>
                        
                    </div>
                    <div>
                        <A 
                            className={row.read_by_users.includes(viewing_user._id) ? 'text-muted' : ''}
                            href={getURL(row)}
                        >
                            {moment.unix(row.created_at).format('h:mm A')}
                        </A>
                    </div>
                </div>
            )

        },
        {
            dataField: "matter",
            text: "Matter",
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <A className={row.read_by_users.includes(viewing_user._id) ? 'text-muted' : ''} href={getURL(row)}>
                        <ObjectFinder collection="matters" _id={row.matter} />
                    </A>
                </div>
            )
        },
        {
            dataField: "parent_name",
            text: "Item & User",
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <div className={row.read_by_users.includes(viewing_user._id) ? 'text-muted' : ''}>{row.parent_name}</div>
                    <div className={row.read_by_users.includes(viewing_user._id) ? 'text-muted' : ''}><i className="fas fa-comments mr-2 text-info" /> <ObjectFinder collection="users" _id={row.created_by} /></div>
                </div>
            )
        },
        {
            dataField: "body",
            text: "Mention",
            formatter: (cell, row) => {
                return <small>{row.body ? ReactHTMLParser(row.body) : ''}</small>
            }
        },
        {
            dataField: "_id",
            text: " ",
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-right">
                    <A className={row.read_by_users.includes(viewing_user._id) ? 'btn btn-outline-success' : 'btn btn-info'} href={getURL(row)}>Open</A>
                </div>
            )
        },

    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <>
    
            <Helmet>
                <title>{`All Mentions`}</title>
                <meta name="description" content="Mentions" />
            </Helmet>

            <HeaderNavigation 
                title={"Mentions"}
                description="All @s"
                leftColSize={5}
                actionComponent={(
                    <div >
                        <UncontrolledDropdown>
                            <DropdownToggle color={read ? 'warning' : 'outline-warning'} style={{width: 150}}>
                                {read !== null ? <span>{read}<i className="fas fa-sort-amount-down ml-2" /></span> : 'All Mentions'}
                                {/* Unread  <i className="fas fa-sort-amount-down ml-2" /> */}
                            </DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={e => onSetFilter('read', 'unread')}>Unread</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('read', null)}>All</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
    
                    </div>
                )}
            />
    
            <Container fluid>
                <Card className="card-color card-primary">    
                    {loading ? <Circle /> : (
                        <Table
                            placeholder="Notifications"
                            hideSearch={true}
                            query={query}
                            columns={columns}
                            data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                            totalDocuments={tableData.total_documents}
                            sizePerPage={sizePerPage}
                            onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                            
                            rowClasses={(row, rowIndex) => {
                                return row.read_at ? 'bg-secondary text-muted' : ' bg-white text-dark';
                            }}
                        />
                    )}
                </Card>

            </Container>
    
        </>
    )

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(UserCommentsAll);
