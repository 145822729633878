import api from 'api';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/functional/modals/Confirmation';
import ObjectFinder from 'components/system/Objects/Finder';
import reactHTMLParser from 'html-react-parser';

import { Card, CardBody } from 'reactstrap';

import Comment from './Comment';

import moment from 'moment';

import { toast } from 'react-toastify';

const UserComment = ({ comment, collection_name, collection_id, parent_name, viewing_user, onSaved, zIndex }) => {

    const [now] = useState(Math.floor(new Date() / 1000));
    const [editing, setEditing] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const [reply, setReply] = useState(false);

    const onSetReply = useCallback(async () => {
        let users = comment.tagged_users;
        users.push(comment.created_by);
        users = [...new Set(users)];
        users = users.filter(u => u !== viewing_user._id)

        const result = await api.users.search({ filter: { _id: { $in: users } } })
        if(!result.success) return;

        let string = '<p>'

        result.data.documents.forEach(d => {
            string += `<span class="mention" data-index="0" data-denotation-char="@" data-id="${d._id}" data-value="${d.display_name}">﻿<span contenteditable="false">@${d.display_name}</span>﻿</span>`
        })

        string += ' </p>'

        setReply(string)
    }, [comment.created_by, comment.tagged_users, viewing_user._id])

    const onDelete = useCallback(async() => {
        const data = await api.user_comments.delete(comment._id);
        if(data.data) return onSaved(data.data)

        toast.error(`Could not delete comment at this time, please try again.`)

    }, [comment._id, onSaved])

    return (
        <div className='mb-5'>
            <p className='text-sm mb-0'>
                <b className='text-dark text-uppercase' style={{fontSize: 16}}><ObjectFinder collection="users" _id={comment.created_by} /></b>
                <span className='pl-3'>
                    {moment.unix(comment.created_at).format('MMM Do, YYYY h:mm A')}
                    {/* {comment.updated_at !== comment.created_at ? (
                        <span> - Edited At {moment.unix(comment.updated_at).format('MMM Do, YYYY h:mm A')}</span>
                    ) : ''} */}
                </span>
                {comment.tagged_users.includes(viewing_user._id) ? <span style={{fontSize: 20}} className='text-success font-weight-bold pl-3'>@</span> : ''}

            </p>

            {editing ? (
                <Comment 
                    comment={comment}
                    collection_name={collection_name} 
                    collection_id={collection_id}
                    parent_name={parent_name}
                    onSaved={(data) => {
                        onSaved(data)
                        setEditing(false)
                    }}
                />
            ) : (
                <Card className='rounded bg-secondary mb-0 comment-container'>
                    <CardBody className='bg-se rounded pt-3 pl-3 pr-3 pb-2'>
                        {reactHTMLParser(comment.body)}
                    </CardBody>
                </Card>
            )}

           {!editing ? (
             <p className='text-sm mt- pl-3'>
                {viewing_user._id === comment.created_by ? (
                    comment.created_at > now - 86400 ? (
                        <div>
                            {console.log('herer')}
                            <b onClick={() => setEditing(true)} className='cursor-pointer'>Edit</b>
                            <b onClick={() => setShowDelete(true)} className='ml-4 cursor-pointer'>Delete</b>
                        </div>
                    ) : (
                        <div className='mb--3' />
                    )
                   
                ) : (
                    <div>
                        <b className='cursor-pointer' onClick={onSetReply} >Reply</b>
                    </div>
                )}
            </p>
           ) : ''}

           {reply ? (
            <div className='p-4 bg-secondary'>
                 <Comment 
                    reply={reply}
                    collection_name={collection_name} 
                    collection_id={collection_id}
                    parent_name={parent_name}
                    onSaved={(data) => {
                        onSaved(data)
                        setReply(false)
                    }}
                />
            </div>
           ) : ''}


        <ConfirmationModal 
            showModal={showDelete}
            toggleModal={() => setShowDelete(false)}
            title={"Remove Comment"}
            onConfirmation={onDelete}
            zIndex={zIndex ? zIndex : 2000}
            body={(
                <span>
                    Are you sure you wish to delete this comment?
                </span>
            )}
        />

        </div>
    );
  };
  

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(UserComment);