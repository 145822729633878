
import { useCallback, useState } from "react";

import moment from 'moment';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import A from 'components/markup/links/A'

import UserIcons from 'components/markup/icons/UserIcons';
import formatText from 'utils/formatText';

import ModalToggler from 'components/functional/modals/Toggler';
import EditITem from 'components/system/OutstandingItems/Edit';
import ObjectFinder from 'components/system/Objects/Finder';
import { components } from "react-select";


const ItemCard = ({ item, timestamp, fetchData }) => {

    const [newComment, setNewComment] = useState(null)

    const finishedChecklist = item.checklist ? item.checklist.filter(c => c.finished) : []
    const unfinishedChecklist = item.checklist ? item.checklist.filter(c => !c.finished) : []

    const date = 
        timestamp === 'due_date' ? item.due_date : 
        timestamp === 'next_follow_up_at' ? item.next_follow_up_at : 
        item.date

    let checklistMarkup = [];

    if(item.checklist && item.checklist.length) {
        unfinishedChecklist.forEach((c, i)=> checklistMarkup.push(<i key={i + '-unfinished'} className="fas fa-times-circle text-danger mr-2" />))
        finishedChecklist.forEach((c, i)=> checklistMarkup.push(<i key={i + '-finished'} className="fas fa-check-circle text-success mr-2" />))
    }

    const getTagName = useCallback((name) => {
        if(!name) return ''
        return name.replace(/_/g, ' ')
    }, [])

    const getMetaData = useCallback((metadata) => {
        const keys = metadata ? Object.keys(metadata) : []
        if(!keys.length) return <></>;

        let markup = [];

        keys.forEach((k, i) => {
            markup.push(<Badge key={i} color="info" className="my-2" >{getTagName(metadata[k])} </Badge>)
        })

        return markup
    }, [getTagName])

    return (
        <ModalToggler 
            component={EditITem}
            item={item}
            findTasks={fetchData}
            onItemChange={fetchData}
            onCommentWasLeft={(d) => setNewComment(d)}
        >

            <Card className={`cursor-pointer     rounded ${item.overdue ? 'border-danger' : 'border-dark'}`}>
                <CardHeader className="py-3 rounded">
                    <Row>
                        <Col md={5} className="align-self-center">
                            <UserIcons users={item.assigned_to} />
                        </Col>

                        <Col md={7} className="align-self-center text-right">
                            {item.category ? <Badge color="info" className="my-2" >{getTagName(item.category)} </Badge> : ''}
                            {item.module ? <Badge color="info" className="my-2" >{getTagName(item.module)} </Badge> : ''}
                            {getMetaData(item.metadata)}
                            {!item.client_action_needed ? <Badge color="purple" className="my-2" ><i className="fas fa-user mr-2" /> Action</Badge> : ''}
                            {item.priority === 1 ? (
                                <Badge color="danger"><i className="fas fa-tag mr-2" /> Highest </Badge>
                            ) : item.priority === 2 ? (
                                <Badge color="warning"><i className="fas fa-tag mr-2" /> High </Badge>
                            ) : item.priority === 3 ? (
                                <Badge color="yellow"><i className="fas fa-tag mr-2" /> Normal </Badge>
                            ) : item.priority === 4 ? (
                                <Badge color="purple"><i className="fas fa-tag mr-2" /> Low </Badge>
                            )  : item.priority === 5 ? (
                                <Badge color="success"><i className="fas fa-tag mr-2" /> Lowest </Badge>
                            ) : ''}
                            {item.overdue ? <Badge color="danger" className="my-2" ><i className="fas fa-clock" /></Badge> : ''}

                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="bg-secondary border-top border-bottom">
                    <p className="mb-0"><ObjectFinder collection="matters" _id={item.matter}/>  <span className="float-right cursor-pointer text-info"><A  href={`/matters/${item.matter}`}><i className="fas fa-link text-info" /></A></span></p>
                </CardBody>

                <CardFooter className="py-3">
                    <CardTitle className="mb-0 text-dark text-uppercase">
                        {formatText(item.name).stripHTML(40)}
                    </CardTitle>
                    {item.description ? (
                        <p className="text-sm mb-0 text-muted">{item.description}</p>
                    ) : ''}
                </CardFooter>

                {newComment ? (
                    <CardFooter className="">
                         <Row>
                             <div className="col-auto align-self-center" style={{width: 50}}>
                                 <UserIcons users={[newComment.created_by]} />
                             </div>
                             <Col className="align-self-center">
                                 <p className="text-sm mb-0">{formatText(newComment.body).stripHTML(100)}</p>
                             </Col>
                         </Row>
                     
                     </CardFooter>
                ) : item.last_comment ? (
                    <CardFooter className="">
                        <Row>
                            <div className="col-auto align-self-center" style={{width: 50}}>
                                <UserIcons users={[item.last_comment_by]} />
                            </div>
                            <Col className="align-self-center">
                                <p className="text-sm mb-0">{formatText(item.last_comment).stripHTML(100)}</p>
                            </Col>
                        </Row>
                    
                    </CardFooter>
                ): ''}
                {checklistMarkup.length ? (
                    <CardFooter className="">
                        <span className="text-muted"><i className="fas fa-list" /> </span>
                        <span className="float-right">{checklistMarkup}</span>
                    </CardFooter>
                ): ''}

                <CardFooter className="rounded">
                    <Row>
                        <Col md={6}>
                            <p className="text-sm text-dark mb-0">{moment.unix(date).format('MM/DD/YYYY h:mm A')}</p>
                        </Col>
                        <Col md={6} className="text-right">
                            <p className="text-sm mb-0">
                                <i className={`fas fa-paperclip ${item.documents.length ? 'text-info' : ''}`} /> {item.documents.length}
                                {' '}
                                <i className={`fas fa-comment pl-4 ${item.last_comment_counter ? 'text-info' : ''}`} /> {item.last_comment_counter}
                            </p>
                        </Col>
                    </Row>

                </CardFooter>
            </Card>
        </ModalToggler>
    )
}

export default ItemCard