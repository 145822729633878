import { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Col, Modal, Row } from "reactstrap";

import { toast } from 'react-toastify'

import Credits from './Credits';
import Details from './Details';
import Discounts from './Discounts';
import Expenses from './Expenses';
import Fees from './Fees';
import Overview from './Overview';
import Tabs from './Tabs';
import WriteOffs from './WriteOffs';

import moment from 'moment';
import api from 'api'

import Circle from 'components/markup/loading/Circle'

import { toggleStandardLoader } from 'store/functions/system/system';

const baseState = {
    sent            : false,
    name              : '',
    description       : '',
    total             : 0,
    total_paid        : 0,
    
    total_fees        : 0,
    total_expenses    : 0,
    total_credits     : 0,
    total_write_offs  : 0,
    total_discounts   : 0,
    total_refunds     : 0,
    
    fees              : [],
    expenses          : [],
    credits           : [],
    write_offs        : [],
    discounts         : [],
    refunds           : [],
}

const ModalCustomFields = ({showModal, toggleModal, onSaved, matter_id, invoice_id}) => {

    const [invoice, setInvoice] = useState(baseState)

    const [tab, setTab] = useState('details')
    const [errs, setErrs] = useState([])
    const [loaded, setLoaded] = useState(false)

    const setTotals = useCallback((_invoice) => {
        let total             = 0;
        let total_fees        = 0;
        let total_expenses    = 0;
        let total_credits     = 0;
        let total_write_offs  = 0;
        let total_discounts   = 0;
        let total_refunds     = 0;

        _invoice.fees.forEach(o => {
            if(o.billable) {
                total += o.amount
                total_fees += o.amount
            }
        })
        _invoice.expenses.forEach(o => {
            total += o.amount
            total_expenses += o.amount
        })
        _invoice.credits.forEach(o => {
            total -= o.amount
            total_credits += o.amount
        })
        _invoice.write_offs.forEach(o => {
            total -= o.amount
            total_write_offs += o.amount
        })
        _invoice.discounts.forEach(o => {
            total -= o.amount
            total_discounts += o.amount
        })
        _invoice.refunds.forEach(o => {
            total -= o.amount
            total_refunds += o.amount
        })

        _invoice.fees.sort((a, b) => {
            return parseInt(moment(a.date).format('X')) < parseInt(moment(b.date).format('X')) ? 1 : -1
        })

        _invoice.total = total;
        _invoice.total_fees = total_fees;
        _invoice.total_expenses = total_expenses;
        _invoice.total_credits = total_credits;
        _invoice.total_write_offs = total_write_offs;
        _invoice.total_discounts = total_discounts;
        _invoice.total_refunds = total_refunds;

        return _invoice;

    }, [])

    const onSetInvoice = useCallback((field, val) => {
        const _invoice = JSON.parse(JSON.stringify(invoice))
        _invoice[field] = val;
        setInvoice(_invoice)
    }, [invoice])
   
    const onAddArrayItem = useCallback((field, obj) => {
        let _invoice = JSON.parse(JSON.stringify(invoice))
        _invoice[field].push(obj)
        _invoice = setTotals(_invoice);
        setInvoice(_invoice)
    }, [invoice, setTotals])

    const onRemoveArrayItem = useCallback((field, index) => {
        let _invoice = JSON.parse(JSON.stringify(invoice))
        _invoice[field].splice(index, 1)
        _invoice = setTotals(_invoice);
        setInvoice(_invoice)
    }, [invoice, setTotals])

    const onSave = useCallback(async () => {
        if(!invoice.name) return toast.info('The invoice must have a name.')

        let saved;

        toggleStandardLoader(true)
        if(invoice_id) {
            saved = await api.invoices.update(invoice_id, invoice)
        } else {
            saved = await api.invoices.create({
                ...invoice,
                matter: matter_id,
            })
        }
        toggleStandardLoader(false)

        if(!saved.success) return setErrs(saved.message)

        toggleModal()
        setInvoice(baseState)
        if(onSaved) onSaved(saved.data)


    }, [invoice, matter_id, invoice_id, onSaved, toggleModal])

    const fetchData = useCallback(async () => {
        const found = await api.invoices.findById(invoice_id)
        if(!found.data) return toast.error('Something went wrong trying to access this invoice. Please Try again')
        setInvoice(found.data)
        setLoaded(true)
    }, [invoice_id])

    useEffect(() => {
        if(!showModal) return setLoaded(false)
        if(!invoice_id) return setLoaded(true);
        fetchData()
    }, [invoice_id, showModal, fetchData])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="lg"
        >

            {!loaded ? (
                <div className='modal-body'>
                    <Circle className="py-6" />
                </div>
            ) : (
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">{invoice_id ? 'Edit Invoice' : 'Create Invoice'}</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                            >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Row>
                            <Col md={3}>
                                <Overview invoice={invoice} />
                            </Col>
                            <Col md={9}>
                            
                            <Tabs tab={tab} setTab={setTab} />

                                {tab === 'details' ? (
                                    <Details invoice={invoice} onSetInvoice={onSetInvoice} />
                                ) : tab === 'fees' ? (
                                    <Fees invoice={invoice} onAddArrayItem={onAddArrayItem} onRemoveArrayItem={onRemoveArrayItem} />
                                ) : tab === 'expenses' ? (
                                    <Expenses invoice={invoice} onAddArrayItem={onAddArrayItem} onRemoveArrayItem={onRemoveArrayItem} />
                                )  : tab === 'credits' ? (
                                    <Credits invoice={invoice} onAddArrayItem={onAddArrayItem} onRemoveArrayItem={onRemoveArrayItem} />
                                ) : tab === 'discounts' ? (
                                    <Discounts invoice={invoice} onAddArrayItem={onAddArrayItem} onRemoveArrayItem={onRemoveArrayItem} />
                                ) : tab === 'write_offs' ? (
                                    <WriteOffs invoice={invoice} onAddArrayItem={onAddArrayItem} onRemoveArrayItem={onRemoveArrayItem} />
                                )  : ''}

                            
                            </Col>
                        </Row>

                    </div>

                    {errs.length ? (
                        <div className='modal-body bg-secondary border-top'>
                            {errs.map((e, i) => (
                                <p key={i} className='text-s mb-0 text-warning font-weight-bold'>*{e}</p>
                            ))}
                        </div>
                    ) : ''}

                    <div className="modal-footer">
                        <button className="btn btn-outline-warning" onClick={toggleModal}>
                            Close
                        </button>
                        
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2" /> Save Invoice
                        </button>
                    </div>

                </div>
            )}

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);