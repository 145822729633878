import { Row, Col } from 'reactstrap';

const InternalSidebar = ({title, tabs, tab, setTab, className, children, width, isAdmin, secondTitle, secondTabs, thirdTitle, thirdTabs, fourthTitle, fourthTabs, sidebarStyles,classNames}) => (
    <Row className={`${isAdmin ? 'archk-internal-sidebar-admin' : 'archk-internal-sidebar'} ${classNames ? classNames : ''}`}>
        <div 
            className={`col-auto archk-internal-sidebar-col-left ${className ? className : ''}`} 
            style={{
                width: width ? width : 150,
                ...sidebarStyles ? sidebarStyles : {}
            }}
        >
            <h2>{title}</h2>

            <ul>
                {tabs.map((t, i) => (
                    <li 
                        key={i} 
                        onClick={() => {
                            if(!t.disabled) setTab(t.value)
                        }} 
                        className={`${t.disabled ? 'disabled' : ''} ${t.value === tab ? 'active' : ''} ${t.type === 'Separator' ? 'mt-4' : ''}`}
                    >
                        {t.name}
                    </li>
                ))}
            </ul>

            {secondTitle && secondTabs ? (
                <div>
                    <h2>{secondTitle}</h2>

                    <ul>
                        {secondTabs.map((t, i) => (
                            <li 
                                key={i} 
                                onClick={() => {
                                    if(!t.disabled) setTab(t.value)
                                }} 
                                className={`${t.disabled ? 'disabled' : ''} ${t.value === tab ? 'active' : ''} ${t.type === 'Separator' ? 'mt-4' : ''}`}
                            >
                                {t.name}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : ''}
    
            {thirdTitle && thirdTabs ? (
                <div>
                    <h2>{thirdTitle}</h2>

                    <ul>
                        {thirdTabs.map((t, i) => (
                            <li 
                                key={i} 
                                onClick={() => {
                                    if(!t.disabled) setTab(t.value)
                                }} 
                                className={`${t.disabled ? 'disabled' : ''} ${t.value === tab ? 'active' : ''} ${t.type === 'Separator' ? 'mt-4' : ''}`}
                            >
                                {t.name}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : ''}
            {fourthTitle && fourthTabs ? (
                <div>
                    <h2>{fourthTitle}</h2>

                    <ul>
                        {fourthTabs.map((t, i) => (
                            <li 
                                key={i} 
                                onClick={() => {
                                    if(!t.disabled) setTab(t.value)
                                }} 
                                className={`${t.disabled ? 'disabled' : ''} ${t.value === tab ? 'active' : ''} ${t.type === 'Separator' ? 'mt-4' : ''}`}
                            >
                                {t.name}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : ''}

        </div>
        <Col>
            {children}
        </Col>

    </Row>
)

export default InternalSidebar;