import { useCallback, useState } from 'react';
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

import ModalSendEmail from 'components/system/Email/Modal';

import { Modal } from "reactstrap";

const ModalShowText = ({showModal, toggleModal, trainingNotes}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Email Response</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body mt-4 text-center border-bottom bg-secondary">

            <div className='alert alert-info'>Training Points:</div>

            <iframe 
                title="email body"
                className='border bg-white custom-shadow-1 p-3'
                style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    height: 450,
                    overflow: 'auto'
                }}
                src={"data:text/html,"+encodeURIComponent(trainingNotes)}
            />


        </div>

        <div className="modal-footer">
            <button className="btn btn-outline-warning" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
)

      
const OutstandingItemsEdit = ({ matter, division, item, call, onSent, type, viewing_user }) => {

    const [showModal, setShowModal] = useState(false)

    const getEmailResponse = useCallback(async () => {
        let result;
        toggleStandardLoader(true)
        if(type === 'item') {
            result = await api._ai.emails.item(item)
        } else if(type === 'items') {
            result = await api._ai.emails.items(matter)
        } else if(type === 'call') {
            result = await api._ai.training.call(call)
        }
        toggleStandardLoader(false)

        if(!result.data || (result.data && !result.data.notes)) return toast.info(`Could not generate email at this time.`)

        const { training_notes } = result.data.notes
        setShowModal({ training_notes })

    }, [call, item, matter, type])

    if(viewing_user._id !== '631e5c687418496a7ab0cb37') return <></>

    return (

       <>

            <button onClick={getEmailResponse} className='btn btn-outline-info'>
                <i className="fa-solid fa-wand-magic-sparkles mr-2" />
                Training Info
            </button>

            {showModal ? (
                <ModalShowText 
                    zIndex={1000000000}
                    showModal={showModal ? true : false}
                    toggleModal={() => setShowModal(false)}
                    trainingNotes={showModal.training_notes}
                />
            ) : ''}

        </>

    )
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,

    };
};

export default connect(mapStateToProps, '')(OutstandingItemsEdit);