import { Card, CardBody, CardHeader, CardTitle, CardFooter, FormGroup, Input, Col, Row } from 'reactstrap';
import { formatCurrency } from 'utils/currency';

import { useCallback, useState } from 'react';
import { toast } from 'react-toastify'

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api'

import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment'

const Payments = ({ invoices, matter, fetchData }) => {

    const [state, setState] = useState({
        reason: 'Reimbursement For Filing Fees',
        payment_method: 'ECH/EFT/Wire Transfer',
        to_account_number: 'General',
        party: 'FM',
        amount: matter.billing_in_trust,
        date: new Date(new Date().setDate(new Date().getDate()-1)),
        for_invoice: false,
    })
    const [loading, setLoading] = useState(false)

    const onInputChange = useCallback((field, val) => {
        const _state = JSON.parse(JSON.stringify(state))
        _state[field] = val;
        setState(_state)
    }, [state])

    const onSave = useCallback(async () => {
        const amount = parseFloat(state.amount)
        const date = moment(state.date).format('YYYY-MM-DD')
        if(Number.isNaN(amount) || !amount || amount < 0) return toast.info(`Payment amount must be greater than 0`)
        if(amount > matter.billing_in_trust) return toast.info(`Amount to charge must not be greater than the total amount in trust`)
        if(!state.party) return toast.info(`The "Pay To" field must not be left blank`)

        if(state.payment_method === 'ECH/EFT/Wire Transfer') {

            if(!state.transaction_reference) return toast.info(`Transaction reference cannot be left blank.`)
            if(!state.to_account_number) return toast.info(`The "To Account Number" field cannot be left blank.`)

        } else if(state.payment_method === 'Printed Check') {

            if(!state.check_series) return toast.info(`Check Series cannot be left blank.`)
            if(!state.check_number) return toast.info(`Check Number cannot be left blank.`)

        } else if(state.payment_method === 'Written Check' || state.payment_method === 'Bank Check/Money Order') {
            if(!state.check_number) return toast.info(`Check Number cannot be left blank.`)
        }

        let payment = undefined;

        setLoading(true)

        if(state.for_invoice) {
            const charged = await api.payments.charge({
                matter              : matter._id,
                amount              : parseFloat(amount),
                type                : 'cash',
                date                : parseFloat(moment().format('X')),
                description         : 'Payment From Trust',
                is_trust_movement   : true
            })

            if(charged.data && charged.data.payments && charged.data.payments[0]) {
                payment = charged.data.payments[0]._id
            } else {
                setLoading(false)
                let err = 'Could not charge payment to invoice, please try again or double check amounts. This is most likely caused if you are trying to pay an invoice for an amount greater than the value left on the matter.'
                if(charged.message && charged.message[0]) err = charged.message[0]

                return toast.info(err)
            }

        }

        const result = await api.payment_trust_entries.create({ 
            ...state, 
            amount, 
            date, 
            payment,
            matter: matter._id,
            action_type: 'payment' 
        })
        setLoading(false)

        if(!result.success) return toast.error(`Could not create trust entry at this time, please try again.`)

        toast.success(`Payment successfully added!`)
        fetchData()
    }, [fetchData, matter._id, matter.billing_in_trust, state])

    return (
        <Card>
            <CardHeader>
                <Row>
                    <Col md={6} className='align-self-center'>
                        <CardTitle className="mb-0"><i className="fas fa-minus-square text-danger mr-2" /> Make A Payment</CardTitle>
                    </Col>

                    <Col md={6} className='align-self-center text-right text-dark font-weight-bold'>
                        <CardTitle className="mb-0">Currently In Trust: {formatCurrency(matter.billing_in_trust)}</CardTitle>
                    </Col>
                </Row>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <label className='form-control-label'>Payment Amount</label>
                            <Input 
                                type="number"
                                value={state.amount}
                                onChange={e => onInputChange('amount', e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <DatePicker 
                            value={new Date(state.date)}
                            onChange={(date) => onInputChange('date', date)}
                            title="Payment Date"
                        />
                    </Col>
                </Row>

                <FormGroup>
                    <label className='form-control-label'>Pay To*</label>
                    <Input 
                        type="text"
                        value={state.party}
                        onChange={e => onInputChange('party', e.target.value)}
                    />
                </FormGroup>

                {invoices.length ? (
                    <div>
                         <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-trust-for-invoice"
                                type="checkbox"
                                checked={state.for_invoice}
                                onChange={(e) => onInputChange('for_invoice', !state.for_invoice)}
                            />
                            <label className="custom-control-label" htmlFor="archk-trust-for-invoice">
                                Designate the value of this payment as going towards matter invoices
                            </label>
                        </div>
                    </div>
                ) : ''}

            </CardBody>

            <CardFooter className='mt-3'>
                <Row>
                    <Col md={6}>
                        <ReactSelect
                            title={"Reason For Payment"}
                            formGroup={true}
                            onChange={(obj) => onInputChange('reason', obj.value)}
                            options={[
                                { label: 'Reimbursement For Filing Fees', value: 'Reimbursement For Filing Fees' },
                                { label: 'Client\'s Portion Of Garnishment Recovery', value: 'Client\'s Portion Of Garnishment Recovery' },
                                { label: 'Earned Fees', value: 'Earned Fees' },
                                { label: 'Post-Filing Garnishment', value: 'Post-Filing Garnishment' },
                                { label: 'Refund', value: 'Refund' },
                                { label: 'Settlement', value: 'Settlement' },
                                { label: 'Other', value: 'Other' },
                            ]}
                            value={state.reason}
                        />   
                    </Col>
                    <Col md={6}>
                        <ReactSelect
                            title={"Payment Method"}
                            formGroup={true}
                            onChange={(obj) => onInputChange('payment_method', obj.value)}
                            options={[
                                { label: 'ECH/EFT/Wire Transfer', value: 'ECH/EFT/Wire Transfer' },
                                { label: 'Printed Check', value: 'Printed Check' },
                                { label: 'Written Check', value: 'Written Check' },
                                { label: 'Bank Check/Money Order', value: 'Bank Check/Money Order' },
                            ]}
                            value={state.payment_method}
                        />   
                    </Col>
                </Row>

                {state.payment_method === 'ECH/EFT/Wire Transfer' ? (
                    <div>
                        <FormGroup>
                            <label className='form-control-label'>Transaction Reference*</label>
                            <Input 
                                type="textarea"
                                value={state.transaction_reference}
                                onChange={e => onInputChange('transaction_reference', e.target.value)}
                            />
                        </FormGroup>
                        
                        <FormGroup>
                            <label className='form-control-label'>To Account Number*</label>
                            <Input 
                                type="textarea"
                                value={state.to_account_number}
                                onChange={e => onInputChange('to_account_number', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ) : state.payment_method === 'Printed Check' ? (
                    <div>
                        <FormGroup>
                            <label className='form-control-label'>Check Series*</label>
                            <Input 
                                type="textarea"
                                value={state.check_series}
                                onChange={e => onInputChange('check_series', e.target.value)}
                            />
                        </FormGroup>
                        
                        <FormGroup>
                            <label className='form-control-label'>Check Number*</label>
                            <Input 
                                type="textarea"
                                value={state.check_number}
                                onChange={e => onInputChange('check_number', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ) : state.payment_method === 'Written Check' || state.payment_method === 'Bank Check/Money Order' ? (
                    <FormGroup>
                        <label className='form-control-label'>Check Number*</label>
                        <Input 
                            type="textarea"
                            value={state.check_number}
                            onChange={e => onInputChange('check_number', e.target.value)}
                        />
                    </FormGroup>
                ) : ''}

                <FormGroup>
                    <label className='form-control-label'>Memo</label>
                    <Input 
                        type="textarea"
                        value={state.memo}
                        onChange={e => onInputChange('memo', e.target.value)}
                    />
                </FormGroup>

            </CardFooter>

            <CardFooter className='text-right'>
                <button disabled={loading} onClick={onSave} className='btn btn-sm btn-success'>Save Adjustment</button>
            </CardFooter>

         
        </Card>
    )

}

export default Payments