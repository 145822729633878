import { useState } from 'react';
import { Nav, NavItem, NavLink } from "reactstrap";

import AdjustTrust from './AdjustTrust'
import MakeAPayment from './MakeAPayment'
import ReceiveFunds from './ReceiveFunds'
import History from './History'

const ModalCustomFields = ({ matter, invoices, fetchData }) => {

    const [tab, setTab] = useState('MakeAPayment')

    return (

        <div>

            <Nav
                className="nav-fill flex-column flex-sm-row my-3"
                id="tabs-text"
                pills
                role="tablist"
            >

                <NavItem>
                    <NavLink
                        aria-selected={tab === 'MakeAPayment'}
                        className={`mb-sm-3 mb-md-0 ${tab === 'MakeAPayment' ? 'active' : ''}`}
                        onClick={e => setTab('MakeAPayment')}
                        role="tab"
                    >
                        Make A Payment
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        aria-selected={tab === 'ReceiveFunds'}
                        className={`mb-sm-3 mb-md-0 ${tab === 'ReceiveFunds' ? 'active' : ''}`}
                        onClick={e => setTab('ReceiveFunds')}
                        role="tab"
                    >
                        Receive Funds
                    </NavLink>
                </NavItem>
        
                <NavItem>
                    <NavLink
                        aria-selected={tab === 'AdjustTrust'}
                        className={`mb-sm-3 mb-md-0 ${tab === 'AdjustTrust' ? 'active' : ''}`}
                        onClick={e => setTab('AdjustTrust')}
                        role="tab"
                    >
                        Adjust Trust
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        aria-selected={tab === 'History'}
                        className={`mb-sm-3 mb-md-0 ${tab === 'History' ? 'active' : ''}`}
                        onClick={e => setTab('History')}
                        role="tab"
                    >
                        History
                    </NavLink>
                </NavItem>
        
            </Nav>

            {tab === 'MakeAPayment' ? (
                <MakeAPayment 
                    matter={matter} 
                    fetchData={fetchData}
                    invoices={invoices}
                />
            ) : tab === 'ReceiveFunds' ? (
                <ReceiveFunds 
                    matter={matter} 
                    fetchData={fetchData} 
                />
            ) : tab === 'AdjustTrust' ? (
                <AdjustTrust 
                    matter={matter} 
                    fetchData={fetchData} 
                />
            ) : ''}

            <History 
                matter={matter} 
                fetchData={fetchData} 
            />

        </div>
    
    )
}


export default ModalCustomFields