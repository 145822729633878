
import { Card, CardBody } from "reactstrap";

const NoItemsFound = () => (
    <Card className="rounded border-success">
        <CardBody className="text-center py-3">
            <p className="text-sm mb-0"><i className="fas fa-check-circle text-success mr-2" /> No Items Found.</p>
        </CardBody>
    </Card>
)


export default NoItemsFound