import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Alert, Container } from "reactstrap";
import { getUrlParameter, setUrlParameter } from 'utils/urls';
import { formatCurrency } from 'utils/currency';

import api from 'api';
import formatText from 'utils/formatText';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import InternalSidebarAdmin from 'components/markup/layout/InternalSidebarAdmin';
import Circle from 'components/markup/loading/Circle';

import Charge from './Charge';
import Header from './Header';
import Invoices from './Invoices';
import Methods from './Methods';
import PaymentEvents from './PaymentEvents';
import Payments from './Payments';
import PaymentSubscriptions from './PaymentSubscriptions';
import Schedule from './Schedule';
import Trust from './Trust';

const tabs = ['invoices', 'methods', 'recurring', 'charge', 'trust', 'schedule', 'events', 'trust']

const ContactsAll = ({match}) => {

    const [ tab, setTab ] = useState('');
    const [ data, setData ] = useState(null);
    const [ error, setError ] = useState(false)

    const fetchData = useCallback(async () => {
        setData(null)
        const _data = await api.matters.billing(match.params.matter_id)
        if(!_data.success) return setError(_data.message)
        setData(_data.data)
    }, [match.params.matter_id])

    const onSetTab = useCallback((tab) => {
        setUrlParameter('tab', tab)
        setTab(tab)

        if(!tab) fetchData()
    }, [fetchData])

    useEffect(() => {
        fetchData()

        const _tab = getUrlParameter('tab')
        if(tabs.includes(_tab)) setTab(_tab)
    }, [fetchData])

    if(error) {
        return (
            <Container fluid >
                <Alert color="warning"><i className="fas fa-info-circle mr-2" /> The contact you are searching for could not be found..</Alert>
            </Container>
        )
    }
   
    if(!data) return <Circle className="py-5" />


    const description = data.contacts.map((contact, i) => {
        const name = formatText().contactIdentifier(contact);
        const spacer = i === 0 ? '' : ', '
        return spacer + name;
    })

    return (

        <>

        <Helmet>
            <title>{data.matter.name}</title>
            <meta name="description" content={data.matter.name} />
        </Helmet>


        <HeaderNavigation 
            title={<A href={`/matters/${data.matter.id}`}>{data.matter.name}</A>}
            description={(
                <p className='mb-0'>
                    <b className='text-uppercase'>
                        Paid Overview: {' '}
                        <b className='text-dark'>{formatCurrency(data.matter.billing_paid)} / {formatCurrency(data.matter.billing_total)}</b>
                    </b>
                </p>
            )}
            actionComponent={(
               <div>
                    <Link to={match.params.contact_id === '-' ? '/billing' : `/billing/${match.params.contact_id}`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> {match.params.contact_id === '-' ? 'Search Contacts' : 'All Matters'}
                    </Link>

                    {data.matter ? (
                        <A className="btn btn-success" href={`/matters/${data.matter.id}`}>
                            <i className="fas fa-link mr-2" /> Open Matter
                        </A>
                    ) : ''}
               </div>
            )}
        />

        {/* <Header data={data} /> */}

        <Container fluid>
    
          

            <InternalSidebarAdmin 
                classNames="archk-internal-sidebar-rounded"
                tab={tab}
                setTab={onSetTab}
                width={165}
                className="ml--4 mt--2"
                tabs={[
                    { value: '', name: 'Payments' },
                    { value: 'methods', name: 'Cards & ACH' },
                    { value: 'charge', name: 'Charge Payment' },
                    { value: 'recurring', name: 'Recurring Charges' },
                    { value: 'invoices', name: 'Invoices' },
                    { value: 'trust', name: 'Trust Payments' },
                    { value: 'schedule', name: 'Schedule' },
                    { value: 'events', name: 'Events' },
                ]}
            >  
                <div className='py-3 pl-3'>
                    {tab === '' ? (
                        <Payments 
                            fetchData={fetchData} 
                            matter={data.matter} 
                            payments={data.payments} 
                            setTab={setTab} 
                            payment_methods={data.payment_methods}
                        />
                    ) : tab === 'invoices' ? (
                        <Invoices 
                            fetchData={fetchData} 
                            invoices={data.invoices} 
                            matter={data.matter} 
                            setTab={setTab} 
                        />
                    ) : tab === 'methods' ? (
                        <Methods 
                            matter={data.matter} 
                            fetchData={fetchData} 
                            payment_methods={data.payment_methods} 
                            setTab={setTab} 
                        />
                    ) : tab === 'events' ? (
                        <PaymentEvents 
                            matter={data.matter} 
                            payment_events={data.payment_events} 
                        />
                    ) : tab === 'charge' ? (
                        <Charge 
                            matter={data.matter} 
                            payment_methods={data.payment_methods} 
                            payment_subscription={data.payment_subscription} 
                            fetchData={fetchData}
                            onSetTab={onSetTab}
                        />
                    ) : tab === 'recurring' ? (
                        <PaymentSubscriptions 
                            matter={data.matter} 
                            payment_methods={data.payment_methods} 
                            payment_subscription={data.payment_subscription} 
                            fetchData={fetchData}
                        />
                    ) : tab === 'schedule' ? (
                        <Schedule 
                            matter={data.matter} 
                            payment_methods={data.payment_methods} 
                            payment_subscription={data.payment_subscription} 
                            schedule={data.schedule}
                            fetchData={fetchData}
                        />
                    ) : tab === 'trust' ? (
                        <Trust 
                            matter={data.matter} 
                            invoices={data.invoices}
                            fetchData={fetchData}
                        />
                    ) : ''}
                </div>
            </InternalSidebarAdmin>


        </Container>

        </>
    )

}

export default ContactsAll