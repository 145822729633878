import { toast } from 'react-toastify';
import { useState, useEffect } from 'react'

import CopyToClipboard from 'react-copy-to-clipboard';

const ContactsUpdatePortal = ({contact, matters}) => {

    const [links, setLinks] = useState([])


    useEffect(() => {
        const _links = [];

        const divisions = new Set(matters ? matters.map(d => d.division) : []);

        divisions.forEach(d => {
            // FAIRMAX LAW - Bankruptcy
            if(d === '6376b82424e2233278fa3571') {
                _links.push({
                    name: 'Fairmax Law - Bankruptcy',
                    register: `https://fairmax.intuidesk.com/register?email=${contact.email}`,
                    login: `https://fairmax.intuidesk.com`
                })
            }

        })
        setLinks(_links)
    }, [contact.email, matters])

    if(!contact.email) return (
        <h2 className="text-center my-3 ">
            <i className="fas fa-info-circle text-warning mr-2" /> 
            A contact must have a valid email on file before their portal can be accessed.
        </h2>
    )

    if(!links.length) {
        return <h2 className="text-warning text-center my-3">No portal links were found for the matters associated to this contact.</h2>
    }


    return (
        <div>

            {links.map((l, i) => (
                <div>
                    <h2><b className='text-success'>Registration</b> - {l.name}</h2>

                    <span className="cursor-pointer">
                        <CopyToClipboard 
                            text={l.register}
                            onCopy={() => {
                                toast.info('Registartion Link Copied To Clipboard')
                            }}
                        >
                            <span>{l.register} <i className="fas fa-copy ml-2 text-info" /></span>
                        </CopyToClipboard>
                    </span>
                    
                    <h2 className='mt-5'><b className='text-success'>Login</b> - {l.name}</h2>

                    <span className="cursor-pointer">
                        <CopyToClipboard 
                            text={l.login}
                            onCopy={() => {
                                toast.info('Log Link Copied To Clipboard')
                            }}
                        >
                            <span>{l.login} <i className="fas fa-copy ml-2 text-info" /></span>
                        </CopyToClipboard>
                    </span>

                    {i !== links.length -1 ? <hr /> : ''}
                </div>
            ))}

        </div>
    )

}

export default ContactsUpdatePortal 