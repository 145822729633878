/*
Documentation

this page holds all the routes for the dashboard layout

*/

import hasPrivilege from 'utils/hasPrivilege'

import ViewPermission from 'components/markup/errors/ViewPermission';

import AccountSettings  from '../pages/account/Settings';
import AccountMFA       from '../pages/account/MFA';



import BillingLookupSearch     from '../pages/billing/lookup/Search';
import BillingSelectMatter     from '../pages/billing/lookup/SelectMatter';
import BillingView     from '../pages/billing/lookup/View';


import Calendar      from '../pages/calendar';

import CallsAll    from '../pages/calls/All';
import CallsView    from '../pages/calls/View';

import CallVoicemails    from '../pages/call_voicemails/All';

import ContactsAll      from '../pages/contacts/All';
import ContactsView     from '../pages/contacts/View';

import CompanySettings  from '../pages/company/Settings';

import CustomViews     from '../pages/custom_views';

import Directory     from '../pages/directory';
import DocumentsAll     from '../pages/documents/All';

import DeadlinesAll      from '../pages/deadlines/All';

import Emails     from '../pages/emails';

import EventsAll      from '../pages/events/All';

import LeadsAll    from '../pages/leads/All';
import LeadsView    from '../pages/leads/View';
import LeadsLive    from '../pages/leads/Live';

import Logout     from '../pages/logout';

import MattersAll      from '../pages/matters/All';
import MattersView     from '../pages/matters/View';
import MattersJourney     from '../pages/matters/Journey';
import MattersCreate     from '../pages/matters/Create';

import MatterProgressLogs     from '../pages/matter_progress_logs';

import Overview        from '../pages/overview/index';

import OutstandingItems        from '../pages/outstanding_items/All';
import OutstandingItemsDaily        from '../pages/outstanding_items/Daily';
import OutstandingItemsStatus        from '../pages/outstanding_items/Status';

import Pipeline        from '../pages/pipeline/index';

import Redirect     from '../pages/redirect';

import NotificationsAll     from '../pages/notifications/All';
import UserComments     from '../pages/user_comments/All';

import SigningAccess    from '../pages/signing/Access';
import SigningTemplates    from '../pages/signing/Templates/All';
import SigningDocumentsAll    from '../pages/signing/Documents/All';
import SigningDocumentsView    from '../pages/signing/Documents/View';

import SupportTickets     from '../pages/support_tickets';

import TasksAll    from '../pages/tasks/All';

import TimeOff    from '../pages/time_off/user';

import Team    from '../pages/team';

import Texts     from '../pages/texts';

import UsersAll     from '../pages/users/All';

import WorkCenter     from '../pages/work_center';

import ModuleBkNewCaseEntries     from 'components/mods/Bankruptcy/NewCaseEntries';
import ModuleBkItems     from 'components/mods/Bankruptcy/Items';

const routes = () =>  [


    // /***********************
    // DASHBOARD
    // ************************/
    // {
    //     path: "/invoices",
    //     component: InvoicesAll,
    // },
    
    /***********************
    SUPPORT TICKETS
    ************************/
    {
        path: "/support_tickets",
        component: hasPrivilege('VIEW.APP_DASHBOARD.SUPPORT_TICKETS') ? SupportTickets : ViewPermission,
    },
    /***********************
    Work Center
    ************************/
    {
        path: "/work_center",
        component: WorkCenter,
    },
    /***********************
    Billing
    ************************/
    {
        path: "/custom_views",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CUSTOM_VIEWS') ? CustomViews : ViewPermission,
    },
    /***********************
    Billing
    ************************/
    {
        path: "/billing",
        component: hasPrivilege('VIEW.APP_DASHBOARD.BILLING') ? BillingLookupSearch : ViewPermission,
    },
    {
        path: "/billing/:_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.BILLING') ? BillingSelectMatter : ViewPermission,
    },
    {
        path: "/billing/:contact_id/:matter_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.BILLING') ? BillingView : ViewPermission,
    },
    /***********************
    Billing
    ************************/
    {
        path: "/leads",
        component: hasPrivilege('VIEW.APP_DASHBOARD.LEADS') ? LeadsAll : ViewPermission,
    },
    {
        path: "/leads/live",
        component: hasPrivilege('VIEW.APP_DASHBOARD.LEADS') ? LeadsLive : ViewPermission,
    },
    {
        path: "/leads/:lead_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.LEADS') ? LeadsView : ViewPermission,
    },
    {
        path: "/leads/search/:type",
        component: hasPrivilege('VIEW.APP_DASHBOARD.LEADS') ? LeadsAll : ViewPermission,
    },


    /***********************
    DOCUMENTS
    ************************/
    {
        path: "/documents",
        component: hasPrivilege('VIEW.APP_DASHBOARD.DOCUMENTS') ? DocumentsAll : ViewPermission,
    },
    /***********************
    DOCUMENTS
    ************************/
    {
        path: "/directory",
        component: Directory,
    },
    /***********************
    ACCOUNT
    ************************/
    {
        path: "/settings/mfa",
        component: AccountMFA,
    },
    {
        path: "/settings",
        component: AccountSettings,
    },

    {
        path: "/company/settings",
        component: CompanySettings,
    },

    /***********************
    Calls
    ************************/
    {
        path: "/calls",
        component: hasPrivilege('VIEW.APP_DASHBOARD.PHONE_CALLS') ? CallsAll : ViewPermission,
    },
    {
        path: "/calls/:call_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.PHONE_CALLS') ? CallsView : ViewPermission,
    },
    /***********************
    Calls
    ************************/
    {
        path: "/voicemail",
        component: hasPrivilege('VIEW.APP_DASHBOARD.VOICEMAIL') ? CallVoicemails : ViewPermission,
    },
    /***********************
    Users
    ************************/
    {
        path: "/users",
        component: UsersAll,
    },
    /***********************
    Calendar
    ************************/
    {
        path: "/calendar",
        component: Calendar,
    },


    /***********************
    Notifications
    ************************/
    {
        path: "/notifications",
        component: NotificationsAll,
    },
    
    /***********************
    UserComments
    ************************/
    {
        path: "/mentions",
        component: UserComments,
    },
    
    /***********************
    Signing
    ************************/
    {
        path: "/signing/access",
        component: SigningAccess,
    },
    {
        path: "/signing/templates/all",
        component: SigningTemplates,
    },
    {
        path: "/signing/documents/all",
        component: SigningDocumentsAll,
    },
    {
        path: "/signing/documents/:signing_document_id",
        component: SigningDocumentsView,
    },


    /***********************
    Tasks
    ************************/
    {
        path: "/tasks",
        component: hasPrivilege('VIEW.APP_DASHBOARD.TASKS') ? TasksAll : ViewPermission,
    },
    /***********************
    Outstanding Items
    ************************/
    {
        path: "/outstanding_items",
        component: hasPrivilege('VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS') ? OutstandingItems : ViewPermission,
    },
    {
        path: "/outstanding_items/daily",
        component: hasPrivilege('VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS') ? OutstandingItemsDaily : ViewPermission,
    },
    {
        path: "/outstanding_items/status/:status",
        component: hasPrivilege('VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS') ? OutstandingItemsStatus : ViewPermission,
    },

    /***********************
    CONTACTS
    ************************/
    {
        path: "/clients",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsAll : ViewPermission,
    },
    {
        path: "/clients/create",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsAll : ViewPermission,
    },
    {
        path: "/clients/search/:type",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsAll : ViewPermission,
    },
    {
        path: "/clients/:contact_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsView : ViewPermission,
    },
    {
        path: "/clients/:contact_id/:page",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsView : ViewPermission,
    },
  
    {
        path: "/contacts",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsAll : ViewPermission,
    },
    {
        path: "/contacts/create",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsAll : ViewPermission,
    },
    {
        path: "/contacts/search/:type",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsAll : ViewPermission,
    },
    {
        path: "/contacts/:contact_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsView : ViewPermission,
    },
    {
        path: "/contacts/:contact_id/:page",
        component: hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? ContactsView : ViewPermission,
    },
   
    /***********************
    DEADLINES
    ************************/
    {
        path: "/deadlines",
        component: DeadlinesAll,
    },
    {
        path: "/events",
        component: hasPrivilege('VIEW.APP_DASHBOARD.EVENTS') ? EventsAll : ViewPermission,
    },

    /***********************
    MATTERS
    ************************/
    {
        path: "/matters",
        component: hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') ? MattersAll : ViewPermission,
    },
    {
        path: "/matters/search/:type",
        component: hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') ? MattersAll : ViewPermission,
    },
    {
        path: "/matters/create",
        component: hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') ? MattersCreate : ViewPermission,
    },
    {
        path: "/matters/:_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') ? MattersView : ViewPermission,
    },
    {
        path: "/matters/:_id/journey",
        component: hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') ? MattersJourney : ViewPermission,
    },

    /***********************
    MATTER PROGRESS LOGS
    ************************/
    {
        path: "/matter_progress",
        component: MatterProgressLogs,
    },

   

    {
        path: "/pipeline",
        component: hasPrivilege('VIEW.APP_DASHBOARD.PIPELINE') ? Pipeline : ViewPermission,
    },
    {
        path: "/team",
        component: Team,
    },
    
    {
        path: "/time_off",
        component: TimeOff,
    },
    {
        path: "/time_off/pending",
        component: TimeOff,
    },
    {
        path: "/time_off/all",
        component: TimeOff,
    },
    {
        path: "/time_off/request",
        component: TimeOff,
    },
  
    {
        path: "/texts",
        component: hasPrivilege('VIEW.APP_DASHBOARD.TEXTS') ? Texts : ViewPermission,
    },
    {
        path: "/texts/:thread_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.TEXTS') ? Texts : ViewPermission,
    },

    {
        path: "/emails/:thread_id",
        component: hasPrivilege('VIEW.APP_DASHBOARD.EMAILS') ? Emails : ViewPermission,
    },
    {
        path: "/emails",
        component: hasPrivilege('VIEW.APP_DASHBOARD.EMAILS') ? Emails : ViewPermission,
    },


    
    {
        path: "/modules/bk/new_case_entries",
        component: ModuleBkNewCaseEntries,
    },
   
    {
        path: "/modules/bk/items",
        component: ModuleBkItems,
    },

    {
        path: "/logout",
        component: Logout,
    },

    {
        path: "/soft_redirect",
        component: Redirect,
    },
    {
        path: "/",
        component: Overview,
    },

   
]

export default routes;
