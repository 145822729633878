import { Card, CardBody, CardHeader, CardTitle, CardFooter, FormGroup, Input, Col, Row } from 'reactstrap';

import { useCallback, useState } from 'react';
import { toast } from 'react-toastify'

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api'

import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment';

const Payments = ({ matter, fetchData }) => {

    const [state, setState] = useState({
        reason: 'Garnishment Recovery',
        payment_method: '',
        to_account_number: 'General',
        amount: '',
        date: new Date(new Date().setDate(new Date().getDate()-1)),
        for_invoice: false,
    })
    const [loading, setLoading] = useState(false)

    const onInputChange = useCallback((field, val) => {
        const _state = JSON.parse(JSON.stringify(state))
        _state[field] = val;
        setState(_state)
    }, [state])

    const onSave = useCallback(async () => {
        const amount = parseFloat(state.amount)
        const date = moment(state.date).format('YYYY-MM-DD')
        if(Number.isNaN(amount) || !amount || amount < 0) return toast.info(`Payment amount must be greater than 0`)
        if(amount > matter.billing_in_trust) return toast.info(`Amount to charge must not be greater than the total amount in trust`)
        if(!state.party) return toast.info(`The "Received From" field must not be left blank`)
        if(!state.party_address) return toast.info(`The "Received From Address" field must not be left blank`)

        if(state.payment_method === 'ECH/EFT/Wire Transfer') {

            if(!state.transaction_reference) return toast.info(`Transaction reference cannot be left blank.`)

        } else if(state.payment_method === 'Printed Check') {

            if(!state.check_series) return toast.info(`Check Series cannot be left blank.`)
            if(!state.check_number) return toast.info(`Check Number cannot be left blank.`)

        } else if(state.payment_method === 'Check' || state.payment_method === 'Bank Check/Money Order') {
            if(!state.check_date) return toast.info(`Check Date cannot be left blank.`)
            if(!state.check_from) return toast.info(`Check From cannot be left blank.`)
            if(!state.check_number) return toast.info(`Check Number cannot be left blank.`)
        }


        setLoading(true)
        const result = await api.payment_trust_entries.create({ 
            ...state, 
            amount, 
            date, 
            matter: matter._id,
            action_type: 'received' 
        })
        setLoading(false)

        if(!result.success) return toast.error(`Could not create trust entry at this time, please try again.`)

        toast.success(`Entry successfully added!`)
        fetchData()
    }, [fetchData, matter._id, matter.billing_in_trust, state])

    return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-plus-square text-success mr-2" /> Receive Funds</CardTitle>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <label className='form-control-label'>Amount Received</label>
                            <Input 
                                type="number"
                                value={state.amount}
                                onChange={e => onInputChange('amount', e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <DatePicker 
                            value={state.date}
                            onChange={(date) => onInputChange('date', date)}
                            title="Date Banked"
                        />
                    </Col>
                </Row>

                <FormGroup>
                    <label className='form-control-label'>Received From*</label>
                    <Input 
                        type="text"
                        value={state.party}
                        onChange={e => onInputChange('party', e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className='form-control-label'>Received From Address*</label>
                    <Input 
                        type="textarea"
                        value={state.party_address}
                        onChange={e => onInputChange('party_address', e.target.value)}
                    />
                </FormGroup>

            </CardBody>

            <CardFooter className='mt-3'>
                <Row>
                    <Col md={6}>
                        <ReactSelect
                            title={"Reason For Payment"}
                            formGroup={true}
                            onChange={(obj) => onInputChange('reason', obj.value)}
                            options={[
                                { label: 'Garnishment Recovery', value: 'Garnishment Recovery' },
                                { label: 'Case Settlement', value: 'Case Settlement' },
                                { label: 'Client Funded Their Account', value: 'Client Funded Their Account' },
                                { label: 'Filing Fees', value: 'Filing Fees' },
                                { label: 'Monthly ACH', value: 'Monthly ACH' },
                                { label: 'Opening Balance', value: 'Opening Balance' },
                                { label: 'Zero Down Payment Plan', value: 'Zero Down Payment Plan' },
                                { label: 'Other', value: 'Other' },
                            ]}
                            value={state.reason}
                        />   
                    </Col>
                    <Col md={6}>
                        <ReactSelect
                            title={"Payment Method"}
                            formGroup={true}
                            onChange={(obj) => onInputChange('payment_method', obj.value)}
                            options={[
                                { label: 'ECH/EFT/Wire Transfer', value: 'ECH/EFT/Wire Transfer' },
                                { label: 'Check', value: 'Check' },
                                { label: 'Bank Check/Money Order', value: 'Bank Check/Money Order' },
                                { label: 'Cash', value: 'Cash' },
                                { label: 'Credit Card', value: 'Credit Card' },
                                { label: 'Debit Card', value: 'Debit Card' },
                                { label: 'Client Direct Deposit Via Bank', value: 'Client Direct Deposit Via Bank' },
                            ]}
                            value={state.payment_method}
                        />   
                    </Col>
                </Row>

            </CardFooter>

            <CardFooter>
                {state.payment_method === 'ECH/EFT/Wire Transfer' ? (
                    <div>
                        <FormGroup>
                            <label className='form-control-label'>Transaction Reference*</label>
                            <Input 
                                type="textarea"
                                value={state.transaction_reference}
                                onChange={e => onInputChange('transaction_reference', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ) : state.payment_method === 'Check' || state.payment_method === 'Bank Check/Money Order' ? (
                    <div>
                        <FormGroup>
                            <label className='form-control-label'>Check Date*</label>
                            <Input 
                                type="text"
                                value={state.check_date}
                                onChange={e => onInputChange('check_date', e.target.value)}
                            />
                        </FormGroup>
                        
                        <FormGroup>
                            <label className='form-control-label'>Check From*</label>
                            <Input 
                                type="text"
                                value={state.check_from}
                                onChange={e => onInputChange('check_from', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label'>Check Number*</label>
                            <Input 
                                type="text"
                                value={state.check_number}
                                onChange={e => onInputChange('check_number', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ) : ''}

                <FormGroup>
                    <label className='form-control-label'>Memo</label>
                    <Input 
                        type="textarea"
                        value={state.memo}
                        onChange={e => onInputChange('memo', e.target.value)}
                    />
                </FormGroup>
            </CardFooter>

            <CardFooter className='text-right'>
                <button disabled={loading} onClick={onSave} className='btn btn-sm btn-success'>Save Adjustment</button>
            </CardFooter>

        </Card>
    )

}

export default Payments