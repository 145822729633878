import { useCallback, useEffect, useState } from 'react';

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import { closeDocumentCenter, openDocumentCenter } from 'store/functions/document_center';

const UserSettingsMatter = () => {

    const [user, setUser] = useState(null)

    const fetchData = useCallback(async () => {
        const viewing_user = await api.users.me()
        setUser(viewing_user.data)
    }, [])


    const onUpload = useCallback(async (doc) => {

        toggleStandardLoader(true)
        const updated = await api.users.update(user._id, { avatar: doc.url })
        toggleStandardLoader(false)

        closeDocumentCenter()
        fetchData()
        console.log(updated)

    }, [fetchData, user])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!user) return <Circle className="pt-4" />

    return (

        <Card>

            <CardHeader>
                <Row>
                    <Col md={6} className='align-self-center'>
                        <h6 className="heading-small text-muted">Profile Picture</h6>
                    </Col>

                    <Col md={6} className='text-right align-self-center'>
                        <button 
                            onClick={(e) => openDocumentCenter({url: '/v1/company_documents', onUpload: onUpload, resizePixels: 150})} 
                            className="btn btn-info"
                        >
                            Upload / Change Image
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            <CardBody>

                 <div className='text-center py-6'>
                    {user.avatar ? (
                        <img 
                            className='border z-depth-1'
                            alt="profile avatar"
                            src={user.avatar} 
                            style={{
                                width: 150,
                                height: 150,
                                borderRadius: '100%'
                            }}
                        />
                    ) : (
                        <p className='text-sm mb-0'>No image uploaded.</p>
                    )}
                 </div>

            </CardBody>

        </Card>



    );
}

export default UserSettingsMatter