import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container } from "reactstrap";
import { toast } from "react-toastify";

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';


import api from 'api';
import moment from 'moment';

import formatText from 'utils/formatText';
import sanitizeHtml from 'sanitize-html';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';


const ContactsAll = ({match, viewing_user, matter, COLORS}) => {

    const [hideDocuments, setHideDocuments] = useState(false)
    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const result = await api.matters.journey(match.params._id)
        if(result.data) return setData(result.data)

        toast.error(`Could not load the matter journey, please refresh your page or check the link you are on.`)
    }, [match.params._id])
  
    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <div className="py-6"><Circle /></div>
    
    return (

        <div className="archk-matter-page">

            <Helmet>
                <title>JOURNEY - {data.matter.name}</title>
                <meta name="description" content={data.matter.name} />
            </Helmet>


            <Container className="bg-secondary" fluid>

                <h2 className="text-center py-5 text-uppercase">
                    {data.matter.name}
                    {' '}
                    {hideDocuments ? (
                        <i className="fas fa-file text-danger cursor-pointer ml-3 ." onClick={() => setHideDocuments(!hideDocuments)} />
                    ) : (
                        <i className="fas fa-file text-success cursor-pointer ml-3 ." onClick={() => setHideDocuments(!hideDocuments)} />
                    )}
                </h2>

                <VerticalTimeline>
                    {data.data.map((d, i) => {
                        if(d.__type === 'document' && hideDocuments) return <span key={d._id}></span>

                        let background;
                        let title;
                        let subtitle;
                        let body;
                        let icon

                        switch (d.__type) {
                            case 'call':
                                background = COLORS.green
                                title = d.direction === 'inbound' ? 'Incoming Call' : 'Outgoing Call'
                                subtitle = formatText(d.duration).secondsToTime(null, true)
                                body = (
                                    <div>
                                        {d.call_legs.map((t, i) => (
                                            <div>
                                                {t.user ? (
                                                    <span><ObjectFinder collection="users" _id={t.user} /></span>
                                                ) : (
                                                    <span><ObjectFinder collection="contacts" _id={t.contact} /></span>
                                                )}
                                            </div>
                                        ))}
                                        <div className="mt-3">{d.ai_summary ? d.ai_summary : d.ai_transcription ? d.ai_transcription : 'No summary found for this call'}</div>
                                    </div>
                                )
                                icon = d.direction === 'inbound' ? <i className="fas fa-phone" /> : <i className="fas fa-headset" />
                                break;
                            case 'call_voicemail':
                                background = COLORS.purple
                                title = 'Voicemail Left'
                                subtitle = formatText(d.recording_duration).secondsToTime(null, true)
                                body = d.transcription_text ? d.transcription_text : 'No transcription was found for this voicemail'
                                icon = <span><i className="fas fa-voicemail" /></span>
                                break;
                            case 'document':
                                background = COLORS.teal
                                title = <span>Document Uploaded - {d.uploaded_by ? <ObjectFinder collection={d.upload_type === 'user' ? 'users' : 'contacts'} _id={d.uploaded_by} /> : 'System'}</span>
                                subtitle = <b className="text-uppercase">{d.name}</b>
                                // body = <span> {d.ai_summary ? d.ai_summary : 'No Summary Found'}</span>
                                icon = <i className="fas fa-file" />
                                break;
                            case 'email':
                                background = COLORS.yellow
                                title = d.outbound ? 'Outbound Email' : 'Incoming Email'
                                subtitle = (
                                    <div>
                                        <div>From: {d.from && d.from[0] ? d.from[0].name + ' - ' + d.from[0].email : ''}</div>
                                        {d.to && d.to.length ? d.to.map((t, i) => (
                                            <div key={i}>To: {t.name + ' - ' + t.email}</div>
                                        )) : ''}
                                    </div>
                                )
                                body = <span style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>{formatText(sanitizeHtml(d.body)).stripHTML()}</span>
                                icon = <i className="fas fa-envelope" />
                                break;
                            case 'event':
                                    background = COLORS.red
                                    title = <span>Event Booked For: {moment.unix(d.__date).format('MM/DD/YYYY h:mm A')}</span>
                                    subtitle = d.name
                                    body = d.participants && d.participants.length ? d.participants.map((t, i) => (
                                        <div key={i}>{t.name} - {t.email}</div>
                                    )) : ''
                                    icon = <i className="fas fa-calendar" />
                                    break;
                            case 'form_submission':
                                    background = COLORS.orange
                                    title = <span>Submitted The Form <ObjectFinder collection="forms" _id={d.form} /></span>
                                    subtitle = <span>Timestamp: {moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</span>
                                    body = ''
                                    icon = <i className="fas fa-pen" />
                                    break;
                            case 'note':
                                    background = COLORS.indigo
                                    title = 'Note Left'
                                    subtitle = <ObjectFinder collection="users" _id={d.user} />
                                    body = formatText(d.value).stripHTML()
                                    icon = <i className="fas fa-note-sticky" />
                                    break;
                            case 'opt_out':
                                    background = COLORS.red
                                    title = 'Communication Opt Out Status Changed'
                                    subtitle = <span>Opted {d.opt_status === 'in' ? 'in' : 'out'} of text message communication</span>
                                    icon = <i className="fas fa-signal" />
                                    break;
                            case 'signing_document':
                                    background = COLORS.black
                                    title = 'Signed A Document'
                                    subtitle = d.name
                                    icon = <i className="fas fa-signature" />
                                    break;
                            case 'text':
                                    background = COLORS.green
                                    title = d.type === 'outbound' ? "Outbound Text Message" : 'Incoming Text Message'
                                    subtitle =<ObjectFinder collection={d.type === 'outbound' ? 'users' : 'contacts'} _id={d.type === 'outbound' ? d.user : d.contact} />
                                    icon = <i className="fas fa-mobile" />
                                    body = d.body
                                    break;
                            default:
                                break;
                        }

                        return (
                            <VerticalTimelineElement
                                key={d._id}
                                className="vertical-timeline-element--education"
                                date={moment.unix(d.__date).format('MMM Do, YYYY h:mm A')}
                                iconStyle={{ background: background, color: '#fff' }}
                                icon={icon}
                            >
                                <h3 className="vertical-timeline-element-title">{title}</h3>
                                <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
                                {body ? <p>{body}</p> : ''}
                            </VerticalTimelineElement>
                        )
                    })}
                </VerticalTimeline>
            </Container>

        </div>
    )

}

const mapStateToProps = state => {
	return {
        COLORS: state.config.COLORS,
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	    device: state.device,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);