import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Col, Container, Row } from "reactstrap";
import { Link } from 'react-router-dom'

import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import DatePicker from 'react-datepicker';

import api from 'api';

import ItemCard from "../_components/ItemCard";
import NoItemsFound from "../_components/NoItemsFound";

const MattersAll = ({selected_division, viewing_user}) => {

    const [day, setDay] = useState(new Date())
    const [data, setData] = useState(null)
    const [overdue, setOverdue] = useState([])
  
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async () => {
        setData(null)

        const start = parseInt(moment(day).startOf('day').format('X'))

        const result = await api.outstanding_items.findByDay(start)
        if(!result.success) return setErr(result.message)

        const now = Math.floor(new Date() / 1000)
        const _overdue = []

        result.data.due.forEach(d => {
            if(d.due_date && d.due_date < now) {
                d.overdue = true;
                d.date = d.due_date;
                _overdue.push(d)
            }
        })
        result.data.follow_up.forEach(d => {
            if(d.next_follow_up_at && d.next_follow_up_at < now) {
                d.date = d.next_follow_up_at;
                d.overdue = true;
                _overdue.push(d)
            }
        })

        _overdue.sort((a, b) => {
            return a.date < b.date ? -1 : 1
        })


        setOverdue(_overdue)
        setData(result.data)
    }, [day])

    useEffect(() => {
        fetchData()        

        document.body.classList.add('bg-secondary')

        return () => {
            document.body.classList.remove('bg-secondary')
        }

    }, [fetchData])

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = <div>Daily Items <small style={{position: 'relative', top: -6}} className="rounded px-4  py-3 bg-warning text-white text-sm">BETA</small></div>
    const description = 'View Item Follow Up and Due Dates'

    return (

        <>

        <Helmet>
            <title>Daily Items</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            leftColSize={9}
            actionComponent={(
                <div>
                    <p className="text-sm mb-0">Select Day</p>
                    <DatePicker 
                        selected={day} 
                        onChange={(date) => setDay(date)} 
                    />
                </div>
            )}
        />

        <Container fluid>

            {!data ? <div className="py-6"><Circle /></div> : (
                <div>
                    <Row>
                        <Col md={4}>
                            <Link to="/outstanding_items/status/due_date">
                                <div className="cursor-pointer alert mb-3 rounded alert-success z-depth-1 text-uppercase">
                                    Items Due ({data.due.length}) <i style={{fontSize: 18}} className="fa-solid fa-arrow-up-right-from-square float-right" />
                                </div>
                            </Link>

                            {data.due.length ? data.due.map(d => (
                                <ItemCard 
                                    key={d._id} 
                                    fetchData={fetchData}
                                    item={d} 
                                    timestamp="due_date"
                                />
                            )) : (
                                <NoItemsFound />
                            )}
                        </Col>
                        <Col md={4}>
                            <Link to="/outstanding_items/status/next_follow_up_at">
                                <div className="alert mb-3 rounded alert-info z-depth-1 text-uppercase">
                                    Follow Ups ({data.follow_up.length})
                                    <i style={{fontSize: 18}} className="fa-solid fa-arrow-up-right-from-square float-right" />
                                </div>
                            </Link>

                            {data.follow_up.length ? data.follow_up.map(d => (
                                <ItemCard 
                                    key={d._id} 
                                    fetchData={fetchData}
                                    item={d} 
                                    timestamp="next_follow_up_at"
                                />
                            )) : (
                                <NoItemsFound />
                            )}
                        </Col>

                        <Col md={4}>
                            <Link to="/outstanding_items/status/due_date">
                                <div className="alert mb-3 rounded alert-danger z-depth-1 text-uppercase">
                                    Overdue Items ({overdue.length})
                                    <i style={{fontSize: 18}} className="fa-solid fa-arrow-up-right-from-square float-right" />
                                </div>
                            </Link>

                            {overdue.length ? overdue.map(d => (
                                <ItemCard 
                                    key={d._id} 
                                    fetchData={fetchData}
                                    item={d} 
                                    timestamp=""
                                />
                            )) : (
                                <NoItemsFound />
                            )}
                        </Col>
                    </Row>


                </div>
            )}
        </Container>


        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
