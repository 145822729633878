import { connect } from 'react-redux';

import { useCallback, useEffect, useState } from 'react';


import Search from './Search'
import Pagination from './Pagination'
import TableHeader from './TableHeader'
import TableBody from './TableBody'

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";
import DatePicker from 'react-datepicker'


import { Row, Col, FormGroup, Input, CardHeader } from 'reactstrap';

import throttle from 'utils/throttle';
import moment from 'moment'

export const downloadCSV = (data, options) => {
    if(!options) options = {}
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + data;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${options.name ? options.name : 'export'}.csv`;
    hiddenElement.click();
}

const Table = ({ useLoader, viewing_user, sizePerPage, totalDocuments, columns, data, placeholder, query, rowClasses, actionComponent, hideSearch, searchFilters }) => {

    const defaultSize = sizePerPage ? sizePerPage : viewing_user.settings.default_table_size

    const [filterProps, setFilterProps] = useState({});
    const [size, setSize] = useState(defaultSize);
    const [searchString, setSearchString] = useState('')
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)

    const reload = useCallback(() => {
        query({
            searchString, 
            page,  
            skip: size * (page - 1),  
            sizePerPage: size,  
            limit: size,
            filterProps
        })
    }, [filterProps, page, query, searchString, size])

    const onSearchChange = useCallback((e) => {
        const val = e.target.value;
        setLoading(true)
        setSearchString(val)
        setPage(1)
        query({
            searchString: val, 
            page: 1,  
            skip: 0,  
            sizePerPage: size,  
            limit: size,
            filterProps
        })
    }, [query, size, filterProps])

    const onFilterPropChange = useCallback((field, val) => {
        const state = JSON.parse(JSON.stringify(filterProps))
        state[field] = val;
        setFilterProps(state)
        setPage(1)
        setLoading(true)
        query({
            searchString, 
            page: 1,  
            skip: 0,  
            sizePerPage: size,  
            limit: size,
            filterProps: state
        })
    }, [filterProps, query, searchString, size])

    const onPageChange = useCallback((_page) => {
        if(page === _page) return
        if(_page > Math.ceil(totalDocuments / size)) return
        if(_page <= 0) return;

        setPage(_page)
        setLoading(true)

        query({
            searchString, 
            page: _page,  
            skip: size * (_page - 1),  
            sizePerPage: size,  
            limit: size,
            filterProps
        })
    }, [page, totalDocuments, size, query, searchString, filterProps])

    const onSizeChange = useCallback((_size) => {
        _size = parseInt(_size)
        setSize(_size)
        setPage(1)
        setLoading(true)

        query({
            searchString, 
            page: 1,  
            skip: 0,  
            sizePerPage: _size,  
            limit: _size,
            filterProps
        })
    }, [query, searchString, filterProps])

    // only do this once on load
    useEffect(() => {

        const _filterProps = {}

        if(searchFilters) {
            searchFilters.forEach(f => {
                if(f.defaultValue) _filterProps[f.property] = f.defaultValue
            })
        }
        setFilterProps(_filterProps)
        query({
            searchString, 
            page: 1,  
            skip: 0,  
            sizePerPage: defaultSize,  
            limit: defaultSize,
            filterProps: _filterProps
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [data])

    return (
        <div>

            {searchFilters ? (
                <CardHeader>
                    <Row>
                    
                        {searchFilters.map((f, i) => (
                            <Col md={f.size}>
                                {f.type === 'select' ? (
                                    <ReactSelect
                                        title={f.title}
                                        placeholder=" "
                                        formGroup={true}
                                        onChange={(obj) => onFilterPropChange(f.property, obj.value)}
                                        options={f.options}
                                        value={filterProps[f.property] ? filterProps[f.property] : f.defaultValue ? f.defaultValue : ''}
                                    />    
                                )  : f.type === 'collectionSearch' ? (
                                    <SearchCollections
                                        collection={f.collection}
                                        title={f.title}
                                        placeholder=" "
                                        value={filterProps[f.property] ? filterProps[f.property] : f.defaultValue ? f.defaultValue : ''}
                                        onChange={(obj) => onFilterPropChange(f.property, obj.value)}
                                        filter={f.filter ? f.filter : {}}
                                    /> 
                                ) : f.type === 'textSearch' ? (
                                    <FormGroup>
                                        <label className='form-control-label'>{f.title}</label>
                                        <Input 
                                            onChange={throttle(onSearchChange, 1000, 6)}
                                            // value={searchString} 
                                            type="text" 
                                        />
                                    </FormGroup>
                                
                                ) : f.type === 'date' ? (
                                    <FormGroup>
                                        <label className='form-control-label'>{f.title}</label>
                                        <DatePicker 
                                            selected={filterProps[f.property] ? moment(filterProps[f.property]).toDate() : f.defaultValue ? moment(f.defaultValue).toDate() : null} 
                                            onChange={(date) => onFilterPropChange(f.property, date)}
                                            />
                                    </FormGroup>
                                ) : ''}
                            </Col>
                        ))}

                    </Row>
                </CardHeader>
            ) : (
                <Row>
                    <Col md={actionComponent ? 6 : 12} className='align-self-center'>
                        {hideSearch ? '' : (
                            <Search loading={loading} placeholder={placeholder} onSearchChange={onSearchChange} />
                        )}
                    </Col>
                    {actionComponent ? (
                        <Col md={6} className='align-self-center text-right'>
                            <div className='pr-1'>
                                <div className='pr-2'>
                                    {actionComponent}
                                </div>
                            </div>
                        </Col>
                    ) : ''}
                    
                </Row>
            )}
            
            <div className='table-responsive react-bootstrap-table position-relative'>
                <table className='table'>
                    <TableHeader columns={columns} />
                    <TableBody useLoader={useLoader} columns={columns} data={data} rowClasses={rowClasses} reload={reload} />
                </table>
            </div>

            <Pagination 
                totalDocuments={totalDocuments}
                size={size}
                page={page}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
            />

        </div>
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Table);