import { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';

import Edit from 'components/system/OutstandingItems/Edit';
import ItemList from 'components/system/OutstandingItems/ItemList';

import InfoBanner from '../_components/InfoBanner';

import HearingResults from './HearingResults';

const ModuleBKOverview = ({ bkCase, onMoveStage, items, fetchItems, stage, setBkCase }) => {

    const [state, setState] = useState({})

    const itemsPending = items ? items.filter(i => !i.finished_at) : null
    const itemsFinished = items ? items.filter(i => i.finished_at) : null

    const stageName = stage.replace(/_/g, ' ');

    if(!itemsPending && !itemsFinished) return <Circle />
  
    return (
        <div>

            <div className={`mb-3 rounded text-center border py-3 px-4 text-capitalize bg-gradient-${stage === bkCase.stage ? 'success' : 'info'}`}>
                <h2 className='text-white'>Stage: {stageName}</h2>
            </div>

            <InfoBanner bkCase={bkCase} />

            {stage === '341' ? (
                <HearingResults 
                    bkCase={bkCase}
                    setBkCase={setBkCase}
                    stage={stage}
                    type="341"
                />
            ) : stage === 'confirmation' ? (
                <HearingResults 
                    bkCase={bkCase}
                    setBkCase={setBkCase}
                    stage={stage}
                    type="confirmation"
                />
            ) : ''}
            

            <hr />

            <ItemList 
                onItemChange={fetchItems} 
                modalIndex={2500} 
                items={itemsPending} 
                finished={false} 
                hideAddNew={true} 
                cardHeader={(
                    <Row>
                        <Col md={6} className="align-self-center">
                            <h2 className='text-warning '>Unfinished Items</h2>
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            {/* <ModalToggler 
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                            >
                                <button className='btn btn-outline-warning  btn-sm'>
                                    Add Admin Item <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler> */}
                            <span className='pl-4'>

                            <ModalToggler 
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                                itemTypes={[
                                    {
                                        label: "Follow Up",
                                        options: [
                                            { value: 'Follow up with CLIENT(s)', label: 'Follow up with CLIENT(s)' },
                                            { value: 'Follow up with TRUSTEE', label: 'Follow up with TRUSTEE' },
                                            { value: 'Follow up with CREDITOR', label: 'Follow up with CREDITOR' },
                                            { value: 'Follow up with STAFF ATTORNEY', label: 'Follow up with STAFF ATTORNEY' },
                                            { value: 'Follow up OTHER', label: 'Follow up OTHER' },
                                        ]
                                    },
                                    {
                                        label: "Preparation",
                                        options: [
                                            { value: 'File Response to Motion', label: 'File Response to Motion', is_deadline: true },
                                        ]
                                    },
                                    {
                                        label: "Review",
                                        options: [
                                            { value: 'Review Secured Claim', label: 'Review Secured Claim', is_deadline: true },
                                            { value: 'Review Priority Claim', label: 'Review Priority Claim', is_deadline: true },
                                            { value: 'Review Notice of Unfiled Tax Returns', label: 'Review Notice of Unfiled Tax Returns', is_deadline: true },
                                            { value: 'Review Objection to Confirmation', label: 'Review Objection to Confirmation', is_deadline: true },
                                            { value: 'Review Plan Payment Change/Submit Payment order', label: 'Review Plan Payment Change/Submit Payment order', is_deadline: true },
                                            { value: 'Review Order Adjourning', label: 'Review Order Adjourning', is_deadline: true },
                                            { value: '*Review Deficiency Notice', label: '*Review Deficiency Notice', is_deadline: true },
                                        ]
                                    },

                                    ...(bkCase.chapter === 13) ? [{
                                        label: "Confirmation",
                                        options: [
                                            { value: 'File Confirmation Hearing Certificate', label: 'File Confirmation Hearing Certificate', lock_titles: true, metadata: { state: 'confirmation' } },
                                            { value: 'Submit OCP/Stipulation Adjourning', label: 'Submit OCP/Stipulation Adjourning', lock_titles: true, metadata: { state: 'confirmation' } },
                                            { value: 'Review Confirmation Hearing Results', label: 'Review Confirmation Hearing Results', lock_titles: true, metadata: { state: 'confirmation' } },
                                            { value: 'Draft Documents', label: 'Draft Documents', lock_titles: true, metadata: { state: 'confirmation' } },

                                        ]
                                    }] : [],
                                   

                                ]}
                            >
                                <button className='btn btn-outline-success  btn-sm'>
                                    Add New Item <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler>
                            </span>

                        </Col>
                    </Row>
                )}
            />

            <ItemList
                onItemChange={fetchItems}
                modalIndex={2500}
                items={itemsFinished}
                finished={true}
                hideAddNew={true}
                cardHeader={(
                    <h2 className='text-success'>Completed Stage Items</h2>
                )}
            />

            <hr />

            <div className='text-right'>
                <button onClick={() => onMoveStage(stage)} className='btn btn-success btn-sm'>Move Case To: {stageName}</button>
            </div>
           

        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


