import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import { toast } from 'react-toastify';

import api from 'api'

const ModalDivisionOverride = ({showModal, toggleModal, method, fetchData}) => {

    const [type, setType] = useState('')

    const onSave = useCallback(async () => {
        if(!type) return toast.info(`You must select the payment method type before proceeding.`)
        if(method.primary_method && type !== 'default') return toast.info(`This method is set as the default, to change this update another method to the files default of payment before continuing.`)

        const result = await api.payment_methods.update(method._id, { type })
        if(!result.success) return toast.error(result.message[0])

        toast.success(`Payment method updated successfully`)
        toggleModal()
        fetchData()

    }, [type, method.primary_method, method._id, toggleModal, fetchData])

    useEffect(() => {
        if(showModal) {
            setType(method.primary_method ? 'default' : method.backup_method ? 'backup' : '')
        }
    }, [showModal, method])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit Payment Method</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <ReactSelect
                    formGroup={true}
                    title="Set Method Type"
                    placeholder=""
                    onChange={(obj) => setType(obj.value)}
                    options={[
                        { value: '', label: '-' },
                        { value: 'default', label: 'Primary' },
                        { value: 'backup', label: 'Backup' },
                    ]}
                    value={type}
                />    

            </div>

            <div className='modal-body bg-secondary border-top'>
                <table className='table border'>

                    <thead>
                        <tr>
                            <th>Owner</th>
                            <th>Type</th>
                            <th className='text-right'>Last 4</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr key={method._id}>
                            <td>
                                <td>{method.owner_name}</td>
                            </td>
                            <td>{method.type === 'cc' || method.type === 'dc' ? 'Card' : method.type === 'ach' ? 'ACH' : '-'}</td>
                            <td className='text-right'><b style={{display: 'inline-block', width: 50}}>Last 4:</b> {method.last_4}</td>
                        </tr>
                    </tbody>

                </table>
            </div>

            <div className='modal-body border-top'>
                <div className='alert alert-info mb-0'>
                    <i className="fas fa-info-circle mr-2" /> Changing the primary and backup methods on a card will update all existing payment plans on the matter.
                </div>
            </div>


            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-outline-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Save
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);