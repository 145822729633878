import { useState, useEffect, useCallback } from 'react'

import Circle from 'components/markup/loading/Circle'
import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api'

import { toast } from 'react-toastify'

const SelectTemplate = ({ foundMatter, selectedTemplate, setSelectedTemplate }) => {

    const [templates, setTemplates] = useState(null)


    const fetchData = useCallback(async () => {
        if(!foundMatter) return;

        const result = await api.outstanding_item_templates.findByMatter(foundMatter._id)
        if(!result.success) return toast.info(`Could not load templates at this time, please try again.`)

        setTemplates(result.data)
        console.log(result)
    }, [foundMatter])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!templates) return <Circle />

    return (
        <div className='modal-body'>

            <ReactSelect
                title={"Select Template"}
                formGroup={true}
                placeholder=""
                onChange={(obj) => setSelectedTemplate(obj.doc)}
                options={templates.map(p => { return { value: p._id, label: p.name, doc: p } } )}
                value={selectedTemplate ? selectedTemplate._id : null}
            />    
        </div>
    )
}

export default SelectTemplate