import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { formatCurrency } from 'utils/currency';

import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import ModalToggler from 'components/functional/modals/Toggler'
import ConfirmationModal from 'components/functional/modals/Confirmation';

import moment from 'moment';

const Payments = ({matter, fetchData}) => {

    const [data, setData] = useState(false)

    const fetchHistory = useCallback(async () => {
        const result = await api.payment_trust_entries.findByMatter(matter._id);
        if(!result.success) return toast.error(`Could not fetch trust data, please refresh your page and try again.`)

        setData(result.data)

    }, [matter._id])

    const onReverse = useCallback(async (_id) => {
        const result = await api.payment_trust_entries.reverse(_id)
        if(!result.success) return toast.error(`could not reverse trust entry. please try again.`)

        toast.success(`Entry successfully reversed`)
        fetchData()
        
    }, [fetchData])

    useEffect(() => {
        fetchHistory()
    }, [fetchHistory])

    if(!data) return <div className='py-6'><Circle /></div>

    return (
        <Card>
            <CardHeader>
                <Row>
                    <Col md={6} className='align-self-center'>
                        <CardTitle className="mb-0">Trust History</CardTitle>
                    </Col>

                    <Col md={6} className='align-self-center text-right text-dark font-weight-bold'>
                        <CardTitle className="mb-0">Currently In Trust: {formatCurrency(matter.billing_in_trust)}</CardTitle>
                    </Col>
                </Row>
            </CardHeader>

            {data.length ? (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Date</th>
                                {/* <th>Payment Method</th> */}
                                <th>Reason</th>
                                {/* <th>Memo</th> */}
                                <th>Amount</th>
                                <th className='text-right'>Balance</th>
                                <th className='text-right'></th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map(d => (
                                <tr key={d._id}>
                                    <td>
                                        <div>{moment.unix(d.created_at).format('MM/DD/YYYY')}</div>
                                        <div>{d.user ? <ObjectFinder collection="users" _id={d.user} /> : 'System'}</div>
                                    </td>
                                    <td>
                                        <div>{moment(d.date).format('MM/DD/YYYY')}</div>
                                    </td>
                                    {/* <td>{d.payment_method}</td> */}
                                    <td>{d.reason ? d.reason : '-'}</td>
                                    {/* <td>{d.memo ? d.memo : '-'}</td> */}
                                    <td>
                                        <b className={d.action_type === 'payment' ? 'text-danger' : 'text-success'}>
                                            {d.action_type === 'payment' ? '-' : '+'}
                                            {formatCurrency(d.amount)}
                                        </b>
                                    </td>
                                    <td className='text-right'><b>{formatCurrency(d.balance)}</b></td>
                                    <td className='text-right'>
                                        { !d.reversed_at && !d.is_reversal && !d.payment && d.user ? (
                                            <ModalToggler
                                                component={ConfirmationModal} 
                                                title="Reverse Trust Entry"
                                                body={(
                                                    <div>
                                                        Are you sure you wish to reverse the trust entry from 
                                                        {' '}
                                                        {moment(d.date).format('MM/DD/YYYY')} in the amount of 
                                                        {' '}
                                                        <b className={d.action_type === 'payment' ? 'text-danger' : 'text-success'}>
                                                            {d.action_type === 'payment' ? '-' : '+'}
                                                            {formatCurrency(d.amount)}?
                                                        </b>
                                                        {' '}
                                                        This cannot be undone.

                                                        <hr />

                                                        {d.reason ? <div>Reason: {d.reason}</div> : ''}
                                                        {d.memo ? <div>Memo: {d.memo}</div> : ''}
                                                    </div>
                                                )}
                                                onConfirmation={() => onReverse(d._id)}
                                            >
                                                     <button className='btn btn-outline-warning'>Reverse</button>
                                            </ModalToggler>
                                           
                                        ) : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <CardBody>
                    <p className='text-sm mb-0'>No trust entries were found for this matter.</p>
                </CardBody>
            )}
         
        </Card>
    )

}

export default Payments