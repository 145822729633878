import { useState } from 'react';
import EditOutstandingItem from 'components/system/OutstandingItems/Edit'

const FinishTaskOutstandingItem = ({task, onFinish, toggleModal}) => {

    const [showModal, setShowModal] = useState(true)

    return <div className='py-3'>
        <EditOutstandingItem 
            showModal={showModal}
            toggleModal={() => {
                setShowModal(false)
                toggleModal()
            }}
            task={task._id}
            defaultName={task.name}
            defaultDescription={task.description}
            findTasks={onFinish}
            size="lg"
        />
    </div>
}

export default FinishTaskOutstandingItem