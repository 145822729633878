import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Badge, Card, Container } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';

import { getUrlParameter } from "utils/urls";

import api from 'api';


import UserIcons from 'components/markup/icons/UserIcons';
import formatText from 'utils/formatText';

import EditItem from 'components/system/OutstandingItems/Edit';

import ModalToggler from 'components/functional/modals/Toggler';


const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, viewing_user}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)

    const [sortType, setSortType] = useState('due_date')

    const [ err, setErr ] = useState(false)



    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};
        if(!params.filterProps) params.filterProps = {};

        const { sort_type, priority, last_comment, outstanding_item_categories, client_action_needed, missed_follow_up, uncompleted_checklist, date_start, date_end } = params.filterProps

        if(sort_type) setSortType(sort_type)


        const type = getUrlParameter('type')
        let rangeProp = 'due_date'

        params.filter.finished_at = 0
        params.filter.hidden = { $ne: true }

        if(selected_division._id) params.filter.division = selected_division._id
        if(type === 'overdue') params.filter.due_date = { $gt: 0, $lte: Math.floor(new Date() / 1000)}


        params.filter.hidden = { $ne: true }

        if(sort_type === 'next_follow_up_at' ) {
            params.sort = { next_follow_up_at: 1 }
            if(!params.filter.next_follow_up_at) params.filter.next_follow_up_at = { $gt: 0 }
            rangeProp = 'next_follow_up_at'
        } else if(sort_type === 'alphabetical') {
            params.sort =  { name: 1 }
        } else {
            params.sort =  { due_date: 1 }
            if(!params.filter.due_date) params.filter.due_date = { $gt: 0 }
        }

        if(priority !== undefined && priority !== 'all') params.filter.priority = priority
        if(last_comment !== undefined && last_comment !== 'all') params.filter.last_comment_by = last_comment ? { $ne: null } : null
        if(outstanding_item_categories) params.filter.outstanding_item_categories = { $in: [outstanding_item_categories] }
        if(client_action_needed !== undefined && client_action_needed !== 'all') params.filter.client_action_needed = client_action_needed
        if(missed_follow_up !== undefined && missed_follow_up !== 'all') params.filter.missed_follow_up = missed_follow_up
        if(uncompleted_checklist !== undefined && uncompleted_checklist !== 'all') params.filter.checklist_percent = uncompleted_checklist ? { $lt: 100 } : 100

        if(date_end && date_start) {
            params.filter[rangeProp] = { 
                $gte: parseInt(moment(date_start).startOf('day').format('X')),
                $lte: parseInt(moment(date_end).endOf('day').format('X')),
            }
        } else if(date_end) {
            if(params.filter[rangeProp]) {
                params.filter[rangeProp].$lte = parseInt(moment(date_end).endOf('day').format('X'))
            } else {
                params.filter[rangeProp] = { $lte: parseInt(moment(date_end).endOf('day').format('X')) }
            }
        } else if(date_start) {
            if(params.filter[rangeProp]) {
                params.filter[rangeProp].$gte = parseInt(moment(date_start).endOf('day').format('X'))
            } else {
                params.filter[rangeProp] = { $gte: parseInt(moment(date_start).endOf('day').format('X')) }
            }
        }

        const query = await api.outstanding_items.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "priority",
            text: 'Priority',
            headerStyle: { textAlign: 'center', width: 90 },

            formatter: (cell, row) => {
                if(row.name === 'no data') return ''

                let badge = ''
                if(row.priority === 1) badge = <Badge style={{width: 80}} color="danger">Highest</Badge>
                if(row.priority === 2) badge = <Badge style={{width: 80}} color="warning">High</Badge>
                if(row.priority === 3) badge = <Badge style={{width: 80}} color="info">Default</Badge>
                if(row.priority === 4) badge = <Badge style={{width: 80}} color="purple">Low</Badge>
                if(row.priority === 5) badge = <Badge style={{width: 80}} color="success">Lowest</Badge>

                return <div className="text-center">{badge}</div>
            }
        },
        {
            dataField: "name",
            text: 'Task',
            formatter: (cell, row) => {


                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                return (
                    <div>
                        <div>
                            <Link to={`/matters/${row.matter}`} className="text-muted font-weight-bold">
                                {row.missed_follow_up ? <i className="fas fa-exclamation-triangle text-danger mr-2" /> : ''}
                                <ObjectFinder collection="matters" _id={row.matter} />
                            </Link>
                        </div>

                        <div>
                            <Link to={`/matters/${row.matter}?tab=Items&docsTab=Outstanding`} className="text-dark font-weight-bold">
                                {row.name}
                            </Link>
                        </div>
                    </div>
                )
            },
        },
        {
            dataField: "last_comment",
            text: 'Comment',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.last_comment ? '' : (
                    <span>
                        {formatText(row.last_comment).stripHTML(100)}
                        {' - '}
                        <b className="text-info">{row.last_comment_by ? <ObjectFinder collection="users" _id={row.last_comment_by}/> : 'System'}</b>
                    </span>
                )
            )
        },
       
       
        {
            dataField: "due_date",
            text: sortType === 'due_date' ? 'Due Date' : 'Follow Up Date',
            headerStyle: { width: 100 },
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''

                const date = sortType === 'due_date' ? row.due_date : row.next_follow_up_at;
                if(!date) return '-';

                const color = date < now ? 'text-danger font-weight-bold' : ''

                return (
                    <div className={color}>
                        <div>{moment.unix(date).format('h:mm A')}</div>
                        <div>{moment.unix(date).format('MM/DD/YYYY')}</div>
                    </div>
                )
            }
        },

        {
            dataField: "assigned_to",
            text: 'Assigned To',
            headerStyle: { width: 150, textAlign: 'center' },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.assigned_to ? '' :
                <div className="text-center"><UserIcons users={row.assigned_to} /></div>
            )
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row, reload) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: EditItem,
                                    item: row,
                                    findTask: () => reload(),
                                    onItemChange: () => reload(),
                            
                                },
                                { 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/matters/${row.matter}?tab=Items&docsTab=Outstanding`,
                                },
                                
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = getUrlParameter('type') === 'overdue' ? 'Overdue Items' :  'All Items'
    const description = getUrlParameter('type') === 'overdue' ? 'All Items with a due date before the current time' : 'Showing all Items assigned to you.'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            leftColSize={3}

        />


        <Container fluid>
            <Card className="card-color card-primary table-fixed table-no-stripes">

                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Item Name Includes"
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    searchFilters={[
                        { 
                            size: 3,
                            type: 'textSearch', 
                            title: 'Item Name Includes' 
                        },
                        { 
                            size: 3,
                            type: 'select', 
                            property: 'sort_type',
                            title: 'Sort By',
                            options: [
                                { value: 'due_date', label: 'Due Date (Default)' },
                                { value: 'next_follow_up_at', label: 'Follow Up Date' },
                                { value: 'alphabetical', label: 'Alphabetical' },
                            ] 
                        },
                        { 
                            size: 3,
                            type: 'date', 
                            property: 'date_start',
                            title: 'Date Start',
                            // defaultValue: moment().endOf('day').subtract(30, 'days').toDate()
                        },
                        { 
                            size: 3,
                            type: 'date', 
                            property: 'date_end',
                            title: 'Date End',
                            // defaultValue: moment().endOf('day').toDate()
                        },
                        { 
                            size: 2,
                            type: 'select', 
                            property: 'priority',
                            title: 'Priority',
                            options: [
                                { value: 'all', label: 'All' },
                                { value: 1, label: 'Highest' },
                                { value: 2, label: 'High' },
                                { value: 3, label: 'Normal' },
                                { value: 4, label: 'Low' },
                                { value: 5, label: 'Lowest' },
                            ] 
                        },
                        { 
                            size: 2,
                            type: 'select', 
                            property: 'last_comment',
                            title: 'Has Comment',
                            options: [
                                { value: 'all', label: 'All' },
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ] 
                        },
                        
                        { 
                            size: 2,
                            type: 'collectionSearch', 
                            collection: 'outstanding_item_categories',
                            property: 'outstanding_item_categories',
                            title: 'Category',
                            filter: selected_division._id ? { division: selected_division._id } : {}
                        },
                    
                        { 
                            size: 2,
                            type: 'select', 
                            property: 'client_action_needed',
                            title: 'Client Action Needed',
                            options: [
                                { value: 'all', label: 'All' },
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ] 
                        },
                        { 
                            size: 2,
                            type: 'select', 
                            property: 'missed_follow_up',
                            title: 'Missed Follow Up',
                            options: [
                                { value: 'all', label: 'All' },
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ] 
                        },
                        { 
                            size: 2,
                            type: 'select', 
                            property: 'uncompleted_checklist',
                            title: 'Checklist',
                            options: [
                                { value: 'all', label: 'All' },
                                { value: true, label: 'Uncompleted' },
                                { value: false, label: 'Completed' },
                            ] 
                        },
                    ]}
                />    
            </Card>

        </Container>
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
