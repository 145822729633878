import { Card, CardBody, CardHeader, CardTitle, Row, Col, Badge } from 'reactstrap';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import ModalToggler from 'components/functional/modals/Toggler'
import ObjectFinder from 'components/system/Objects/Finder';

import AddFortisPay from './Add/FortisPay'
import Edit from './Edit'

import ConfirmationModal from 'components/functional/modals/Confirmation';


import TypeIcon from '../_components/TypeIcon'

import api from 'api'

const Payments = ({matter, payment_methods, fetchData}) => {

    const now = Math.floor(new Date() / 1000);

    const onDelete = useCallback(async (_id) => {
        const result = await api.payment_methods.delete(_id);
        if(!result.success) return toast.info(result.message[0])

        fetchData()
        toast.success(`Payment method successfully removed!`)

    }, [fetchData])

    return (
        <Card>
            <CardHeader className='py-3'>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className="mb-0">Payment Methods</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        <ModalToggler matter={matter} fetchData={fetchData} component={AddFortisPay}>
                            <button className='btn btn-success btn-sm'>Add Card / Bank</button>
                        </ModalToggler>
                    </Col>
                </Row>
            </CardHeader>

        

            {payment_methods.length ? (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Details</th>
                                <th>Type</th>
                                <th>Name On Card</th>
                                <th>Type</th>
                                <th>Estimated Success</th>
                                <th><i className="fas fa-check text-success font-weight-bold" /></th>
                                <th><i className="fas fa-times text-danger font-weight-bold" /></th>
                                <th className='text-right'>Added By</th>
                                <th className='text-right'></th>
                            </tr>
                        </thead>

                        <tbody>
                            {payment_methods.map(method => (
                                <tr key={method._id}>
                                    <td>
                                        <div><b style={{display: 'inline-block', width: 50}}>Last 4:</b>  <TypeIcon type={method.type} /> {method.last_4}</div>
                                        <div className={method.expires_unix < now ? 'text-danger' : ''}>
                                            {method.type !== 'ach' ? <span><b style={{display: 'inline-block', width: 50}}>Expires:</b> {method.expires}</span> : "" }
                                        </div>
                                    </td>
                                    <td>
                                        {method.type === 'cc' || method.type === 'dc' ? 'Card' : method.type === 'ach' ? 'ACH' : '-'}
                                    </td>
                                    <td>{method.owner_name}</td>
                                    <td>
                                        {method.primary_method ? (
                                            <Badge style={styles.badge} color="success">Default</Badge>
                                        ) : method.backup_method ? (
                                            <Badge style={styles.badge} color="info">Backup</Badge>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td>
                                        {method.next_payment_success_rate === 0 && !method.payments_succeeded && !method.payments_failed ? (
                                            '-'
                                        ) : (
                                            <span>{method.next_payment_success_rate.toFixed(2)}%</span>
                                        )}
                                        
                                    </td>
                                    <td>{method.payments_succeeded}</td>
                                    <td>{method.payments_failed}</td>
                                    <td className='text-right'>
                                        {method.user ? (
                                            <ObjectFinder collection="users" _id={method.user} />
                                        ) : method.contact ? (
                                            <ObjectFinder collection="contacts" _id={method.contact} />
                                        ) : '-'}
                                    </td>
                                    <td className='text-right'>

                                        <ModalToggler component={Edit} method={method} fetchData={fetchData}>
                                            <button className='btn btn-sm btn-info mr-3'><i className="fas fa-edit mr-2" /> Edit</button>
                                        </ModalToggler>

                                        <ModalToggler 
                                            component={ConfirmationModal} 
                                            onConfirmation={() => onDelete(method._id)}
                                            title="Delete Payment Method"
                                            body={(
                                                <div>
                                                    <table className='table border'>
                                                        <thead>
                                                            <tr>
                                                                <th>Owner</th>
                                                                <th>Type</th>
                                                                <th className='text-right'>Last 4</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr key={method._id}>
                                                                <td>
                                                                    <td>{method.owner_name}</td>
                                                                </td>
                                                                <td>{method.type === 'cc' || method.type === 'dc' ? 'Card' : method.type === 'ach' ? 'ACH' : '-'}</td>
                                                                <td className='text-right'><b style={{display: 'inline-block', width: 50}}></b> {method.last_4}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <p className='text-sm mb-0'>Are you sure you wish to delete the above payment method? This action cannot be undone.</p>
                                                </div>
                                            )}
                                        >
                                            <button className='btn btn-sm btn-danger'><i className="fas fa-trash" /></button>
                                        </ModalToggler>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            No Payment Methods Have Been Added To This Matter.
                        </p>
                    </CardBody>

                    
                </div>
            )}
            
        </Card>
    )

}

const styles = {
    badge: {
        width: 70
    }
}

export default Payments