import { memo } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import moment from 'moment';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import TableIcons from 'components/markup/icons/TableIcons';
import ObjectFinder from 'components/system/Objects/Finder';

import UserIcons from 'components/markup/icons/UserIcons'

const PipelineCard = ({matter, hideActions, hideLastNote, viewing_user}) => {

    const now = Math.floor(new Date() / 1000);
    const movedToday = matter.current_step_start > parseInt(moment().startOf('day').format('X'));

    const missedCall = matter.missed_call_at && matter.missed_call_at <= now;
    const deadline = matter.deadline_overdue_at && matter.deadline_overdue_at <= now;

    const day = movedToday ? moment.unix(matter.current_step_start).format('h:mm A') : moment.unix(matter.current_step_start).format('MMM Do, YYYY')

    const ago = moment.unix(matter.current_step_start).fromNow()

    return (
        <div className="pb-3">
            <Card className={`archk-pipeline-card mb-0 ${missedCall ? 'border-danger' : deadline ? 'border-info' : ''} `}>
                <CardHeader className="">
                    <Row>
                        <Col className="align-self-center">
                            <CardTitle className="mb--2 text-dark">
                                <Link to={`/matters/${matter.id}`}>{matter.name}</Link>
                            </CardTitle>
                            <p className="text-sm mb-0 text-muted"><span><i className="fas fa-clock " /> {day} - {ago}</span></p>
                            </Col>
                        <div className="col-auto align-self-center" style={{width: 35}}>
                            {matter.bookmarked_by && matter.bookmarked_by.includes(viewing_user._id) ? (
                                <Badge color="purple" className="text-sm"><i className="fas fa-bookmark " /></Badge>
                            ) : '' }
                        </div>
                    </Row>
                    
                </CardHeader>
                <CardBody className="py-2 bg-secondar">
                    {matter.contacts.map((u, i) => (
                        <p key={u + i} className={`text-sm text-dark mb-0 ${i !== 0 ? 'mt--2' : ''}`}>
                            <ObjectFinder collection="contacts" _id={u} />
                        </p>
                    ))}
                    {!hideLastNote ? (
                        <p className="text-sm mb-0 text-muted" style={{whiteSpace: 'pre-line'}}>{matter.last_note}</p>
                    ) : ''}

                </CardBody>

                {!hideActions ? (
                    <CardFooter className=" text-right py-2">
                        <Row>
                            <Col md={8} className='text-left'>
                                <UserIcons users={matter.roles.filter(r => r.user).map(r => r.user)} />
                            </Col>
                            <Col md={4}>
                                <TableIcons
                                icons={[
                                    { 
                                        icon: 'fas fa-edit',
                                        color: 'success', 
                                        wrapper: Link,
                                        to: `/matters/${matter._id}`,
                                        tooltip: 'View Case'
                                    },
                                ]}
                            />
                            </Col>
                        </Row>
                       
                    </CardFooter> 
                ) : ''}
               
            
                {missedCall ? (
                    <CardFooter className="bg-danger">
                        <p className="text-sm mb-0 text-white"><i className="fas fa-exclamation-triangle mr-2" /> MISSED CALL</p>
                    </CardFooter>
                ) : ''}
                
                {deadline ? (
                    <CardFooter className="bg-info">
                        <p className="text-sm mb-0 text-white"><i className="fas fa-calendar mr-2" /> Deadline</p>
                    </CardFooter>
                ) : ''}
    
            </Card>
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(memo(PipelineCard));

