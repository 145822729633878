import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import HeaderTabs from 'components/markup/layout/HeaderTabs';

import ItemList from 'components/system/OutstandingItems/ItemList'

import { getUrlParameter, setUrlParameter } from 'utils/urls';

const tabs = ['Outstanding', 'Completed']

const MatterViewMainTasks = () => {

    const [tab, setTab] = useState('')

    const onSetTab = useCallback((_tab) => {
        setTab(_tab);
        setUrlParameter('docsTab', _tab)
    }, [])

    useEffect(() => {
        const t = getUrlParameter('docsTab')
        if(tabs.includes(t)) {
            setTab(t)
        } else {
            setTab('Outstanding')
        }
    }, [])

    if(!tab) return <></>
    return (
        <div className="section pt-0" style={{minHeight: 250}}>

            <div className="section bg-white z-depth- border-top text-white pt-3 borer-top mt-0 pb-0 " style={{borderBottom: 'none'}}>
                <HeaderTabs 
                    className="mb-0"
                    tab={tab}
                    setTab={(tab) => onSetTab(tab)}
                    tabs={[
                        { value: 'Outstanding', name: <span className="text-whit"><i className="fas fa-times " /> Outstanding</span> },
                        { value: 'Completed', name: <span className="text-whit"><i className="fas fa-check pl-1 " /> Finished</span> },
                    ]}
                />
            </div>

            <ItemList 
                finished={tab === 'Outstanding' ? false : true}
            />

        </div>

    );

}


const mapStateToProps = state => {
	return {
        selected_matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainTasks);