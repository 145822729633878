import { openDocumentCenter } from 'store/functions/document_center';

import { useCallback, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { onDownloadDocument } from 'utils/documents';

import moment from 'moment';

import ImageRenderer from 'components/functional/images/ImageRenderer';
import TableIcons from 'components/markup/icons/TableIcons';

const reducer = (state, action) => {

    switch (action.type) {
        case "add":
            const s = JSON.parse(JSON.stringify(state))
            s.push(action.val)
            return s

      default:
        return state;
    }
};

const DocumentName = ({ row }) => {

    const [d, setD] = useState(row);

    return (
        <span>
            <ImageRenderer id={`archk-doc-list-id-${row._id}`} doc={d} onNameUpdated={(d) => setD(d)}>
                <div>
                    <div> 
                        <i className="fas fa-file mr-2 text-info" />
                        {d.name}
                    </div>
                    <div>{moment.unix(d.created_at).format('MMM Do, YYYY h:mm A')}</div>
                </div>
            </ImageRenderer>
        </span>
    )
}

const OutstandingItemsEditChecklist = ({id, selected_matter, foundMatter, documents}) => {
    const matterToUse = foundMatter && foundMatter._id ? foundMatter : selected_matter
    // const [docs, setDocs] = useState(documents)
    const [docs, setDocs] = useReducer(reducer, documents || []);


    const onUpload = useCallback((val) => {
        setDocs({ type: 'add', val })
    }, [])

    return (

        <div className='pb-5'>

            <h3>Attachments</h3>

            {!docs.length ? (
                <p className='text-sm mb-3'><i className="fas fa-info-circle text-info mr-2" /> No Attachments Have Been Uploaded</p>
            ) : (
                <div>
                    <table className='table border-bottom'>
                        <tbody>
                            {docs.map(d => (
                                <tr key={d._id}>
                                    <td><DocumentName row={d} /></td>
                                    <td>
                                        <div className="text-right">
                                            <TableIcons
                                                icons={[
                                                    { 
                                                        icon: 'fas fa-search',
                                                        color: 'warning', 
                                                        onClick: () => {
                                                            const el = document.getElementById(`archk-doc-list-id-${d._id}`)
                                                            if(el) el.click();
                                                        }
                                                    },
                                                    {  
                                                        icon: 'fas fa-download', 
                                                        color: 'info',
                                                        onClick: () => onDownloadDocument(d._id) 
                                                    },
                                                    // { 
                                                    //     icon: 'fas fa-trash',
                                                    //     color: 'danger',
                                                    //     wrapper: ModalToggler,
                                                    //     component: ConfirmationModal,
                                                    //     title: 'Delete Document',
                                                    //     onConfirmation: () => onDelete(d._id),
                                                    //     body: (
                                                    //         <span>
                                                    //             Are you sure you wish to delete the document:{' '}
                                                    //             <b className="text-danger text-underline">{d.name}</b><br /> 
                                                    //             This cannot be undone.
                                                    //         </span>
                                                    //     )
                                                    // },
                                                ]}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            )}
            
            <button 
                onClick={() => openDocumentCenter({matter: matterToUse._id, outstanding_item: id, onUpload, zIndex: 3000})}
                className='btn btn-outline-success btn-sm'
            >
                <i className="fas fa-plus mr-2" /> Upload Documents
            </button>

        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(OutstandingItemsEditChecklist);