import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

import ModalSendEmail from 'components/system/Email/Modal';

import { Modal } from "reactstrap";

const ModalShowText = ({showModal, toggleModal, subject, body}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Email Response</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body mt-4 text-center border-bottom bg-secondary">

            <div className='alert alert-info'>No associated matter found, email copied below:</div>

            <div className='border bg-white custom-shadow-1 py-4 px-3 mb-4'>
                <h3 className='mb-0'>Email Subject</h3>
                <p className='mb-0'>{subject}</p>
            </div>
          

            <iframe 
                title="email body"
                className='border bg-white custom-shadow-1 p-3'
                style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    height: 450,
                    overflow: 'auto'
                }}
                src={"data:text/html,"+encodeURIComponent(body)}
            />


        </div>

        <div className="modal-footer">
            <button className="btn btn-outline-warning" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
)

      
const OutstandingItemsEdit = ({ matter, division, item, call, onSent, type }) => {

    const [showModal, setShowModal] = useState(false)

    const getEmailResponse = useCallback(async () => {
        let result;
        toggleStandardLoader(true)
        if(type === 'item') {
            result = await api._ai.emails.item(item)
        } else if(type === 'items') {
            result = await api._ai.emails.items(matter)
        } else if(type === 'call') {
            result = await api._ai.emails.call(call)
        }
        toggleStandardLoader(false)

        if(!result.data || (result.data && !result.data.email)) return toast.info(`Could not generate email at this time.`)

        const { subject, body } = result.data.email
        setShowModal({ body, subject })

    }, [call, item, matter, type])

    return (

       <>

            <button onClick={getEmailResponse} className='btn btn-success'>
                <i className="fa-solid fa-wand-magic-sparkles mr-2" />
                Send Email
            </button>

            {showModal ? (
                matter && division ? (
                    <ModalSendEmail 
                        zIndex={1000000000}
                        showModal={showModal ? true : false}
                        toggleModal={() => setShowModal(false)}
                        matter={matter}
                        division={division}
                        emailTemplate={''}
                        defaultBody={showModal.body}
                        defaultSubject={showModal.subject}
                        onSent={() => {
                            if(onSent) onSent()
                        }}
                    />
                ) : (
                    <ModalShowText 
                        zIndex={1000000000}
                        showModal={showModal ? true : false}
                        toggleModal={() => setShowModal(false)}
                        body={showModal.body}
                        subject={showModal.subject}
                    />
                )
            ) : ''}

        </>

    )
}

export default OutstandingItemsEdit