import api from 'api';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Comment from './Comment';
import CommentItem from './CommentItem';

const UserComment = ({ collection_name, collection_id, parent_name, onCommentLeft, title, matter, zIndex }) => {

    const [comments, setComments] = useState(null);

    const onSaved = useCallback((data) => {
        if(!comments) return;
        const c = JSON.parse(JSON.stringify(comments))

        const index = c.findIndex(cc => cc._id === data._id);

        if(index === -1) {
            c.unshift(data)
            if(onCommentLeft) onCommentLeft(data)
        } else {
            if(data.deleted) {
                c.splice(index, 1)
            } else {
                c[index] = data;
            }
        }

        setComments(c)
    }, [comments, onCommentLeft])

    const fetchComments = useCallback(async () => {
        const result = await api.user_comments.find(collection_name, collection_id)
        setComments(result.data)
    }, [collection_id, collection_name])

    const markSeen = useCallback(async () => {
        api.user_comments.markSeen({ collection_name, collection_id })
    }, [collection_id, collection_name])

    useEffect(() => {
        fetchComments()
        markSeen()
    }, [fetchComments, markSeen])

    return (
        <div className='archk-user-comments'>
            {title ? title : <h3 className=''>New Comment</h3>}

            <Comment 
                matter={matter} 
                collection_name={collection_name} 
                collection_id={collection_id}
                parent_name={parent_name}
                onSaved={onSaved}
            />

            {comments && comments.length? (
                <div>
                    <hr />

                    <h3>Previous Comments</h3>
                    {comments.map(c => (
                        <CommentItem 
                            key={c._id}
                            comment={c}
                            collection_name={collection_name}
                            collection_id={collection_id}
                            parent_name={parent_name}
                            onSaved={onSaved}
                            zIndex={zIndex}
                        />
                    ))}
                </div>

            ) : ''}

        </div>
      
    );
};
  

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(UserComment);