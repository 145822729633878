import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Container } from "reactstrap";
import { Link } from 'react-router-dom'

import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import ItemCard from "../_components/ItemCard";
import NoItemsFound from "../_components/NoItemsFound";

const MattersAll = ({match}) => {
    const status = match.params.status

    const [data, setData] = useState(null)
  
    const [ err, setErr ] = useState(false)

    const fetchData = useCallback(async () => {

        const result = await api.outstanding_items.findByStatus(status)
        if(!result.success) return setErr(result.message)

            result.data.forEach(d => {
                d.date =status === 'overdue' ? d.due_date : d.next_follow_up_at;
                d.overdue = true
            })

        setData(result.data)
    }, [status])

    useEffect(() => {
        fetchData()        

        document.body.classList.add('bg-secondary')

        return () => {
            document.body.classList.remove('bg-secondary')
        }

    }, [fetchData])

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = <div>{status === 'due_date' ? 'Overdue Items' : 'Overdue Follow Ups'} <small style={{position: 'relative', top: -6}} className="rounded px-4  py-3 bg-warning text-white text-sm">BETA</small></div>
    const description = status === 'due_date' ? 'Showing Last 500 Unfinished Items' : 'Showing Last 500 Missed Follow Ups'

    return (

        <>

        <Helmet>
            <title>{status === 'due_date' ? 'Overdue Items' : 'Overdue Follow Ups'}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            leftColSize={9}
            actionComponent={(
                <Link className="btn btn-outline-info" to="/outstanding_items/daily"><i className="fas fa-arrow-left mr-2" /> Overview</Link>
            )}
        />

        <Container fluid>

            {!data ? <div className="py-6"><Circle /></div> : (
                <div>
                    
                    {status === 'due_date' ? (
                        <div className="alert mb-3 rounded alert-danger z-depth-1 text-uppercase">Overdue Items ({data.length})</div>
                    ) : (
                        <div className="alert mb-3 rounded alert-warning z-depth-1 text-uppercase">Overdue Follow Ups ({data.length})</div>
                    )}

                    <div className="card-columns">
                        {data.length ? data.map(d => (
                            <ItemCard 
                                key={d._id}
                                fetchData={fetchData}
                                item={d} 
                                timestamp="due_date"
                            />
                        )) : (
                            <NoItemsFound />
                        )}
                    </div>

                </div>
            )}
        </Container>


        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
