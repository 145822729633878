import { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import api from 'api';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import { getUrlParameter } from "utils/urls";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import Update from './Update';
import Overview from './Overview';
import Feed from './Feed';
import Divisions from './Divisions';

import LoadingTable from 'components/markup/loading/Table.js'


import CallContactBadge from 'components/system/CallContact/Badge'
import CallContact from 'components/system/CallContact'

import formatText from 'utils/formatText'
import { setSelectedContact } from 'store/functions/contacts'

const ContactsView = ({match, divisions}) => {

    const [ showClickToDial, setShowClickToDial ] = useState(getUrlParameter('dial' || false))
    const [ contact, setContact ] = useState({})
    const [ lastNote, setLastNote ] = useState(null)
    const [ matters, setMatters ] = useState(null)
    const [ loading, setLoading ] = useState({})
    const [ tab, setTab ] = useState('Overview')
    const [ err, setErr ] = useState(false)
    const [ errors, setErrors ] = useState(false)

    const setContactName = useCallback((contact) => {
        if(contact.given_name_unformatted) contact.given_name = contact.given_name_unformatted;
        if(contact.family_name_unformatted) contact.family_name = contact.given_name_unformatted;

        return contact;

    }, [])

    const fetchData = useCallback(async () => {
        const contact = await api.contacts.overview(match.params.contact_id)
        if(!contact.data) return setErr(true);

        const formattedContact = setContactName(contact.data.contact)
        setContact(formattedContact)
        setLastNote(contact.data.lastNote)
        setMatters(contact.data.matters)
        setSelectedContact(formattedContact)
        setLoading(false)
    }, [match.params.contact_id, setContactName])

    const onInputChange = useCallback(( field, value) => {
        const newContact = Object.assign({}, contact)
        newContact[field] = value;
        setContact(newContact);
    }, [contact])

    const onSave = useCallback(async () => {

        setErrors(false)
        //copy state
        let newState = Object.assign({}, contact);
        //assume we have no errors

        if(!newState.family_name && !newState.email && !newState.phone) {
            return setErrors([`A contact must have either a last name, email, or phone field.`])
        }

        setContact(newState)

        // toggle loaders and update/create the contacts
        toggleStandardLoader(true)
        let saved = await api.contacts.update(newState._id, newState, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success("Contact Updated Successfully")
            fetchData();
        } else {
            return setErrors(saved.message);
        }
    
    }, [contact, fetchData])

    const getDescription = useCallback(() => {
        if(!contact) return '-'

        return (
            <span>
                {contact.display_name ? contact.display_name : ''}
                {contact.display_name && (contact.phone || contact.email) ? ' - ' : ''}
                {contact.phone ? (
                    <CallContactBadge contact={contact._id} phone={contact.phone}>
                        <span className="cursor-pointer">{formatText(contact.phone).phone()}</span>
                    </CallContactBadge>
                ) : ''}
                {contact.email && ( contact.display_name || contact.phone ) ? ' - ' : ''}
                {contact.email ? contact.email : ''}
            </span>
        )

    }, [contact])

    // make sure to use the friendly url of a matter and not the mongo object id
    useEffect(() => {
        if(contact.id) {
            let url = window.location.href
            url = url.replace(contact._id, contact.id)
            window.history.pushState(null, null, `${url}`);
        }
    }, [contact.id, contact._id])

    useEffect(() => {
        api.page_histories.create(window.location.pathname);
        fetchData();

        const nav = parseInt(getUrlParameter('nav'));
        if(nav && !Number.isNaN(nav)) setTab(nav)

    }, [fetchData])

    useEffect(() => {
        return () => {
            setSelectedContact(null)
        }
    }, [])


    return (

        <>

            <HeaderNavigation 
                title="View Client"
                description={getDescription()}
                tab={tab}
                setTab={setTab}
                tabs={[
                    { value: 'Overview', name: 'Overview' },
                    { value: 'Feed', name: 'Feed' },
                    { value: 'Update', name: 'Update' },
                    { value: 'Divisions', name: 'Divisions' },
                    // { value: 'Settings', name: 'Settings' },
                ]}
                actionComponent={( 
                    <UncontrolledDropdown>
                        <DropdownToggle color="info">
                            Actions <i className="fas fa-caret-down ml-2 " />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href={`/matters/create`} target="_blank">Create New Matter</DropdownItem>

                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
            />


        <Container fluid>

            <Helmet>
                <title>View Contact</title>
                <meta name="description" content="Cases Create" />
            </Helmet>

           {err ? (
               <div className="alert alert-warning my-4 mb-0">
                   <i className="fas fa-exclamation-triangle mr-2 " />
                   The contact you are looking for cannot be found.
               </div>
           ) : loading ? (
               <LoadingTable rows={10} />
           )  : (

                <>

                {tab === 'Overview' ? (
                    <Overview 
                        lastNote={lastNote}
                        contact={contact}
                        matters={matters}
                        fetchData={fetchData}
                    />
                ) : tab === 'Update' ? (
                    <Update
                        match={match}
                        contact={contact}
                        onInputChange={onInputChange}
                        onSave={onSave}
                        errors={errors}
                        matters={matters}
                        onSaved={fetchData}
                    />
                ) : tab === 'Feed' ? (
                    <Feed
                        contact={contact}
                    />
                ) : tab === 'Divisions' ? (
                    <Divisions
                        onSaved={fetchData}
                        contact={contact}
                    />
                ) : null}

                </>

           )}

            {divisions && divisions.length ? (
                <CallContact 
                    showModal={showClickToDial}
                    toggleModal={() => setShowClickToDial(false)}
                    contact={contact._id}
            />
            ) : ''}
           
                
        </Container>

        </>

    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
        divisions: state.divisions,
	};
};

export default connect(mapStateToProps, '')(ContactsView);