import { formatCurrency } from 'utils/currency'
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

const ModalCustomFields = ({invoice}) => (

    <Card className='z-depth-'>
        <CardHeader className='bg-secondary'>
            <CardTitle className='mb-0 text-dark'>Invoice Overview</CardTitle>
        </CardHeader>

        <CardBody>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Name</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{invoice.name}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Fees</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total_fees)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Expenses</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total_expenses)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0 font-weight-bold text-dark'>Total</p></Col>
                <Col xs={7}>
                    <p className='text-sm mb-0 text-right font-weight-bold text-dark'>
                        {formatCurrency(invoice.total_fees + invoice.total_expenses)}
                    </p>
                </Col>
            </Row>
        </CardBody>
    
        <CardFooter>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Credits</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total_credits)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Write Offs</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total_write_offs)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Discounts</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total_discounts)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0 font-weight-bold text-dark'>Total</p></Col>
                <Col xs={7}>
                    <p className='text-sm mb-0 text-right font-weight-bold text-dark'>
                        {formatCurrency(invoice.total_credits + invoice.total_write_offs + invoice.total_discounts)}
                    </p>
                </Col>
            </Row>
        </CardFooter>
        
        <CardFooter>
            <Row>
                <Col xs={6}><p className='text-sm mb-0 font-weight-bold text-dark'>Invoice Total</p></Col>
                <Col xs={6}><p className='text-sm mb-0 text-right font-weight-bold text-dark'>{formatCurrency(invoice.total)}</p></Col>
            </Row>
        </CardFooter>

        <CardFooter className='bg-secondary'>
            <CardTitle className='mb-0 text-dark'>Paid Breakdown</CardTitle>
        </CardFooter>

        <CardFooter>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Fees</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.fees_paid)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Expenses</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.expenses_paid)}</p></Col>
            </Row>
            <hr className='my-2' />
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Total</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total)}</p></Col>
            </Row>
            <Row>
                <Col xs={5}><p className='text-sm mb-0'>Paid</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total_paid)}</p></Col>
            </Row>
            <Row className='text-dark font-weight-bold text-uppercase'>
                <Col xs={5}><p className='text-sm mb-0'>Balance</p></Col>
                <Col xs={7}><p className='text-sm mb-0 text-right'>{formatCurrency(invoice.total - invoice.total_paid)}</p></Col>
            </Row>
        </CardFooter>
            
    </Card>

)


export default ModalCustomFields