import { useCallback, useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';

import api from 'api';

import ApiError from 'components/markup/layout/ApiError'
import Circle from 'components/markup/loading/Circle'

import UnfinishedTaskTable from '../UnfinishedTaskTable'
import FalsifiedTaskTable from '../FalsifiedTaskTable'

import ConfirmationModal from 'components/functional/modals/Confirmation'
import ModalToggler from 'components/functional/modals/Toggler';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import { toast } from 'react-toastify'

const TasksMoveStepSelect = ({matter, selectedStep, moveStep, selected, onSetType}) => {

    const [err, setErr] = useState(null)
    const [data, setData] = useState(null)

    const [reasons, setReasons] = useState(null)

    const [reason, setReason] = useState('')
    const [notes, setNotes] = useState('')

    const tasksNeeded = data && ( data.moving_tasks.length || data.tasks.length ) ? true : false

    const checkNextStep = useCallback(async () => {
        const result = await api.matters.moveStep(matter._id, { workflow_step: selected, check: true });
        if(!result.success) return setErr(result.message)

        setData(result.data)
    }, [matter._id, selected])

    const fetchReasons = useCallback(async () => {
        const result = await api.matter_closing_reasons.find(matter.workflow)
        if(!result.success) return toast.info(`Could not load closing reasons, please refresh your page.`)

        setReasons(result.data)
    }, [matter.workflow])

    
    useEffect(() => {
        checkNextStep()

        if(selectedStep.set_as_closed) fetchReasons()
    }, [checkNextStep, fetchReasons, selectedStep.set_as_closed])

    return (
        <div className="ection">

            <Card className='card-no-shadow'>

                <CardHeader>
                    <CardTitle className='mb-0'>
                        {}
                    </CardTitle>
                </CardHeader>

                <CardBody className='pt-3'>
                    {err ? <ApiError err={err} /> : !data ? <Circle /> : (
                        <div>
                            {tasksNeeded ? (
                                <div>
                                    {data.tasks.length ? (
                                        <div>
                                            <h3>Tasks Required Before Moving Steps</h3>
                                            <UnfinishedTaskTable 
                                                err={err}
                                                tasks={data.tasks}
                                                findTasks={checkNextStep}
                                                isStepTaskView={false}
                                            />
                                        </div>
                                    ) : ''}

                                    {data.moving_tasks.length ? (
                                        <div>
                                            <h3>Tasks Required To Move To Step {selectedStep.name} </h3>

                                            <FalsifiedTaskTable 
                                                err={err}
                                                tasks={data.moving_tasks}
                                                findTasks={checkNextStep}
                                                isStepTaskView={false}
                                            />
                                        </div>
                                    ) : ''}
                                   
                                </div>
                            ) : (
                                <div>
                                    <p className='text-sm mb-0'><i className="fas fa-check mr-2 text-success" /> This matter is ready to move to the selected step.</p>

                                    {selectedStep.set_as_closed ? (
                                        <div>
                                            <hr />
                                            {reasons ? (
                                                <ReactSelect
                                                    title="Select the reason for closing this matter out."
                                                    formGroup={true}
                                                    onChange={(obj) => setReason(obj.value)}
                                                    options={reasons.map(r => { return { value: r._id, label: r.name }  })}
                                                    value={reason}
                                                />    
                                            ) : <Circle />}

                                            <FormGroup>
                                                <label className='form-control-label'>Closing Reason Notes</label>
                                                <Input 
                                                    type="textarea"
                                                    value={notes}
                                                    onChange={e => setNotes(e.target.value)}
                                                />
                                            </FormGroup>
                                        </div>
                                    ) : ''}

                                </div>

                            )}
                        </div>
                    )}
                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={() => onSetType('select')} className='btn btn-outline-warning'><i className="fas fa-arrow-left mr-2" /> Back</button>
                    {!tasksNeeded ? (
                        // <button onClick={() => onSetType('check')} className='btn btn-success'>Move Step</button>
                        <ModalToggler 
                            component={ConfirmationModal}
                            title="Move Step"
                            body={(
                                <span>Are you sure you wish to move the matter to the step: <b className='font-weight-bold text-dark text-underline'>{selectedStep.name}</b></span>
                            )}
                            onConfirmation={() => {
                                
                                moveStep(reason, notes)
                            }}
                        >
                        <button className='btn btn-success'>Move Step</button>
                    </ModalToggler>
                    ) : ''}
                </CardFooter>
              

            </Card>

        </div>
    );

}

export default TasksMoveStepSelect


// import { useCallback, useEffect, useState } from 'react';
// import { connect } from 'react-redux';

// import api from 'api';

// import UnfinishedTaskTable from '../UnfinishedTaskTable'

// const MyTasks = ({matter, viewing_user}) => {

//     const [err, setErr] = useState(null)
//     const [tasks, setTasks] = useState(null)

//     const findTasks = useCallback(async () => {
//         const tasks = await api.tasks.search({
//             skip: 0,
//             limit: 100,
//             searchString: '',
//             filter: {
//                 matter: matter._id,
//                 finished_on: 0,
//             }
//         })

//         if(!tasks.success) return setErr(tasks.message)
//         if(tasks.data) setTasks(tasks.data.documents)
//     }, [matter._id])

//     useEffect(() => {
//         findTasks()
//     }, [findTasks])

//     return (
//         <UnfinishedTaskTable 
//             err={err}
//             tasks={tasks}
//             findTasks={findTasks}
//             isStepTaskView={false}
//         />
//     )
  
// }


// const mapStateToProps = state => {
// 	return {
//         viewing_user: state.auth.viewing_user,
//         matter: state.matters.selected_matter,
// 	};
// };

// export default connect(mapStateToProps, '')(MyTasks);