import { useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'

import api from 'api';

import ModalToggler from 'components/functional/modals/Toggler'
import EventsModal from 'components/system/Event/Modal'

import UserIcons from 'components/markup/icons/UserIcons'
import { getUrlParameter } from "utils/urls";

const MattersAll = ({selected_division, viewing_user}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};
        params.filter.users = { $in: [viewing_user._id] }

        const startOfDay = parseInt(moment().startOf('day').format('X'))
        
        const type = getUrlParameter('type');
        if(type === 'today') params.filter.unix_start = { $gte: startOfDay, $lt: startOfDay + 86399 }
        if(type === '7') params.filter.unix_start = { $gte: startOfDay, $lt: startOfDay + (86400 * 7) }
        if(type === 'missed') {
            params.filter.unix_start = { $lt: now }
            params.filter.finished_by = null
        }

        if(selected_division._id) params.filter.division = selected_division._id

        const query = await api.events.search({ ...params, isCSV })

        
        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "given_name",
            text: 'Event',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div>
                            {row.matter ? (
                                <Link to={`/matters/${row.matter}?tab=Events`} className="text-dark font-weight-bold">{row.name}</Link>
                            ) : (
                                <span>
                                    {row.name} - No Matter Found
                                </span>
                            )}
                        </div>
                        <div>
                            {row.contacts.map((c, i) => (
                                <Link key={c._id} to={`/clients/${c}`}>
                                    {i !== 0 ? ', ' : ''}<ObjectFinder collection="contacts" _id={c} />
                                </Link>
                            ))}
                        </div>
                    </div>
                )
            },
        },
        {
            dataField: "users",
            text: 'Assigned To',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.users ? '' :
                <UserIcons users={row.users} />
            )
        },
        {
            dataField: "created_at",
            text: 'Date',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className={`${(row.unix_start < now) && !row.finished_by ? 'text-danger' : ''}`}>
                    <div> {moment.unix(row.unix_start).format('MM/DD/YYYY')}</div>
                    <div> {moment.unix(row.unix_start).format('h:mm')} - {moment.unix(row.unix_end).format('h:mm A')}</div>
                </div>
            )
        },
        {
            dataField: "finished_by",
            text: 'Finished By',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.contacts ? '' :
                row.finished_by ? <ObjectFinder collection="users" _id={row.finished_by} /> : ''

            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                ...(row.matter || row.contacts.length ? [{ 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: row.matter ? `/matters/${row.matter}?tab=Events` : `/contacts/${row.contacts[0]}`,
                                }] : []),

                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: EventsModal,
                                    onSave: () => {},
                                    _id: row._id
                                },

                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} />

    const title = getUrlParameter('type') === 'today' ? 'Events Today' : 
        getUrlParameter('type') === '7' ? 'Events Upcoming' : 
        getUrlParameter('type') === 'missed' ? 'Missed Events' : 
        'All My Events'
    const description = getUrlParameter('type') === 'today' ? 'Show Events Since The Start Of The Day' : 
        getUrlParameter('type') === '7' ? 'All Events From This Morning Until 7 Days From Now' : 
        getUrlParameter('type') === 'missed' ? 'Events Overdue That Have Not Been Completed Yet' : 
        `Showing All ${selected_division._id ? selected_division.name : ''} Events Assigned To Me`

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            actionComponent={(
                <div>
                    {/* <Link to="/matters/create" className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</Link> */}
                </div>
            )}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Event Name..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
