import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledTooltip, CardFooter } from 'reactstrap';
import { formatCurrency } from 'utils/currency';

import moment from 'moment';

import ModalToggler from 'components/functional/modals/Toggler';
import ObjectFinder from 'components/system/Objects/Finder';

import Refund from './Refund';
import View from './View';

import { onDownloadDocumentResponse } from 'utils/documents';

import TypeIcon from '../_components/TypeIcon'

const Payments = ({payment_methods, matter, payments, setTab, fetchData}) => {

    const [filter, setFilter] = useState('all')
    const [data, setData] = useState(payments)
    const [totals, setTotals] = useState({ all: 0, succeeded: 0, failed: 0, refunded: 0 })

    const onDownload = useCallback(async () => {
        toast.info('Download Started')
        onDownloadDocumentResponse(`/v1/payments/${matter._id}/download`)
    }, [matter._id])

    useEffect(() => {
        let arr = payments;

        if(filter === 'succeeded') {
            arr = payments.filter(p => p.status === 'succeeded')
        } else if(filter === 'failed') {
            arr = payments.filter(p => p.status === 'failed')
        } else if(filter === 'refunded') {
            arr = payments.filter(p => p.status === 'refunded')
        }

        let all = 0;
        let succeeded = 0;
        let failed = 0;
        let refunded = 0;

        payments.forEach(a => {

            if(a.status !== 'refunded' && (a.status !== 'failed' && !a.refund_for)) all += a.amount
            if(a.status === 'succeeded') succeeded += a.amount;
            if(a.status === 'failed' && !a.refund_for) failed += a.amount;
            if(a.status === 'refunded') {
                refunded += a.amount;

                // this is a voided ACH where it does not associate a refund
                // this is the only time a single payment does not have a match therefore
                // we consider it both as succeeded and refunded by adding the below amounts
                if(!a.refund_for) {
                    succeeded += a.amount
                    all += a.amount
                }
            }
        })

        succeeded = succeeded - refunded;

        setData(arr)
        setTotals({ all, succeeded, failed, refunded })

    }, [filter, payments])

    return (
        <>

        <Row>
            <Col xs={3}>
                <Card className='text-center custom-shadow-1 rounded'>
                    <CardHeader className='rounded bg-gradient-success text-white'>
                        <CardTitle className='mb-0'>Succeeded</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <h5 className='display-4'>{formatCurrency(totals.succeeded)}</h5>
                    </CardBody>

                    <CardFooter className='rounded'>
                        {totals.succeeded && totals.all ? ((totals.succeeded / totals.all) * 100).toFixed(2) : 0}%  
                    </CardFooter>
                </Card>
            </Col>
            <Col xs={3}>
                <Card className='text-center custom-shadow-1 rounded'>
                    <CardHeader className='rounded bg-gradient-danger text-white'>
                        <CardTitle className='mb-0'>Failed</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <h5 className='display-4'>{formatCurrency(totals.failed)}</h5>
                    </CardBody>

                    <CardFooter className='rounded'>
                        {totals.failed && totals.all ? ((totals.failed / totals.all) * 100).toFixed(2) : 0}%  
                    </CardFooter>
                </Card>
            </Col>
            <Col xs={3}>
                <Card className='text-center custom-shadow-1 rounded'>
                    <CardHeader className='rounded bg-gradient-warning text-white'>
                        <CardTitle className='mb-0'>Refunded</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <h5 className='display-4'>{formatCurrency(totals.refunded)}</h5>
                    </CardBody>

                    <CardFooter className='rounded'>
                        {totals.refunded && totals.all ? ((totals.refunded / totals.all) * 100).toFixed(2) : 0}%  
                    </CardFooter>
                </Card>
            </Col>
            <Col xs={3}>
                <Card className='text-center custom-shadow-1 rounded'>
                    <CardHeader className='rounded bg-gradient-purple text-white'>
                        <CardTitle className='mb-0'>All</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <h5 className='display-4'>{formatCurrency(totals.all)}</h5>
                    </CardBody>

                    <CardFooter className='rounded'>
                        Total Payments: {payments.length}
                    </CardFooter>
                </Card>
            </Col>

        </Row>

        <Card>
            <CardHeader className='roundedpy-3'>
                <Row>
                    <Col md={7} className="align-self-center">
                        <CardTitle className="mb-0">
                            <span className='pr-5'>Matter Payments</span>

                            <button 
                                id="archk-payment-all"
                                onClick={() => setFilter('all')} 
                                className={`cursor-pointer btn btn-sm ${filter === 'all' ? 'btn-success' : ''}`}
                            >
                                All
                            </button>
                             <UncontrolledTooltip delay={0} placement="bottom" target="archk-payment-all">
                                Show All Payments
                            </UncontrolledTooltip>

                            <button 
                                id="archk-payment-succeeded"
                                onClick={() => setFilter('succeeded')} 
                                className={`cursor-pointer btn btn-sm ${filter === 'succeeded' ? 'btn-success' : ''}`}
                            >
                                <i className="fas fa-check" />
                            </button>
                            <UncontrolledTooltip delay={0} placement="bottom" target="archk-payment-succeeded">
                                Only Succeeded
                            </UncontrolledTooltip>

                            <button 
                                id="archk-payment-failed"
                                onClick={() => setFilter('failed')} 
                                className={`cursor-pointer btn btn-sm ${filter === 'failed' ? 'btn-success' : ''}`}
                            >
                                <i className="fas fa-times" />
                            </button>
                            <UncontrolledTooltip delay={0} placement="bottom" target="archk-payment-failed">
                                Only Failed
                            </UncontrolledTooltip>

                            <button 
                                id="archk-payment-refunded"
                                onClick={() => setFilter('refunded')} 
                                className={`cursor-pointer btn btn-sm ${filter === 'refunded' ? 'btn-success' : ''}`}
                            >
                                <i className="fas fa-undo" />
                            </button>
                            <UncontrolledTooltip delay={0} placement="bottom" target="archk-payment-refunded">
                                Only Refunded
                            </UncontrolledTooltip>

                        </CardTitle>
                    </Col>
                    <Col md={5} className="align-self-center text-right">
                        {data.length ? (
                            <button onClick={onDownload} className='btn btn-outline-info btn-sm'>
                                <i className="fas fa-download mr-2" /> Download
                            </button>
                        ) : ''}
                        <button onClick={() => setTab('charge')} className='btn btn-success btn-sm'>
                            <i className="fas fa-dollar-sign mr-2" /> Charge Payment
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {data.length ? (
                <div>

                   

                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th className='text-right'>Amount</th>
                                    <th className='text-right text-center'>Status</th>
                                    <th className='text-right'>Made By</th>
                                    <th className='text-right'>Card/Bank</th>
                                    <th className='text-center'>Trust</th>
                                    <th className='text-right'>Type</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map(p => {

                                    const method = payment_methods.find(method => method._id === p.payment_method) 

                                    let madeBy = ''
                                    if(method) madeBy = <span><TypeIcon type={method.type} /> xx{method.last_4}</span>

                                    return (
                                        <tr key={p._id} >
                                            <td>
                                                <span className='cursor-pointer'>
                                                    <ModalToggler component={View} payment={p}>
                                                        <i className="fas fa-list mr-2 text-success" />
                                                        {moment.unix(p.created_at).format('MM/DD/YYYY')}
                                                    </ModalToggler>

                                                    {p.description ? <div>{p.description}</div> : ''}
                                                
                                                </span>
                                            </td>

                                            <td className='text-right'>
                                                {formatCurrency(p.amount)}
                                                <div>
                                                    {p.refunded_amount ? (
                                                        <b className='text-danger font-weight-bold'> <i className="fas fa-undo mr-2" />{formatCurrency(p.refunded_amount)}</b>
                                                    ) : ''}
                                                </div>
                                            </td>

                                            <td className='text-center text-capitalize'>
                                                {p.status === 'succeeded' ? (
                                                    <Badge style={styles.badge} color="gradient-success">{p.status}</Badge>
                                                ) : p.status === 'failed' ? (
                                                    <Badge style={styles.badge} color="gradient-danger">{p.refund_for ? 'REFUND' : p.status}</Badge>
                                                ) : p.status === 'refunded' || p.status === 'voided'  ? (
                                                    <Badge style={styles.badge} color="gradient-warning">Refunded</Badge>
                                                )  : p.status === 'chargeback'  ? (
                                                    <Badge style={styles.badge} color="gradient-danger"> 
                                                        <i className="fas fa-exclamation-triangle mr-1" />
                                                        Chargeback
                                                    </Badge>
                                                ) : (
                                                    <Badge style={styles.badge} color="gradient-info">{p.status}</Badge>
                                                )}
                                            </td>

                                            <td className='text-right'>
        
                                                {p.user ? (
                                                    <ObjectFinder collection="users" _id={p.user} />
                                                ) : p.contact ? (
                                                    <ObjectFinder collection="contacts" _id={p.contact} />
                                                ) : 'System'}
                                            </td>

                                            <td className='text-right'>
                                                {madeBy}
                                            </td>

                                            <td className='text-center'>
                                                {p.trust ? (
                                                    <i className="fas lead mb-0 mt-0 fa-check text-success font-weight-bold" /> 
                                                ) : (
                                                    <i className="fas lead mb-0 mt-0 fa-times text-danger font-weight-bold" /> 
                                                )}
                                            </td>

                                            <td className='text-right'>
                                                {p.type === 'recurring' ? 'Recurring' : p.type === 'cash' ? 'Cash' : 'One Time'}
                                            </td>

                                            <td className='text-right'>
                                                {p.status === 'succeeded' ? (
                                                    <ModalToggler component={Refund} payment={p} onRefund={fetchData}>
                                                        <button className={`btn btn-${p.refunded_amount === p.amount ? 'outline-warning' : 'warning'} btn-sm`}>
                                                            <i className="fa-solid fa-rotate-left mr-2" />
                                                            REFUND
                                                        </button>
                                                    </ModalToggler>
                                                
                                                ) : ''}
                                            </td>
        
                                        </tr>
                                    )
                                })}
                            
                            </tbody>
                        </table>
                    </div>

                   
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center text-capitalize">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            No Payments have been charged to this this matter or were found with the current filter.
                        </p>
                    </CardBody>
                    
                </div>
            )}


        </Card>

        </>
    )

}

const styles = {
    badge: {
        width: 110
    }
}

export default Payments