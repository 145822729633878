import { useCallback, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup, CardFooter, Input } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
                            import DatePicker from "react-datepicker";

import { formatCurrency } from 'utils/currency';
import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'
import moment from 'moment';

const Payments = ({matter, payment_methods, payment_subscription, onSetTab, fetchData}) => {

    const [errs, setErrs] = useState(false)
    const [cash, setCash] = useState({
        amount: '',
        type: 'cash',
        date: new Date(),
        description: ''
    })
    const [method, setMethod] = useState({
        amount: payment_subscription ? formatCurrency(payment_subscription.amount).replace('$', '') : '',
        method: payment_methods.length ? payment_methods[0]._id : ''
    })

    const onSetCash = useCallback((field, value) => {
        const _cash = JSON.parse(JSON.stringify(cash))
        _cash[field] = value;
        setCash(_cash)
    }, [cash])
   
    const onSetMethod = useCallback((field, value) => {
        const _method = JSON.parse(JSON.stringify(method))
        _method[field] = value;
        setMethod(_method)
    }, [method])

    const onChargeMethod = useCallback(async () => {
        setErrs([])
        let amount = method.amount ? parseFloat(method.amount) : 0;
        amount = parseFloat(amount.toFixed(2))

        if(amount <= 0 || amount > matter.billing_balance) return toast.info('Amount to charge must be greater than 0 and less than the balance left on the matter.')

        toggleStandardLoader(true)
        const charged = await api.payments.charge({
            matter            : matter._id,
            payment_method    : method.method,    
            amount            : parseFloat(amount),
            type              : 'one_time',
        })
        toggleStandardLoader(false);

        if(charged.message && charged.message.length) return setErrs(charged.message);
        if(charged.data.amount_failed) {
            let _errs = []
            charged.data.payments.forEach(p => {
                if(p.payment_status_message) {
                    let message = p.payment_status_message ? p.payment_status_message.toLowerCase() : '';
                    if(p.payment_status_reason) message += ' - ' + p.payment_status_reason.toLowerCase();
                    _errs.push(
                        <span>
                            A {p.trust ? 'trust' : 'standard'} payment failed in the amount of {formatCurrency(p.amount)}. Message from processor: <br />
                            "<span className='text-underline text-capitalize font-weight-bold'>{message}</span>"
                        </span>
                    )
                } else {
                    _errs.push(`A ${p.trust ? 'trust' : 'standard'} payment went through in the amount of ${formatCurrency(p.amount)}`)
                }
            })
            toast.error('Payment Failed, Check Message Response Below')
            return setErrs(_errs)
        }

        onSetTab('')
        toast.success('Payment Charged Successfully')
        return fetchData()


    }, [method, matter.billing_balance, matter._id, fetchData, onSetTab])
  
    const onChargeCash = useCallback(async () => {
        let amount = cash.amount ? parseFloat(cash.amount) : 0;
        amount = parseFloat(amount.toFixed(2))

        if(amount <= 0 || amount > matter.billing_balance) return toast.info('Amount to charge must be greater than 0 and less than the balance left on the matter.')


        if(!cash.date) return toast.info('A date must be specified to charge a cash payment')
        if(!cash.description) return toast.info('A description must be specified to charge a cash payment')

        toggleStandardLoader(true)
        const charged = await api.payments.charge({
            matter        : matter._id,
            amount        : parseFloat(amount),
            type          : 'cash',
            date          : parseInt(moment(cash.date).format('X')),
            description   : cash.description,
        })
        toggleStandardLoader(false);

        if(charged.message && charged.message.length) return setErrs(charged.message);

        onSetTab('Payments')
        toast.success('Payment Charged Successfully')
        return fetchData()

    }, [cash, matter.billing_balance, matter._id, fetchData, onSetTab])

    return (
        <Row>
            <Col md={6}>

                {payment_methods.length ? (
                    <Card>
                        <CardHeader className='py-3'>
                            <CardTitle className="mb-0">Charge A Card Or Bank Account</CardTitle>
                        </CardHeader>


                        <CardBody>

                            <FormGroup>
                                <label className='form-control-label'>Charge Amount</label>
                                <Input 
                                    type="number"
                                    onChange={(e) => onSetMethod('amount', e.target.value)}
                                    value={method.amount}
                                    />
                                <p className='text-sm text-right'><span className='text-muted'>Current Balance:</span> {formatCurrency(matter.billing_balance)}</p>
                            </FormGroup>

                            <ReactSelect
                                title={"Payment Method"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => onSetMethod('method', obj.value)}
                                options={payment_methods.map((method) => {
                                    let icon = method.type === 'cc' ? <i className="fa-solid fa-money-check text-success mr-2" /> : <i className=" text-warning fa-solid fa-building-columns mr-2" />

                                    let innerText = ' ' + method.last_4 + ' - ' + method.owner_name
                                    if(method.primary_method) innerText += ' - (Primary)'

                                    const label = <span>{icon} {innerText}</span>

                                    return { value: method._id, label }
                                })}
                                value={method.method}
                            />    
                        </CardBody>

                        {errs.length ? (
                             <CardFooter className="">
                                {errs.map((e, i) => (
                                    <p key={i} className='text-sm mb-0 text-warning font-weight-bold'>{e}</p>
                                ))}
                            </CardFooter>
                        ) : ''}

                        <CardFooter className="text-right">
                            <button 
                                onClick={onChargeMethod} 
                                className='btn btn-success btn-sm'
                            >
                                Charge A Payment
                            </button>
                        </CardFooter>

                    </Card>
                ) : (
                    <Card>
                        <CardBody>
                            <p className='text-center text-sm mb-0'><i className="fas fa-info-circle mr-2 text-info" /> There are no payment methods on file for this matter so a card/ach payment cannot be charged.</p>
                        </CardBody>
                    </Card>
                )}
               
            </Col>
            <Col md={6}>
                <Card>
                    <CardHeader className='py-3'>
                        <CardTitle className="mb-0">Record A Cash Payment</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <FormGroup>
                            <label className='form-control-label'>Charge Amount</label>
                            <Input 
                                type="number"
                                onChange={(e) => onSetCash('amount', e.target.value)}
                                value={cash.amount}
                            />
                        </FormGroup>

                        <ReactSelect
                            title={"Payment Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetCash('type', obj.value)}
                            options={[
                                { label: 'Cash', value: 'cash' },
                                { label: 'Check', value: 'check' },
                                { label: 'Money Order', value: 'money order' },
                            ]}
                            value={cash.type}
                        />    
                        <FormGroup>
                            <label className='form-control-label'>Payment Date</label>

                            <DatePicker selected={moment(cash.date).toDate()} onChange={(date) => onSetCash('date', date)} />
                        </FormGroup>

                        <FormGroup>
                            <label className='form-control-label'>
                                {cash.type === 'cash' ? 'Payment Description' : cash.type === 'check' ? 'Check Number' : 'Money Order Reference'}
                            </label>
                            <Input 
                                type="text"
                                onChange={(e) => onSetCash('description', e.target.value)}
                                value={cash.description}
                            />
                        </FormGroup>
                    </CardBody>

                    <CardFooter className="text-right">
                        <button 
                            onClick={onChargeCash} 
                            className='btn btn-success btn-sm'
                        >
                            Charge A Payment
                        </button>
                    </CardFooter>

                
                </Card>
            </Col>
        </Row>
    )

}

export default Payments