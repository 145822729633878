
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Modal } from "reactstrap";

import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

import moment from 'moment';

import Circle from 'components/markup/loading/Circle';

const production = window.location.href.includes('intuidesk') && !window.location.href.includes('staging');

const AddFortisPay = ({showModal, toggleModal, matter, fetchData, COLORS}) => {
    const [loaded, setLoaded] = useState(false)

    const loadScript = useCallback(() => new Promise (async resolve => {
        if(window.Commerce) return resolve(false);

        const script = document.createElement('script');
        script.src = production ? 'https://js.fortis.tech/commercejs-v1.0.0.min.js' : 'https://js.sandbox.fortis.tech/commercejs-v1.0.0.min.js';

        script.onload = () => {
            resolve(true)
        };
    
        script.onerror = () => {
            toast.info(`Could not load payment methods, code Fortis Script 1`)
        };
    
        document.body.appendChild(script);
    

    }), [])

    const fetchHosted = useCallback(async () => {
        setLoaded(false)
        const initialLoad = await loadScript()

        const token = await api.payment_methods.hosted(matter._id, 'fortis_pay')
        if(!token.data) return toast.error(`Could not load payment methods, code Fortis Error 2`)

        const elements = new window.Commerce.elements(token.data);
        
        elements.create({
            environment: production ? 'production' : 'sandbox',
            container: '#payment',
            theme: 'default',
            digitalWallets: [],
            // digitalWallets: ['ApplePay', 'GooglePay'],

            fields: {
                    billing: [
                    { name: 'address', required: true, value: '' },
                    { name: 'city', required: true },
                    { name: 'postal_code', required: true }
                ]
            },
            appearance: {
                colorButtonSelectedBackground: COLORS.teal,
                colorButtonSelectedText: '#ffffff',
                colorButtonActionBackground: COLORS.green,
                colorButtonActionText: '#ffffff',
                colorButtonBackground: '#ffffff',
                colorButtonText: '#363636',
                colorFieldBackground: '#ffffff',
                colorFieldBorder: '#dbdbdb',
                colorText: '#4a4a4a',
                colorLink: '#485fc7',
                fontSize: '13px',
                marginSpacing: '.2rem',
                borderRadius: '0.4375rem',
                trimWhitespace: 'true',
                rowMarginSpacing: '0.2rem',
            }
        });

        elements.on('ready', function(event){
            setLoaded(true)
        });

        elements.on('tokenExpired', function(event){
            toggleModal()
        });

        // prevent these from firing multiple times
        // ready needs to fire each time elements loads
        if(initialLoad) {
            elements.on('done', async (result) => {
    
                if(result.data && result.data.payment_method) {
                    const expires = result.data.payment_method === 'ach' ? '12/2200' :  moment().add(result.data.expiring_in_months, 'months').format('MM/YY')
    
                    const payload = {
                        matter              : matter._id,        
                        payment_processor   : 'fortis_pay',
                        type                : result.data.payment_method,
                        
                        token               : result.data.id,
                        owner_name          : result.data.account_holder_name,
                        last_4              : result.data.last_four,
                        zip                 : result.data.billing_zip,
                        expires             : expires,
                    }
    
                    toggleStandardLoader(true)
                    const saveMethod = await api.payment_methods.create(payload)
                    toggleStandardLoader(false)
    
                    if(!saveMethod.success) return toast.error('Could not save payment method, internal error.')
    
                    toggleModal()
                    fetchData()
                } else {
                    toast.error(`Could not save payment method, please check card or ACH details`)
                }
                
            });
        }
        

    }, [COLORS.green, COLORS.teal, fetchData, matter._id, toggleModal, loadScript])

    useEffect(() => {
        if(showModal) fetchHosted()
    }, [fetchHosted, showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Payment Method</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body py-3">

                <div className='border px-4 border bg-secondary'>
                    <div id="payment" style={{display: loaded ? 'block' : 'none'}} />

                    {!loaded ? <div className='py-6'><Circle /></div> : ''}
                </div>

            </div>

            
            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left " /> Back
                </button>

            </div>

        </Modal>

    )

}


const mapStateToProps = state => {
    return {
        COLORS: state.config.COLORS,
    };
};

export default connect(mapStateToProps, '')(AddFortisPay);