import { Modal } from "reactstrap";

import SendEmail from './index';

const ModalSendEmail = ({showModal, toggleModal, matter, thread, task, division, emailTemplate, onSent, defaultSubject, defaultBody, contact}) => {

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Send Email</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <SendEmail 
                    thread={thread}
                    matter={matter}
                    division={division}
                    contact={contact}
                    task={task}
                    emailTemplate={emailTemplate}
                    onSent={onSent}
                    defaultBody={defaultBody}
                    defaultSubject={defaultSubject}
                    toggleModal={toggleModal}
                />
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

export default ModalSendEmail