import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import StandardLoader from 'components/markup/layout/StandardLoader';

import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment';

import ModalToggler from 'components/functional/modals/Toggler';
import EventsEdit from 'components/system/Event';
import { useEffect } from 'react';

import EventsModal from 'components/system/Event/Modal'
import UserIcons from 'components/markup/icons/UserIcons'

import reactHTMLParser from 'html-react-parser';

const MattersViewMainTasks = ({matter, events, fetchMatterEvents}) => {

    const finished = events ? events.filter(t => t.finished_by) : []
    const unfinished = events ? events.filter(t => !t.finished_by) : []

    useEffect(() => {
        if(!events) fetchMatterEvents()
    }, [events, fetchMatterEvents])

    if(!events) return (
        <div style={{minHeight: 250}}>
            <StandardLoader />
        </div>
    )

    return (
        <div className="section pt-0">
            <Card className='card-no-shadow'>

                <CardHeader className="py-3">
                    <Row>
                        <Col md={6} className="align-self-center">
                            <CardTitle className="mb-0">Pending Events</CardTitle>
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            <ModalToggler 
                                component={EventsEdit}
                                matter={matter._id}
                                closeOnSave={true}
                                onSave={fetchMatterEvents}
                            >
                                <button className="btn btn-success btn-sm"><i className="fas fa-plus mr-3" /> Add Event</button>
                            </ModalToggler>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive ">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>Time</th>
                                <th>Contacts</th>
                                <th>Users</th>
                                <th>Location</th>
                                <th className="text-right"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {unfinished.length ? (
                                unfinished.map(event => (
                                    <tr key={event._id}>
                                        <td>
                                            <div>{event.name}</div>
                                            {event.description ? (
                                                <div className="text-dark html-p-small border roundd bg-white py-2 px-3 d-inline-block">
                                                    {reactHTMLParser(event.description)}
                                                </div>
                                            ) : null}
                                        </td>
                                        <td>
                                            <div>
                                                {moment(event.start).format("MMM Do, YYYY")}
                                                <div>{moment(event.start).format("h:mm ")} - {moment(event.end).format("h:mm A")}</div>
                                            </div>
                                        </td>

                                        <td >
                                            {event.contacts ? event.contacts.map((contact, i) => (
                                                <div><ObjectFinder collection="contacts" _id={contact} /></div>
                                            )) : '-'}
                                        </td>
                                        <td >
                                            {event.users ? <UserIcons users={event.users} /> : '-'}
                                        </td>
                                        <td >
                                            {event.location && <ObjectFinder collection="locations" _id={event.location} />}
                                        </td>

                                        <td className="text-right" >
                                            <ModalToggler 
                                                component={EventsModal} 
                                                onSave={fetchMatterEvents}
                                                _id={event._id}
                                            >
                                                <button className="btn btn-info btn-sm">Update Event</button>
                                            </ModalToggler>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Events Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <p className="mb-0 text-sm"><small>Showing {finished.length} Results</small></p>
                </CardFooter>
                
            </Card>

            <Card className='card-no-shadow'>

                <CardHeader>
                    <CardTitle className="mb-0">Finished Events</CardTitle>
                </CardHeader>

                <div className="table-responsive ">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>Finished At</th>
                                <th className="text-center">Finished By</th>
                                <th>Contacts</th>
                                <th>Location</th>
                            </tr>
                        </thead>

                        <tbody>
                            {finished.length ? (
                                finished.map(event => (
                                    <tr key={event._id}>
                                        <td>
                                            <div>{event.name}</div>
                                            {event.description ? (
                                                <div className="text-dark html-p-small border roundd bg-white py-2 px-3 d-inline-block">
                                                    {reactHTMLParser(event.description)}
                                                </div>
                                            ) : null}
                                        </td>
                                        <td>
                                            <div>
                                                {moment.unix(event.finished_at).format("MMM Do, YYYY")}
                                                <div>{moment.unix(event.finished_at).format("h:mm ")}</div>
                                            </div>
                                        </td>
                                        <td >
                                            <div className="text-center"><UserIcons users={event.finished_by ? [event.finished_by] : []} /></div>
                                        </td>
                                        <td >
                                            {event.contacts ? event.contacts.map((contact, i) => (
                                                <div><ObjectFinder collection="contacts" _id={contact} /></div>
                                            )) : '-'}
                                        </td>
                                       
                                        <td >
                                            {event.location && <ObjectFinder collection="location" _id={event.location} />}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Events Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <p className="mb-0 text-sm"><small>Showing {unfinished.length} Results</small></p>
                </CardFooter>
                
            </Card>

        </div>
    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainTasks);