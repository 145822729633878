
import * as actionTypes from '../../actions';
import store from '../../index';

export const openDocumentCenter = async({matter, task, matter_document, matter_document_upload, onUpload, outstanding_item, zIndex, url, resizePixels}) => {
    if(!matter) {
        if(!url || !url.includes('company')) return console.warn('A Matter must be passed to openDocumentCenter')
    }

    store.dispatch({ type: actionTypes.SET_DOCUMENT_CENTER, payload: { 
        matter, 
        resizePixels, 
        url: url ? url : '/v1/documents', 
        task, 
        matter_document, 
        matter_document_upload,
        open: true,
        onUpload,
        outstanding_item,
        zIndex
    } });
}

export const closeDocumentCenter = async(newDocs) => {

    store.dispatch({ type: actionTypes.SET_DOCUMENT_CENTER, payload: { 
        resizePixels: 1200,
        url: '/v1/documents',
        matter: null,
        task: null,
        matter_document: null,
        open: false ,
        onUpload: null,
        outstanding_item: null,
        zIndex: 0
    } });
}

