import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import AvatarImage from "components/functional/images/AvatarImage";
import Circle from 'components/markup/loading/Circle';

import RoleUpdate from './Update';

const MattersViewSidebarLeftRoles = ({fetchWorkflowRoles, workflowRoles, matter}) => {

    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        if(!workflowRoles) fetchWorkflowRoles()
    }, [workflowRoles, fetchWorkflowRoles])

    if(!workflowRoles) return <Circle />

    return (

        <div>
            <div className="section mb-0 py-1 border-none bg-secondary ">
                <h4 className="mb-0 ">
                    <span className="text-uppercas">Roles</span>
                     
                    <span className="float-right cursor-pointer" onClick={() => setShowAll(!showAll)} >
                        {showAll ? (
                            <span><i className="fas fa-square-minus mr-2 text-warning " /> Less</span>
                        ) : (
                            <span><i className="fas fa-square-plus mr-2 text-info" /> More</span>
                        )}
                    </span>
                </h4>
            </div>

            <div className="section border-top mb-0 border-bottom">
                {workflowRoles.map(role => {
                    const found = matter.roles.find(r => {
                        if(r.workflow_role && r.workflow_role._id === role._id) return true
                        return false;
                    })

                    if(!found && !showAll) return <span key={role._id}></span>
                    if(found && !found.user && !showAll) return <span key={role._id}></span>

                    return (
                        <ModalToggler key={role._id} component={RoleUpdate} role={role} currentUser={found && found.user ? found.user : null}>
                            <Row className="mt-2 cursor-pointer " >
                                <div className="col-auto align-self-center pr-1 " style={{width: 40}}>
                                    <AvatarImage src={found && found.user ? found.user.avatar : 'test'} />
                                </div>
                                <Col className="align-self-center ">
                                    <h4 className=" text-uppercas mb--2 ">
                                        {found && found.user ? found.user.display_name : <span className="text-warning mb-0">UNASSIGNED</span>}
                                    </h4>
                                    <div><span>{role.name}</span></div>
                                </Col>
                            </Row>
                        </ModalToggler> 
                    )
                })}
            </div>
        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewSidebarLeftRoles);

