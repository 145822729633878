import { useEffect, useState } from 'react';
import { FormGroup, Input, Modal } from "reactstrap";

const ModalAIPrompt = ({showModal, toggleModal, onGenerate}) => {

    const [prompt, setPrompt] = useState('')

    useEffect(() => {
        if(showModal) setPrompt('')
    }, [showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="sm"
            zIndex={1000000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Regenerate Prompt</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>Enter prompt to regenerate email:</label>
                    <Input 
                        type="text"
                        value={prompt}
                        onChange={e => setPrompt(e.target.value)}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={() => onGenerate(prompt)}>
                    Regenerate
                </button>
            </div>

        </Modal>

    )
}

export default ModalAIPrompt