import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';

import api from 'api';

import { toast } from 'react-toastify';

import hasPrivilege from 'utils/hasPrivilege'
import ConfirmationModal from 'components/functional/modals/Confirmation';

import ModalToggler from 'components/functional/modals/Toggler'
import { toggleStandardLoader } from 'store/functions/system/system';


const MatterViewMainDocuments = ({matter}) => {

    const [identifier, setIdentifier] = useState(matter.identifier || '')
    const [name, setName] = useState(matter.name)
    const [redirect, setRedirect] = useState(null)

    const onSave = useCallback(async () => {
        const result = await api.matters.update(matter._id, { identifier, name })
        if(!result.success) return toast.error(`Something went wrong saving your changes, please try again.`)
        toast.success(`Matter Identifiers Updated Successfully`)
    }, [matter._id, name, identifier])

    const onDelete = useCallback(async () => {
        toggleStandardLoader(true)
        const result = await api.matters.delete(matter._id)
        toggleStandardLoader(false)

        if(!result.success) return toast.error('Could not delete matter. Please try again.')

        toast.success('Matter deleted successfully')
        return setRedirect('/matters')
    }, [matter._id])

    if(redirect) return <Redirect to={redirect} />
  
    return (
        <div className="section pt-0" >
            <Card>

                <CardHeader>
                    <CardTitle className='mb-0'>Matter Name</CardTitle>
                </CardHeader>

                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>This will update the search value as well as the display name of the matter</label>

                        <Input 
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormGroup>
                </CardBody>
               
                <CardHeader>
                    <CardTitle className='mb-0'>Matter Identifier</CardTitle>
                </CardHeader>

                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>A matter identifier is used across the system to reference this matter by an ID outside of IntuiDesk</label>

                        <Input 
                            value={identifier}
                            onChange={e => setIdentifier(e.target.value)}
                        />
                    </FormGroup>
                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={onSave} className='btn btn-success'>Save</button>
                </CardFooter>

            </Card>

            {hasPrivilege('MATTERS.DELETE') ? (
                <Card>
                    <CardHeader className='bg-danger'>
                        <CardTitle className='mb-0 text-white'>DANGER ZONE</CardTitle>
                       
                    </CardHeader>

                    <CardBody>
                        <p className='text-sm mb-0'>Delete Matter - This will remove this matter from showing up in search results and analytics. Proceed with caution.</p>
                    </CardBody>

                    <CardFooter className='text-right'>

                        <ModalToggler
                            component={ConfirmationModal}
                            title="Delete Matter"
                            body="Are you sure you wish to delete this matter? This cannot be undone."
                            onConfirmation={onDelete}
                        >
                            <button className='btn btn-outline-danger'>
                                <i className="fas fa-exclamation-triangle" /> DELETE
                            </button>
                        </ModalToggler>

                    </CardFooter>
                </Card>
            ) : ''}

            
        </div>

    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocuments);