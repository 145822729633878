import { connect } from 'react-redux'
import { Badge, Row, Col } from 'reactstrap';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import { useCallback, useEffect, useReducer } from 'react';

import Search from './Search'
import prettyBytes from 'pretty-bytes'

import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment'
import { onDownloadDocument } from 'utils/documents';
import { closeDocumentCenter } from 'store/functions/document_center'

import { toast } from 'react-toastify'
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'

const reducer = (state, action) => {
    switch (action.type) {
        case "UPLOADED":
            return state.map((doc) => {
                return (doc._id === action.obj._id) ? action.obj : doc
            });

        case "ADDED":
            state.push(action.obj)
            return state

      default:
        return state;
    }
};

const Upload = ({document_center, socket, viewing_user}) => {

    const [newDocs, dispatch] = useReducer(reducer, []);
    const zIndex = document_center.zIndex ? document_center.zIndex : 1050

    let uploadOne = false;
    if(document_center.task) uploadOne = true

    const onDocStatusUpdated = useCallback(async (obj) => {
        dispatch({ type: "UPLOADED", obj });
        if(document_center.onUpload) document_center.onUpload(obj)

        if(uploadOne) toggleStandardLoader(false)

        if(document_center.task && obj.uploaded_by === viewing_user._id) {
            const finished = await api.tasks.finish(document_center.task, {
                _id   : obj._id,
                field : 'document',
            })
            if(!finished.success) return toast.error('Document was uploaded, but no task could be finished. Please try again.')
            closeDocumentCenter()
        }
    }, [document_center, uploadOne, viewing_user._id])
    
    useEffect(() => {

        if(document_center.url === '/v1/documents') {
            socket.on('DOCUMENTS.UPLOADED', onDocStatusUpdated)
            socket.on('DOCUMENTS.FAILED', onDocStatusUpdated)
            return () => {
                socket.off('DOCUMENTS.UPLOADED', onDocStatusUpdated)
                socket.off('DOCUMENTS.FAILED', onDocStatusUpdated)
            }
        } else {
            socket.on('COMPANY_DOCUMENTS.UPLOADED', onDocStatusUpdated)
            socket.on('COMPANY_DOCUMENTS.FAILED', onDocStatusUpdated)
            return () => {
                socket.off('COMPANY_DOCUMENTS.UPLOADED', onDocStatusUpdated)
                socket.off('COMPANY_DOCUMENTS.FAILED', onDocStatusUpdated)
            }
        }

    }, [document_center.url, onDocStatusUpdated, socket])
  
    useEffect(() => {
        if(document_center.open === true) {
            document.body.classList.add('noScroll')
        } else {
            document.body.classList.remove('noScroll')
        }
    }, [document_center.open])

    if(!document_center.open) return <></>

    const isCompanyDocument = document_center.url === '/v1/company_documents';

    return (
        <div className="archk-upload">
            <div className="archk-upload-blackout"  style={{zIndex: zIndex !== 'inherit' ? zIndex - 5 : zIndex}} onClick={closeDocumentCenter} />
            
            <div className="archk-upload-main" style={{zIndex}} >

                <div className="archk-upload-section border-bottom">
                    <Row>
                        <Col md={6} className="align-self-center">
                            <h3 className='mb-0'>Upload Center</h3>
                        </Col>
                        <Col md={6} className='align-self-center text-right'>
                            <button onClick={closeDocumentCenter} className='btn btn-warning'><i className="fas fa-times mr-2" /> Close</button>
                        </Col>
                    </Row>
                </div>
                <div className="archk-upload-section border-bottom">
                    <p className='text-sm mb-0'>
                        <b className='text-dark font-weight-bold'>Current Action:</b>{' '}
                        {document_center.task ? (
                            <span>Finishing the task: <b className='text-underline font-weight-bold text-dark'><ObjectFinder collection="tasks" _id={document_center.task} /></b> </span>
                        ) : document_center.matter_document ? (
                            <span>Uploading files to document category <b className='text-underline'><ObjectFinder collection="matter_documents" _id={document_center.matter_document} /></b> on the case: <b className='text-underline'><ObjectFinder collection="matters" _id={document_center.matter} /></b>.</span>
                        ) : isCompanyDocument ? (
                            <span>Uploading Documents</span>
                        )  : (
                            <span>Uploading documents to the case <b className='text-underline'><ObjectFinder collection="matters" _id={document_center.matter} /></b>.</span>
                        )}
                    </p>
                </div>

                <div className="archk-upload-section">
                    <DragAndDrop
                        zone_id="archk-document-center"
                        url={document_center.url ? document_center.url : '/v1/documents'}
                        uploadOne={uploadOne}
                        onError={(e) => {
                            toast.error('Document could not be uploaded, please try again or check the integrity of the file')
                        }}
                        resizePixels={document_center.resizePixels ? document_center.resizePixels : 1200}
                    
                        onSuccess={(file, request) => {
                            if(request && request.data) {
                                dispatch({ type: "ADDED", obj: request.data });
                                if(uploadOne) toggleStandardLoader(true)
                            }
                        }}
                        extraData={{
                            matter: document_center.matter,
                            task: document_center.task ? document_center.task : undefined,
                            matter_document: document_center.matter_document ? document_center.matter_document : undefined,
                            matter_document_upload: document_center.matter_document_upload ? document_center.matter_document_upload : undefined,
                            outstanding_item: document_center.outstanding_item ? document_center.outstanding_item : undefined,
                        }}
                    />
                </div>

                {newDocs && newDocs.length ? (
                    <div className="archk-upload-section">
                        <h4>New Uploads</h4>
                        <table className='table border-left border-right border-bottom'>
                            <thead>
                                <tr>
                                    {!isCompanyDocument ? (
                                        <td>Matter</td>
                                    ) : ''}
                                    <td>File</td>
                                    <td>Size</td>
                                    <td>Date</td>
                                    <td>Status</td>
                                    <td className='text-right'>Download</td>
                                </tr>
                            </thead>
                            <tbody>
                                {newDocs.map(d => (
                                    <tr key={d._id}>
                                        {!isCompanyDocument ? (
                                            <td><ObjectFinder collection="matters" _id={d.matter} /></td>
                                        ) : ''}
                                        <td>{d.name}</td>
                                        <td>{prettyBytes(d.size)}</td>
                                        <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                        <td>
                                            {d.status === 'uploading' ? (
                                                <Badge color="info">Uploading</Badge>
                                            ) : d.status === 'succeeded' ? (
                                                <Badge color="success">Succeeded</Badge>
                                            ) : (
                                                <Badge color="danger">Failed</Badge>
                                            )}
                                        </td>
                                        <td className='text-right'>
                                            {d.status === 'succeeded' ? (
                                                <button 
                                                    onClick={() => onDownloadDocument(d, isCompanyDocument ? 'company_documents' : '')} 
                                                    className='btn btn-sm btn-success'
                                                >
                                                    <i className="fas fa-download mr-2" /> Download
                                                </button>
                                            ) : ''}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> 
                    </div>
                ) : ''}

                <div className="archk-upload-section border-top ">
                    <h4>My Upload History (Last 30 Days)</h4>
                    <Search isCompanyDocument={isCompanyDocument} />
                </div>

            </div>
            
        </div>
    )
}


const mapStateToProps = state => {
	return {
        socket: state.socket,
	    document_center: state.document_center,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Upload);