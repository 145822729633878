const TypeIcon = ({ type }) => (
    type === 'cc' ? (
        <span style={styles.icon}><i className="fa-solid fa-money-check text-success mr-2" /></span>
    ) : (
        <span style={styles.icon}><i className="text-warning fa-solid fa-building-columns" /></span>
    )
)

const styles = {
    icon: {
        display: 'inline-block',
        width: 16
    }
}

export default TypeIcon