import { useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Col, Container } from "reactstrap";

import Checklist from "./Checklist";
import CustomFields from "./CustomFields";
import Documents from "./Documents";
import Events from "./Events";
import FeedPage from "./Feed/Page";
import Forms from "./Forms";
import Overview from "./Overview";
import Tasks from "./Tasks";
import Identifier from "./Identifier";
import MatterValue from "./MatterValue";
import LeadSources from "./LeadSources";
import Automations from "./Automations";
import OutstandingItems from "./OutstandingItems";
import CustomDates from "./CustomDates";

import AI from "./AI";

import ComponentsBanners from "./components/Banners";
import ComponentsLinks from "./components/Links";
import ComponentsMainNavigation from "./components/MainNavigation";
import ComponentsProgress from "./components/Progress";
import ComponentsQuickActions from "./components/QuickActions";
import ComponentsStepHistory from "./components/StepHistory";
import RecentStaffCommunication from "./components/RecentStaffCommunication";

import ModulesBankruptcy from 'components/mods/Bankruptcy/MatterOverlay'

import Alerts from './Alerts';

import JumpStep from './Tasks/JumpStep'

import hasPrivilege from 'utils/hasPrivilege'

const MattersViewMain = ({
    matter, 
    loaded, 
    workflowLocations, 
    fetchWorkflowLocations, 
    viewing_user, 
    customFields, 
    fetchCustomFields, 
    events, 
    fetchMatterEvents, 
    tab, 
    onSetTab, 
    isMoving, 
    setIsMoving, 
    workflowLinks, 
    fetchWorkflowLinks,
    showAI,
    setShowAI,
    showModuleBK,
    setShowModuleBK,
}) => {

    const [showAlerts, setShowAlerts] = useState(false)
    const [alertsOnMount, setAlertsOnMount] = useState(false)
  
    useEffect(() => {
        if(!alertsOnMount) {
            const showAlerts  = matter.alerts.length && 
            matter.alerts.some(a => !a.resolved_at) && 
            !matter.alerts_viewed_by.includes(viewing_user._id)
            if(showAlerts) {
                setShowAlerts(showAlerts)
                setAlertsOnMount(true)
            }
        }
      
    }, [viewing_user._id, alertsOnMount, matter._id, matter.alerts, matter.alerts_viewed_by])


    if(!loaded) return <></>

    return (

        <Col className="px-0 main">
            <div className="pl-">

            {matter.deleted ? (
                <div className="alert alert-danger bg-gradient-danger mb-0 text-center">
                    <i className="fas fa-exclamation-triangle mr-2" /> This matter has been deleted.
                </div>
            ) : ''}

                {showAI ? <AI showAI={showAI} setShowAI={setShowAI} /> : null }
                
                {showModuleBK ? <ModulesBankruptcy showModuleBK={showModuleBK} setShowModuleBK={setShowModuleBK} /> : null }

                <ComponentsBanners 
                    banners={matter.banners} 
                    setShowAlerts={setShowAlerts} 
                    onSetTab={onSetTab}
                />
               
                <ComponentsProgress 
                    onSetTab={onSetTab}
                    isMoving={isMoving}
                />

                <ComponentsLinks 
                    workflowLinks={workflowLinks}
                    fetchWorkflowLinks={fetchWorkflowLinks}
                />

               

                {!matter.workflow_step ? hasPrivilege('FUNCTION.JUMP_STEPS') ? (
                    <div>
                        <div className="alert alert-info mb-0">This matter is currently on a step that was deleted. To keep this file current you must jump it to an active step.</div>
                        <JumpStep setIsMoving={setIsMoving} />
                    </div>
                ) : (
                    <div>
                        <div className="alert alert-warning mb-0">This matter is currently on a step that was deleted. Contact an admin with the ability to jump steps to keep this file current.</div>
                    </div>
                ) : (
                    <div>
                         <ComponentsMainNavigation 
                            tab={tab}
                            setTab={onSetTab}
                            setShowAI={setShowAI}
                            setShowModuleBK={setShowModuleBK}
                        />

                        <ComponentsQuickActions matter_id={matter._id} onSetTab={onSetTab} />

                        {tab === 'Overview' ? (
                            <div>
                                {
                                    viewing_user._id === '631e5c687418496a7ab0cb37' ||
                                    viewing_user._id === '66ad07793c4bde4ab85c10b8' ||
                                    viewing_user._id === '6685d06d9188ba72f9dbeab0' ||
                                    viewing_user._id === '6696c3261e2743b4dba57024'
                                 ? (
                                    <Container fluid>
                                        <RecentStaffCommunication />
                                    </Container>
                                 ) : (
                                    <ComponentsStepHistory />
                                 )}
                                
                                {/*   */}
                                <Overview 
                                    matter={matter} 
                                    workflowLocations={workflowLocations}
                                    fetchWorkflowLocations={fetchWorkflowLocations}
                                    customFields={customFields}
                                    fetchCustomFields={fetchCustomFields}
                                    setTab={onSetTab}
                                />
                            </div>
                        ) : tab === 'Documents' ? (
                            <Documents />
                        ) : tab === 'Forms' ? (
                            <Forms />
                        ) : tab === 'Feed' ? (
                            <FeedPage matter={matter} />
                        ) : tab === 'Tasks' ? (
                            <Tasks setIsMoving={setIsMoving} />
                        ) : tab === 'Events' ? (
                            <Events events={events} fetchMatterEvents={fetchMatterEvents} />
                        ) : tab === 'CustomFields' ? (
                            <CustomFields customFields={customFields} fetchCustomFields={fetchCustomFields}/>
                        ) : tab === 'Checklist' ? (
                            <Checklist />
                        ) : tab === 'Identifier' ? (
                            <Identifier />
                        ) : tab === 'CustomDates' ? (
                            <CustomDates />
                        ) : tab === 'MatterValue' ? (
                            <MatterValue />
                        ) : tab === 'LeadSources' ? (
                            <LeadSources />
                        ) : tab === 'Automations' ? (
                            <Automations />
                        ) : tab === 'Items' ? (
                            <OutstandingItems />
                        ) : ''}
                        
                        <Alerts 
                            showAlerts={showAlerts}
                            setShowAlerts={setShowAlerts}
                            alertsOnMount={alertsOnMount}
                        />
                    </div>
                )}

                
               
            </div>
        </Col>
    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMain);
