import { Card, CardBody, CardHeader, CardTitle, CardFooter, FormGroup, Input, Col, Row } from 'reactstrap';
import { formatCurrency } from 'utils/currency';

import { useCallback, useState } from 'react';
import { toast } from 'react-toastify'

import api from 'api'

const Payments = ({matter, fetchData}) => {

    const [err, setErr] = useState(false)
    const [num, setNum] = useState(0)
    const [loading, setLoading] = useState(false)

    const onSave = useCallback(async () => {
        if(!num ||  num === '0') return toast.info(`Trust value cannot be zero`)

        setErr(false)
        setLoading(true)
        const result = await api.matters.trust(matter._id, { billing_for_trust: parseFloat(num) })
        setLoading(false)

        if(!result.success) return setErr(result.message[0])
        
        fetchData()
        toast.success(`Future trust balance updated successfully!`)
    }, [fetchData, matter._id, num])

    return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-wrench text-info mr-2" /> Adjust Future Trust Payments</CardTitle>
            </CardHeader>

            <CardBody>
                <Row>
                    <Col md={6}>
                        <table className='table border'>
                            <tbody>
                                <tr>
                                    <td>Total For Case:</td>
                                    <td className='text-right'>{formatCurrency(matter.billing_total)}</td>
                                </tr>
                                <tr>
                                    <td>Amount Paid On Case: </td>
                                    <td className='text-right'>{formatCurrency(matter.billing_paid)}</td>
                                </tr>
                                <tr>
                                    <td>Remaining Balance:</td>
                                    <td className='text-right'>{formatCurrency(matter.billing_balance)}</td>
                                </tr>
                                <tr>
                                    <td>Money In Trust:</td>
                                    <td className='text-right'>{formatCurrency(matter.billing_in_trust)}</td>
                                </tr>
                                <tr>
                                    <td><i className="fas fa-exclamation mr-2 text-warning" /> Future Amount Going To Trust:</td>
                                    <td className='text-right font-weight-bold text-dark'>{formatCurrency(matter.billing_for_trust)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <label className='form-control-label'>Amount to add or subtract from future trust payments.</label>
                            <Input 
                                type="number"
                                value={num}
                                onChange={e => setNum(e.target.value)}
                            />
                        </FormGroup>

                        <p className='text-sm mb-0'>
                            <i className="fas fa-info-circle text-info mr-2" /> 
                            Trust payment takes priority over standard ones. Any amount going into trust will be pulled first from payments or payment plans and deposited into the associated account.
                        </p>
                    </Col>
                </Row>
            </CardBody>

            {err ? (
                <CardFooter className='text-center bg-secondary'>
                    <p className='text-warning font-weight-bold mb-0 text-sm'>{err}</p>
                </CardFooter>
            ) : ''}

            <CardFooter className='text-right'>
                <button disabled={loading} onClick={onSave} className='btn btn-sm btn-success'>Save Adjustment</button>
            </CardFooter>

         
        </Card>
    )

}

export default Payments