
import { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup, CardFooter, Input } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import DatePicker from "react-datepicker";
import ConfirmationModal from 'components/functional/modals/Confirmation'

import { formatCurrency } from 'utils/currency';
import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'
import moment from 'moment';

import TypeIcon from '../_components/TypeIcon'

const TableItem = ({title, value}) => (
    <Row>
        <Col md={6}>
            <p className='text-sm mb-0 font-weight-bold'>{title}</p>
        </Col>
        <Col md={6}>
            <p className='text-sm text-right mb-0'>
                {value}
            </p>
        </Col>
    </Row>
)

const SelectDayOfMonth = ({title, value, onChange}) => (
    <ReactSelect
        title={title}
        formGroup={true}
        placeholder=""
        onChange={(obj) => onChange(obj.value)}
        options={[
            { label: '1st', value: '1' },
            { label: '2nd', value: '2' },
            { label: '3rd', value: '3' },
            { label: '4th', value: '4' },
            { label: '5th', value: '5' },
            { label: '6th', value: '6' },
            { label: '7th', value: '7' },
            { label: '8th', value: '8' },
            { label: '9th', value: '9' },
            { label: '10th', value: '10' },
            { label: '11th', value: '11' },
            { label: '12th', value: '12' },
            { label: '13th', value: '13' },
            { label: '14th', value: '14' },
            { label: '15th', value: '15' },
            { label: '16th', value: '16' },
            { label: '17th', value: '17' },
            { label: '18th', value: '18' },
            { label: '19th', value: '19' },
            { label: '20th', value: '20' },
            { label: '21st', value: '21' },
            { label: '22nd', value: '22' },
            { label: '23rd', value: '23' },
            { label: '24th', value: '24' },
            { label: '25th', value: '25' },
            { label: '26th', value: '26' },
            { label: '27th', value: '27' },
            { label: '28th', value: '28' },
            { label: '29th', value: '29' },
            { label: '30th', value: '30' },
            { label: '31st (Last Day Of Each Month)', value: '31' },
        ]}
        value={value}
    />    
)

const Payments = ({matter, payment_methods, payment_subscription, setTab, fetchData}) => {

    const [showDeletePlan, setShowDeletePlan] = useState(false)
    const [subscription, setSubscription] = useState(null)
    const [errs, setErrs] = useState([])


    const onSetSubscription = useCallback((field, value) => {
        const _subscription = JSON.parse(JSON.stringify(subscription))
        _subscription[field] = value;
        setSubscription(_subscription)
    }, [subscription])

    const getOption = useCallback((method) => {

        let innerText = ' ' + method.last_4 + ' - ' + method.owner_name
        if(method.primary_method) innerText += ' - (Primary)'

        const label = <span><TypeIcon type={method.type} /> {innerText}</span>

        return { value: method._id, label }

    }, [])
   
    const onDeletePlan = useCallback(async () => {
        toggleStandardLoader(true)
        const deleted = await api.payment_subscriptions.delete(payment_subscription._id)
        toggleStandardLoader(false)

        if(!deleted.success) return toast.error(`Something went wrong deleting this plan, please try again`)

        toast.success('Payment Plan Successfully Delete')
        fetchData()
    }, [payment_subscription, fetchData])
 
    const onSaveSubscription = useCallback(async () => {
        setErrs([])
        let interval = subscription.interval;
        let amount = subscription.amount ? parseFloat(subscription.amount) : 0;
        amount = parseFloat(amount.toFixed(2))

        const day1 = parseInt(subscription.day1)
        const day2 = parseInt(subscription.day2)

        setErrs(false)

        if(amount <= 0 || amount > matter.billing_balance) return setErrs(['Payment plan amount must be greater than 0 and less than the balance left on the matter.'])

        if(!subscription.payment_method) return setErrs(['A valid payment method must be selected to charge this plan to'])
        if(subscription.payment_method === subscription.payment_method_backup) return setErrs(['Payment method and backup payment method cannot be the same.'])

        if(!payment_subscription) {
            if(!subscription.start_date) return setErrs(['A start date must be specified to charge a subscription payment'])
            if(!moment(subscription.start_date).isAfter(moment().format('YYYY-MM-DD'), 'day')) return setErrs([`Start Date must after today's date`]);
        } else {
            if(!subscription.next_run_date) return setErrs(['The next run date must be specified to charge a subscription payment'])
            if(!moment(subscription.next_run_date).isAfter(moment().format('YYYY-MM-DD'), 'day')) return setErrs([`Next run date must after today's date`]);
        }

        if(interval === 'Specific Day of the Month') {
            if(!day1) return setErrs(['You must specify a day of the month to charge this plan on'])
            interval = day1
        }
        if(interval === '2 Specific Days of the Month') {
            if(!day1 || !day2) return setErrs(['You must specify 2 days of the month to charge this plan on'])
            if(day1 > 27) return setErrs(['The first day of the month to charge this plan on must be less than the 27th of the month'])
            if(day1 >= day2) return setErrs(['The second day of the month to charge this plan on must be after the first day of the month to charge the plan on'])
            interval = day1 + '-' + day2
        }

        toggleStandardLoader(true)
        let charged;

        console.log(subscription)

        if(payment_subscription) {
            charged = await api.payment_subscriptions.update(payment_subscription._id, {
                matter                  : matter._id,
                amount                  : parseFloat(amount),
                interval                : interval.toString(),
                next_run_date           : moment(subscription.next_run_date).format('YYYY-MM-DD'),
                payment_method          : subscription.payment_method,
                payment_method_backup   : subscription.payment_method_backup,
                run_time                : subscription.run_time,
            })
        } else {
            charged = await api.payment_subscriptions.create({
                matter                  : matter._id,
                amount                  : parseFloat(amount),
                interval                : interval.toString(),
                start_date              : moment(subscription.start_date).format('YYYY-MM-DD'),
                payment_method          : subscription.payment_method,
                payment_method_backup   : subscription.payment_method_backup,
                run_time                : subscription.run_time,
            })
        }
        toggleStandardLoader(false);

        if(charged.success) {
            toast.success('Plan saved successfully')
            return fetchData()
        } else {
            setErrs(charged.message)
        }

    }, [subscription, matter.billing_balance, matter._id, payment_subscription, fetchData])

    useEffect(() => {

        const primary = payment_methods.find(p => p.primary_method)
        const backup = payment_methods.find(p => p.backup_method)

        if(payment_subscription) {
            const sub = JSON.parse(JSON.stringify(payment_subscription))

            if(sub.interval.includes('-')) {
                sub.day1 = sub.interval.split('-')[0]
                sub.day2 = sub.interval.split('-')[1]
                sub.interval = '2 Specific Days of the Month'

            } else if(!Number.isNaN(parseInt(sub.interval)) && !sub.interval.includes('of the month')) {
                sub.day1 = sub.interval.toString()
                sub.interval = 'Specific Day of the Month'
            }

            return setSubscription(sub)
        }

        return setSubscription({
            payment_method          : primary ? primary._id : '',
            payment_method_backup   : backup ? backup._id : '',
    
            amount                  : '10',
            start_date              : moment().startOf('day').add(1, 'days').toDate(),
            next_run_date           : '',
            interval                : 'weekly',
            day1                    : '10',
            day2                    : '25',
            run_time                : 'morning',
        })

    }, [payment_methods, payment_subscription])

    if(!subscription) return <></>

    return (
        <Row>
            
            <Col md={6}>
                <Card>
                    <CardHeader className='py-3'>
                        <CardTitle className="mb-0">{payment_subscription ? 'Update' : 'Create'} Payment Plan </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <FormGroup>
                            <label className='form-control-label'>Recurring Amount</label>
                            <Input 
                                type="number"
                                onChange={(e) => onSetSubscription('amount', e.target.value)}
                                value={subscription.amount}
                            />
                        </FormGroup>

                        <ReactSelect
                            title={"Recurring Interval"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetSubscription('interval', obj.value)}
                            options={[
                                { label: 'Weekly', value: 'weekly' },
                                { label: 'Biweekly', value: 'biweekly' },
                                { label: '1st of the Month', value: '1st of the month' },
                                { label: '15th of the Month', value: '15th of the month' },
                                { label: '1st and 15th of the month', value: '1st and 15th of the month' },
                                { label: 'Specific Day of the Month', value: 'Specific Day of the Month' },
                                { label: '2 Specific Days of the Month', value: '2 Specific Days of the Month' },
                            ]}
                            value={subscription.interval}
                        />    

                        {subscription.interval === 'Specific Day of the Month' ? (
                            <div className='pl-5'>
                                <SelectDayOfMonth 
                                    title="Day Of Month"
                                    value={subscription.day1}
                                    onChange={(day) => onSetSubscription('day1', day)}
                                />
                            </div>
                        ) : subscription.interval === '2 Specific Days of the Month' ? (
                            <div className='pl-5'>
                                <Row>
                                    <Col md={6}>
                                        <SelectDayOfMonth 
                                            title="1st Day Of Month"
                                            value={subscription.day1}
                                            onChange={(day) => onSetSubscription('day1', day)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SelectDayOfMonth 
                                            title="2nd Day Of Month"
                                            value={subscription.day2}
                                            onChange={(day) => onSetSubscription('day2', day)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : '' }

                        {payment_subscription ? (
                            <FormGroup>
                                <label className='form-control-label'>Next Run Date</label>

                                <DatePicker 
                                    selected={moment(subscription.next_run_date).toDate()} 
                                    onChange={(date) => onSetSubscription('next_run_date', date)} 
                                />
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <label className='form-control-label'>Start Date</label>

                                <DatePicker 
                                    selected={moment(subscription.start_date).toDate()} 
                                    onChange={(date) => onSetSubscription('start_date', date)} 
                                />
                            </FormGroup>
                        )}
                      
                        <ReactSelect
                            title={"Payment Method"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetSubscription('payment_method', obj.value)}
                            options={payment_methods.map(getOption)}
                            value={subscription.payment_method}
                        />    

                        {subscription.payment_method ? (
                            <div>
                                <ReactSelect
                                    title={"Backup Payment Method"}
                                    // description={<span><i className="fas fa-info-circle mr-2 text-info" /> This card or bank will be charged if the primary method fails</span>}
                                    formGroup={true}
                                    placeholder=""
                                    onChange={(obj) => onSetSubscription('payment_method_backup', obj.value)}
                                    options={payment_methods.map(getOption)}
                                    value={subscription.payment_method_backup}
                                />    

                                {subscription.payment_method_backup ? (
                                    <p className='text-sm mb-0 mt--2 text-muted'>
                                        <span onClick={() => onSetSubscription('payment_method_backup', '')} className='cursor-pointer ml-3'>
                                            <i className="fas fa-trash mr-2 text-warning" /> Remove Backup Method
                                        </span>
                                    </p>
                                ) : ''}
                            </div>
                        ) : ''}

                        <hr />

                        <ReactSelect
                            title={"Run Payment:"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetSubscription('run_time', obj.value)}
                            options={[
                                { label: 'In The Morning', value: 'morning' },
                                { label: 'At Night', value: 'night' },
                            ]}
                            value={subscription.run_time}
                        />    
                      
                        
                    </CardBody>

                    {errs.length ? (
                        <CardFooter>
                            {errs.map((e, i) => (
                                <p key={i} className='text-sm mb-0 text-warning font-weight-bold'>{e}</p>
                            ))}
                        </CardFooter>
                    ) : ''}

                    <CardFooter className="text-right">
                        <button 
                            onClick={onSaveSubscription} 
                            className='btn btn-success btn-sm'
                        >
                            <i className="fas fa-save mr-2" />
                            {payment_subscription ? 'Update' : 'Create'} Plan
                        </button>
                    </CardFooter>

                </Card>
            </Col>

            {payment_subscription ? (
                <Col md={6}>
                    <Card>
                        <CardHeader>
                            <CardTitle className='mb-0'>Current Plan Info</CardTitle>
                        </CardHeader>

                        <CardBody>

                           

                            <TableItem 
                                title="Current Balance"
                                value={formatCurrency(payment_subscription.recurring_balance)}
                            />
                             <TableItem 
                                title="Run Time"
                                value={<b className='text-uppercase'>{payment_subscription.run_time}</b>}
                            />

                            <TableItem   
                                title="Start Date"
                                value={moment(payment_subscription.start_date).format('MMM Do, YYYY')}
                            />
                            <TableItem 
                                title="Paid Off Date"
                                value={moment(payment_subscription.paid_off_date).format('MMM Do, YYYY')}
                            />
                            <TableItem 
                                title="Future Payments"
                                value={payment_subscription.future_payments}
                            />
                            <TableItem 
                                title="Last Run Date"
                                value={payment_subscription.last_run_date === '0' ? '-' : moment(payment_subscription.last_run_date).format('MMM Do, YYYY')}
                            />
                         
                        </CardBody>

                        <CardFooter>
                            
                            <TableItem 
                                title="Delinquent"
                                value={payment_subscription.delinquent ? <span className='text-danger'>YES</span> : <span className='text-success'>NO</span>}
                            />
                            <TableItem 
                                title="Delinquent Payments"
                                value={payment_subscription.delinquent_payments}
                            />
                            <TableItem 
                                title="Delinquent Since"
                                value={payment_subscription.delinquent_since ? moment(payment_subscription.delinquent_since).format('MM/DD/YYYY') : '-'}
                            />

                        </CardFooter>

                        <CardFooter>
                           
                            <TableItem 
                                title="Next Payment Success Rate"
                                value={payment_subscription.next_payment_success_rate.toFixed(2) + '%'}
                            />
                            <TableItem 
                                title="Percent Payments Succeeded"
                                value={
                                    payment_subscription.payments_failed || payment_subscription.payments_succeeded ?
                                    payment_subscription.percent_payments_succeeded.toFixed(2) + '%'
                                    : '-'
                                }
                            />
                            <TableItem 
                                title="Percent Payments Failed"
                                value={
                                    payment_subscription.payments_failed || payment_subscription.payments_succeeded ?
                                    (100 - payment_subscription.percent_payments_succeeded).toFixed(2) + '%'
                                    : '-'
                                }
                            />                           
                            <TableItem 
                                title="Payments Failed"
                                value={payment_subscription.payments_failed}
                            />
                            <TableItem 
                                title="Payments Succeeded"
                                value={payment_subscription.payments_succeeded}
                            />

                        </CardFooter>

                        <CardFooter className='text-right'>
                            <button onClick={() => setShowDeletePlan(true)} className='btn btn-danger btn-sm'>
                                <i className="fas fa-exclamation-triangle" /> Delete Plan
                            </button>
                        </CardFooter>

                    </Card>
                    
                </Col>
            ) : ''}

            <ConfirmationModal 
                showModal={showDeletePlan}
                toggleModal={() => setShowDeletePlan(false)}
                title={"Remove Payment Plan"}
                onConfirmation={onDeletePlan}
                body={(
                    <span>
                        Are you sure you wish to delete this plan? This will remove all plan statistics about failure/success rate and a new plan will need to be setup in the future if you wish to bill this matter on a recurring basis again.
                    </span>
                )}
            />

        </Row>
    )

}

export default Payments